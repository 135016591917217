import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={155}
      viewBox="0 0 128 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M108 38.107a1 1 0 01-1 1H21a1 1 0 01-1-1V27.371a1 1 0 011-1h86a1 1 0 011 1v10.736z"
        fill="#4A4A48"
      />
      <path
        d="M107.998 56.283a1 1 0 01-1 1H68.472a1 1 0 01-1-1V45.546a1 1 0 011-1h38.526a1 1 0 011 1v10.737z"
        fill="#329C7F"
      />
      <path
        d="M107.998 74.058a1 1 0 01-1 1H68.472a1 1 0 01-1-1V63.32a1 1 0 011-1h38.526a1 1 0 011 1v10.737z"
        fill="#F3AD3D"
      />
      <path
        d="M107.998 91.12a1 1 0 01-1 1H68.472a1 1 0 01-1-1V80.381a1 1 0 011-1h38.526a1 1 0 011 1V91.12z"
        fill="#4A4A48"
      />
      <path
        d="M107.998 127.378a1 1 0 01-1 1H68.472a1 1 0 01-1-1v-10.736a1 1 0 011-1h38.526a1 1 0 011 1v10.736z"
        fill="#F3AD3D"
      />
      <path
        d="M107.998 108.892a1 1 0 01-1 1H68.472a1 1 0 01-1-1V98.155a1 1 0 011-1h38.526a1 1 0 011 1v10.737z"
        fill="#329C7F"
      />
      <path
        d="M107.998 144.44a1 1 0 01-1 1H68.472a1 1 0 01-1-1v-10.736a1 1 0 011-1h38.526a1 1 0 011 1v10.736z"
        fill="#4A4A48"
      />
      <path
        d="M108 20.737a1 1 0 01-1 1H21a1 1 0 01-1-1V10a1 1 0 011-1h86a1 1 0 011 1v10.737z"
        fill="#F3AD3D"
      />
      <path
        d="M60.526 56.283a1 1 0 01-1 1H21a1 1 0 01-1-1V45.546a1 1 0 011-1h38.526a1 1 0 011 1v10.737z"
        fill="#329C7F"
      />
      <path
        d="M60.526 74.058a1 1 0 01-1 1H21a1 1 0 01-1-1V63.32a1 1 0 011-1h38.526a1 1 0 011 1v10.737z"
        fill="#F3AD3D"
      />
      <path
        d="M60.526 91.12a1 1 0 01-1 1H21a1 1 0 01-1-1V80.381a1 1 0 011-1h38.526a1 1 0 011 1V91.12z"
        fill="#4A4A48"
      />
      <path
        d="M60.526 127.378a1 1 0 01-1 1H21a1 1 0 01-1-1v-10.736a1 1 0 011-1h38.526a1 1 0 011 1v10.736z"
        fill="#F3AD3D"
      />
      <path
        d="M60.526 108.892a1 1 0 01-1 1H21a1 1 0 01-1-1V98.155a1 1 0 011-1h38.526a1 1 0 011 1v10.737z"
        fill="#329C7F"
      />
      <path
        d="M60.526 144.44a1 1 0 01-1 1H21a1 1 0 01-1-1v-10.736a1 1 0 011-1h38.526a1 1 0 011 1v10.736z"
        fill="#4A4A48"
      />
    </Svg>
  )
}

const SvgCoins3 = styled(BaseComponent)`
  outline: none;
`
export default SvgCoins3
