import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5a.5.5 0 00-1 0v12a.5.5 0 001 0v-12zM3.318 5.818a.45.45 0 10-.636-.636l-2 2a.45.45 0 000 .636l2 2a.45.45 0 10.636-.636L2.086 7.95H5.5a.45.45 0 100-.9H2.086l1.232-1.232zm9-.636a.45.45 0 10-.636.636l1.232 1.232H9.5a.45.45 0 000 .9h3.414l-1.232 1.232a.45.45 0 00.636.636l2-2a.45.45 0 000-.636l-2-2z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgColumnSpacing = styled(BaseComponent)`
  outline: none;
`
export default SvgColumnSpacing
