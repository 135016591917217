import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.754 2.05a.45.45 0 100 .9H9.95v4.1h-4.9v-4.1h1.204a.45.45 0 100-.9h-3.5a.45.45 0 100 .9H3.95v9.1H2.754a.45.45 0 000 .9h3.5a.45.45 0 000-.9H5.05v-4.1h4.9v4.1H8.754a.45.45 0 000 .9h3.5a.45.45 0 000-.9H11.05v-9.1h1.204a.45.45 0 000-.9h-3.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgHeading = styled(BaseComponent)`
  outline: none;
`
export default SvgHeading
