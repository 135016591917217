import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.95 1.5a.45.45 0 00-.9 0v12a.45.45 0 10.9 0v-12zM6.568 3.932a.45.45 0 10-.636.636L8.414 7.05H.5a.45.45 0 000 .9h7.914l-2.482 2.482a.45.45 0 10.636.636l3.25-3.25a.45.45 0 000-.636l-3.25-3.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPinRight = styled(BaseComponent)`
  outline: none;
`
export default SvgPinRight
