import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import css from '@styled-system/css'
import { AsYouType, E164Number } from 'libphonenumber-js/min'
import styled from 'styled-components'

import { Box } from '../../../atoms/Box'
import { Flex } from '../../../atoms/Flex'
import { useMedia, usePrevious } from '../../../hooks'
import FormItem, { Props as FormItemProps } from '../../Forms/FormItem'
import { BasicSelect as Select } from '../Select'
import { BasicTextField as TextField } from '../TextField'
import { countries, allCountriesMap } from './data'

import './flags.css'

export type Props = {
  name: string
  value?: string
  defaultValue?: string
  countryCode?: string
  disabled?: boolean
  onChange?: (value: E164Number) => void
} & Omit<FormItemProps, 'children'>

const options = countries.map((item) => ({
  ...item,
  icon: <Box className={`fflag fflag-${item.value} ff-md`} />,
}))

export const getInputValuePrefix = (value: string) =>
  value[0] === '+' ? value : `+${value}`

export const getPhoneNumber = (value: string) => {
  const asYouType = new AsYouType()
  const valueWithPrefix = getInputValuePrefix(value)
  asYouType.input(valueWithPrefix)

  return asYouType.getNumber()
}

const Wrapper = styled(Flex)`
  align-items: center;
  & > div:last-child {
    flex: 1;
  }
`

const StyledSelect = styled(Select)(
  css({
    mr: 1,
    position: 'relative',
    '[data-reach-listbox-button]': {
      padding: 2,
      cursor: 'pointer',
    },
  })
)

const defaultCountryCode = 'DE'

export const TelephoneInput = ({
  id,
  label,
  labelOptional,
  labelProps,
  error,
  errorProps,
  name,
  value,
  defaultValue,
  countryCode = defaultCountryCode,
  onChange,
  helperText,
  disabled,
  ...props
}: Props) => {
  const [country, setCountry] = useState(countryCode)

  const popoverWidth = useMedia(
    [`(max-width: 767px)`],
    ['80% !important'],
    '400px !important'
  )

  const initialCountryData = useMemo(() => {
    if (countryCode && countryCode.length < 2) {
      return
    }

    return allCountriesMap[countryCode]
  }, [countryCode])

  const [phoneNumber, setPhoneNumber] = useState(
    getInputValuePrefix(
      value ?? defaultValue ?? initialCountryData?.dialCode ?? ''
    )
  )

  const previousValue = usePrevious(value)

  const handleCountry = useCallback(
    (value: string) => {
      setCountry(value)
      setPhoneNumber(getInputValuePrefix(allCountriesMap[value]?.dialCode))
      onChange?.('')
    },
    [onChange]
  )

  const handleCountryCode = useCallback(
    (value: string) => {
      if (value !== country) {
        setCountry(value)
      }
    },
    [country]
  )

  const parseUserInput = useCallback(
    (value: string) => {
      if (value.length < 3) {
        return
      }

      const phone = getPhoneNumber(value)
      if (phone) {
        handleCountryCode(phone?.country!)
        onChange?.(phone?.number!)
      }
    },
    [handleCountryCode, onChange]
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event

      const inputValue = value.trim()

      if (inputValue) {
        parseUserInput(inputValue)
      }
    },
    [parseUserInput]
  )

  useEffect(() => {
    // we only want to set the country code here
    if (value && value !== previousValue) {
      const phone = getPhoneNumber(value)
      handleCountryCode(phone?.country!)
    }
  }, [previousValue, handleCountryCode, value])

  const formItemProps = {
    id,
    label,
    labelOptional,
    labelProps,
    error,
    errorProps,
    helperText,
    name,
  }

  return (
    <FormItem {...formItemProps}>
      <Wrapper>
        <StyledSelect
          name={`${name}.countryCode`}
          value={country}
          onChange={handleCountry}
          options={options}
          renderAsIcon
          withCheckMark={false}
          disabled={disabled}
          popoverWidth={popoverWidth}
        />
        <TextField
          {...props}
          name={`${name}.phoneNumber`}
          id={id}
          value={phoneNumber}
          type="tel"
          onChange={handleInputChange}
          enableStatusIcons={false}
          disabled={disabled}
        />
      </Wrapper>
    </FormItem>
  )
}
