import React, { useCallback } from 'react'

import { IKImage } from 'imagekitio-react'
import { CSSProperties } from 'styled-components'

type Transformation = {
  width?: string | number
  height?: string | number
}

export type Props = {
  alt: string
  imageUrl: string
  transformation?: Transformation[]
  loading?: 'lazy'
  style?: CSSProperties
}

const imageKitEndpoint = 'https://ik.imagekit.io/pzmhkpeqidw/'

const paths = [
  'https://s3.eu-central-1.amazonaws.com/linussalesforce/',
  'https://manualupload.s3.eu-central-1.amazonaws.com/',
  'https://cdn.builder.io/api/v1/image/',
  imageKitEndpoint,
]

export const OptimizedImage = ({
  alt,
  imageUrl,
  transformation,
  loading,
  style,
}: Props) => {
  const getPath = useCallback(() => {
    const rootPath = paths.find((p) => imageUrl?.includes(p)) ?? '';
    const path = imageUrl?.replace(rootPath, '');

    if (rootPath === imageKitEndpoint && path.includes('?')) {
      return path.split('?')[0];
    }
    return path
  }, [imageUrl])

  return (
    <IKImage
      alt={alt}
      urlEndpoint={imageKitEndpoint}
      path={getPath()}
      transformation={transformation}
      loading={loading}
      css={style}
    />
  )
}
