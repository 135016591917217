import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2V1h5v1H5zm-.25-2A.75.75 0 004 .75V1h-.5A1.5 1.5 0 002 2.5v10A1.5 1.5 0 003.5 14H7v-1H3.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5H4v.25c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75V2h.5a.5.5 0 01.5.5V7h1V2.5A1.5 1.5 0 0011.5 1H11V.75a.75.75 0 00-.75-.75h-5.5zM9 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm1.5.5a.5.5 0 100-1 .5.5 0 000 1zm2.5-.5a.5.5 0 11-1 0 .5.5 0 011 0zm1.5.5a.5.5 0 100-1 .5.5 0 000 1zm.5 1.5a.5.5 0 11-1 0 .5.5 0 011 0zm-.5 2.5a.5.5 0 100-1 .5.5 0 000 1zm0 2a.5.5 0 100-1 .5.5 0 000 1zm-6-4a.5.5 0 100-1 .5.5 0 000 1zm.5 1.5a.5.5 0 11-1 0 .5.5 0 011 0zM8.5 15a.5.5 0 100-1 .5.5 0 000 1zm2.5-.5a.5.5 0 11-1 0 .5.5 0 011 0zm1.5.5a.5.5 0 100-1 .5.5 0 000 1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgClipboardCopy = styled(BaseComponent)`
  outline: none;
`
export default SvgClipboardCopy
