import styled from 'styled-components'

import { Box } from '../../atoms/Box'
import { ThemeProps } from '../../theme'
import { breakIdentifiers, breakpoints } from '../../utils/createMediaQueries'

export type Props = {
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  children?: React.ReactNode
}

type HiddenFnProps = {
  theme: ThemeProps
} & Props

const hidden = (key: breakIdentifiers) => (props: HiddenFnProps) =>
  props[key]
    ? {
        [breakpoints()[key]]: {
          display: 'none',
        },
      }
    : null

const Hide = styled(Box)<Props>`
  ${hidden('sm')}
  ${hidden('md')}
  ${hidden('lg')}
  ${hidden('xl')}
`

export default Hide
