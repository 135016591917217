import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1a1 1 0 011-1h3a1 1 0 011 1v1h4a1 1 0 011 1v3c0 .889-.387 1.687-1 2.236V11.5a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 011 11.5V8.236A2.993 2.993 0 010 6V3a1 1 0 011-1h4V1zm4 0v1H6V1h3zM1 3h13v3a1.996 1.996 0 01-2 2H8v-.5a.5.5 0 00-1 0V8H3a1.996 1.996 0 01-2-2V3zm6 6H3c-.35 0-.687-.06-1-.17v2.67a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V8.83c-.313.11-.65.17-1 .17H8v.5a.5.5 0 01-1 0V9z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBackpack = styled(BaseComponent)`
  outline: none;
`
export default SvgBackpack
