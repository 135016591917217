import { Spinner as SpinnerIcon } from '@linus-capital/icons'
import styled from 'styled-components'
import { variant } from 'styled-system'

const variants = {
  small: {
    width: 16,
    height: 16,
  },
  medium: {
    width: 24,
    height: 24,
  },
  large: {
    width: 32,
    height: 32,
  },
  xlarge: {
    width: 48,
    height: 48,
  },
  xxlarge: {
    width: 64,
    height: 64,
  },
}

export type Props = {
  size?: keyof typeof variants
}

const spinnerSize = variant({
  prop: 'size',
  variants,
})

const Spinner = styled(SpinnerIcon)<Props>(spinnerSize)

Spinner.defaultProps = {
  size: 'medium',
}

export default Spinner
