import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M0 11C0 4.9 4.9 0 11 0s11 4.9 11 11-4.9 11-11 11S0 17.1 0 11zm11.9 5.9l5.8-5.4.2-.5-.2-.5-5.8-5.4c-.3-.3-.8-.3-1 0-.3.3-.3.8 0 1.1l4.4 4.1H4.8c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h10.5l-4.4 4.1c-.3.3-.3.7 0 1 .3.4.7.4 1 .1z"
      />
    </Svg>
  )
}

const SvgArrowRightCircle = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowRightCircle
