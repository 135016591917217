import { useEffect, useRef } from 'react'

export const useTimeout = (
  callback: (...args: any[]) => void,
  delay: number | null
) => {
  const fn = useRef(callback)

  useEffect(() => {
    if (delay == null) {
      return undefined
    }

    let timeoutId: number | null = null

    timeoutId = window.setTimeout(() => {
      fn.current()
    }, delay)

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [delay, fn])
}
