import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7.5a.5.5 0 00.21.407l7 5A.5.5 0 009 12.5V10h2.5a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5H9V2.5a.5.5 0 00-.79-.407l-7 5A.5.5 0 001 7.5zm7-4.028V5.5a.5.5 0 00.5.5H11v3H8.5a.5.5 0 00-.5.5v2.028L2.36 7.5 8 3.472z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgThickArrowLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgThickArrowLeft
