import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86.534 86.541h4.74a1 1 0 011 1v11.712a1 1 0 01-1 1h-4.74c-9.516 0-18.21-4.82-23.255-12.89l-22.2-35.514a13.645 13.645 0 00-11.628-6.444H11a1 1 0 01-1-1V32.692a1 1 0 011-1h18.45c9.524 0 18.217 4.82 23.257 12.89l22.2 35.514a13.645 13.645 0 0011.627 6.445zM86.535 31.692h4.738a1 1 0 011 1v11.713a1 1 0 01-1 1h-4.738a13.645 13.645 0 00-11.628 6.444l-3.938 6.301a1 1 0 01-1.696 0L62.37 47.1a1 1 0 010-1.06l.91-1.458c5.04-8.07 13.733-12.89 23.256-12.89z"
        fill="#fff"
      />
      <path
        d="M46.713 73.796a1 1 0 00-1.697 0l-3.93 6.3a13.657 13.657 0 01-11.635 6.445H11a1 1 0 00-1 1v11.712a1 1 0 001 1h18.45c9.517 0 18.21-4.82 23.257-12.89l.91-1.458a1 1 0 000-1.06l-6.904-11.049zM113.278 38.128a1 1 0 010 1.425L93.007 59.54c-.632.623-1.702.175-1.702-.712V18.852c0-.887 1.07-1.335 1.702-.712l20.271 19.988zM113.278 91.275a1 1 0 010 1.424l-20.271 19.988c-.632.623-1.702.176-1.702-.712V71.999c0-.888 1.07-1.335 1.702-.712l20.271 19.988z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgArrowsWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowsWhite
