import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.05 2.75a.55.55 0 10-1.1 0v4.533a.5.5 0 00-.217-.225l-9-4.75A.5.5 0 002 2.75v9.5a.5.5 0 00.733.442l9-4.75a.5.5 0 00.217-.225v4.533a.55.55 0 001.1 0v-9.5zM3 11.42V3.58l7.429 3.92L3 11.42z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgTrackNext = styled(BaseComponent)`
  outline: none;
`
export default SvgTrackNext
