import * as React from 'react'

import css from '@styled-system/css'
import styled from 'styled-components'

import { Box } from '../../atoms/Box'
import { BasicSelect as Select } from '../../molecules/Inputs/Select'
import { German, UK } from './Flags'

export type Props = {
  onChange: (value: string) => void
  language: string
}

const Wrapper = styled(Box)(
  css({
    '[data-reach-listbox-button]': {
      justifyContent: 'center',
      cursor: 'pointer',
      p: 0,
      border: 'none',
      boxShadow: 'none',
      '> div': {
        pt: '7px',
      },
    },
    '[data-reach-listbox-arrow]': {
      ml: 0,
    },
  })
)

const LanguageSelector = ({ onChange, language }: Props) => (
  <Wrapper>
    <Select
      name="language"
      value={language}
      renderAsIcon
      options={[
        {
          value: 'en',
          label: 'English',
          icon: <UK />,
        },
        {
          value: 'de-DE',
          label: 'Deutsch',
          icon: <German />,
        },
      ]}
      onChange={onChange}
      withBorder={false}
      withCheckMark={false}
    />
  </Wrapper>
)

export default LanguageSelector
