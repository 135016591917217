import * as React from 'react'

import styled from 'styled-components'

import { buttonSize, ButtonWrapper, Props as ButtonProps } from './Button'

export type Props = {
  icon?: React.ReactNode
  iconRight?: React.ReactNode
  children?: React.ReactNode
} & Omit<ButtonProps, 'variant' | 'children' | 'iconRight' | 'pending'>

export const TransparentButton = styled(ButtonWrapper)<Props>(buttonSize)
