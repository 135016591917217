import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.135 8.842a.5.5 0 00.707.023L7.5 5.435l3.658 3.43a.5.5 0 00.684-.73l-4-3.75a.5.5 0 00-.684 0l-4 3.75a.5.5 0 00-.023.707z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgChevronUp = styled(BaseComponent)`
  outline: none;
`
export default SvgChevronUp
