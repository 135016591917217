import React from 'react'

import css from '@styled-system/css'
import styled from 'styled-components'
import { SpaceProps, TextAlignProps } from 'styled-system'

import { Box, BoxProps } from '../../atoms/Box'
import { Button } from '../../atoms/Button'
import { Flex } from '../../atoms/Flex'
import { TextClamp } from '../../atoms/TextClamp'
import { H8, H7, H4, Text } from '../../atoms/Typography'
import { Banner } from '../../molecules/Banner'
import { Card } from '../../molecules/Card'
import { OptimizedImage } from '../../molecules/OptimizedImage'
import { ProgressBar } from '../../molecules/ProgressBar'
import { hexToRgb } from '../../utils/misc'
import { WithChildren, Status } from '../../utils/types'

type ValueLabelType = {
  value?: string
  label?: string
}

type BannerType = {
  color: Status
  text: string
  isTransparent?: boolean
}

export type Props = WithChildren<
  {
    imageUrl: string
    percentage?: number
    percentageLabel?: string
    title: string
    subtitle?: string
    buttonText?: string
    onClick: () => void
    properties?: ValueLabelType[]
    disabled?: boolean
    projectType?: string
    lazyImageLoading: boolean
    banner?: BannerType
    className?: string
    hidePercentage?: boolean
    hideButton?: boolean
    isGreyScaled?: boolean
  } & BoxProps
>

const PropertyItem = (props: ValueLabelType) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    py={3}
    px={[2, 2, 3]}
  >
    <H8>{props.label}</H8>
    <H8>{props.value}</H8>
  </Flex>
)

const ValueLabel = ({
  value,
  label,
  ...props
}: ValueLabelType & TextAlignProps & SpaceProps) => (
  <Box {...props}>
    <H7>{value}</H7>
    <Text variant="BodyExtraSmall">{label}</Text>
  </Box>
)

const PropertiesWrapper = styled(Box)`
  ${Flex} {
    :nth-child(odd) {
      background-color: ${(props) => props.theme.colors.earth[0]};
    }
    :nth-child(even) {
      background-color: ${(props) => props.theme.colors.earth[1]};
    }
  }
`

const ImageWrapper = styled(Card)(
  css({
    height: [95, 130],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '> img': {
      objectFit: 'cover',
      maxWidth: '100%',
    },
  })
)

const StyledCard = styled(Card)<{
  disabled?: boolean
  isGreyScaled?: boolean
}>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-between;
  transition: box-shadow 0.5s linear;
  :hover {
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.success};
    ${ImageWrapper} {
      ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(
          0deg,
          ${(props) => hexToRgb(props.theme.colors.success, 0.25)},
          ${(props) => hexToRgb(props.theme.colors.success, 0.25)}
        );
        opacity: 0.6;
      }
    }
  }
  ${(props) =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: 0.6;
    `}

  ${(props) =>
    props.isGreyScaled &&
    `
      pointerEvents: none;
      opacity: 0.6;
      img {
        filter: grayscale(100%);
      }
      button {
        cursor: inherit;
        :hover:not(:disabled) {
          opacity: 1;
        }
      }
    `}

}
`

const StyledLabel = styled(Box)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  opacity: 0.75;
  text-transform: uppercase;
`
const ProjectCard = ({
  imageUrl,
  title,
  subtitle,
  percentage,
  percentageLabel,
  buttonText,
  onClick,
  properties = [],
  children,
  disabled,
  projectType,
  banner,
  lazyImageLoading = true,
  hidePercentage = false,
  hideButton = false,
  isGreyScaled,
  ...props
}: Props) => (
  <StyledCard
    disabled={disabled}
    isGreyScaled={isGreyScaled}
    onClick={onClick}
    {...props}
  >
    <Box>
      <Box p={2}>
        <Box position="relative">
          <ImageWrapper variant="l-shape-small">
            <OptimizedImage
              alt={title}
              imageUrl={imageUrl}
              transformation={[{ width: 600, height: 300 }]}
              loading={lazyImageLoading ? 'lazy' : undefined}
            />
          </ImageWrapper>
          {projectType && (
            <StyledLabel
              fontSize={3}
              fontWeight={700}
              position="absolute"
              top={[1, 2, 2]}
              left={[1, 2, 3]}
              p={1}
            >
              {projectType}
            </StyledLabel>
          )}
          {banner && (
            <Box position="absolute" bottom={0} right={0} left={0}>
              <Banner
                status={banner.color}
                textAlign="center"
                alpha={banner.isTransparent ? 0.6 : 1}
                padding={2}
                textMargin={[0, 0, 2]}
              >
                <H7>{banner.text}</H7>
              </Banner>
            </Box>
          )}
        </Box>
      </Box>
      <Flex
        flexDirection="column"
        height={[150, 150, 150, 160]}
        justifyContent={isGreyScaled ? 'center' : 'space-between'}
        mt={isGreyScaled ? -2 : 2}
      >
        <Flex flexDirection="column" textAlign="center" height={[90, 95, 87]}>
          <H4 mb={1} px={2}>
            <TextClamp pt={1} linesShown={1}>
              {title}
            </TextClamp>
          </H4>
          <Flex flex={1} alignItems="center">
            <TextClamp
              variant="BodyExtraSmall"
              linesShown={3}
              width={[9 / 10, 8 / 10]}
              mx="auto"
            >
              {subtitle}
            </TextClamp>
          </Flex>
        </Flex>
        {!hidePercentage && (
          <Box>
            <ValueLabel
              value={`${percentage}%`}
              label={percentageLabel}
              pl={[2, 2, 3]}
            />
            <Box mt={1}>
              <ProgressBar progress={percentage} />
            </Box>
          </Box>
        )}
      </Flex>
      {properties?.length > 0 && (
        <PropertiesWrapper>
          {properties.map((property, index) => (
            <PropertyItem
              key={index}
              value={property.value}
              label={property.label}
            />
          ))}
        </PropertiesWrapper>
      )}
      {children}
    </Box>
    {!hideButton && (
      <Box p={3}>
        <Button variant="secondary" disabled={disabled} width={1}>
          <Text variant="ButtonSmall">{buttonText}</Text>
        </Button>
      </Box>
    )}
  </StyledCard>
)

export default ProjectCard
