import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={45.638}
        y={11}
        width={37.206}
        height={9.567}
        rx={1.063}
        fill="#fff"
      />
      <rect
        x={18}
        y={109.861}
        width={91.42}
        height={7.441}
        rx={1.063}
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.325 17.377c-.587 0-1.063.476-1.063 1.064v91.419c0 .588.476 1.063 1.063 1.063H59.43a1.063 1.063 0 01-1.035-1.062v-13.82c0-.587.476-1.063 1.063-1.063h10.63c.588 0 1.064.476 1.064 1.064v13.819c0 .577-.46 1.047-1.034 1.062H88.16c.587 0 1.063-.475 1.063-1.063V18.44c0-.587-.476-1.063-1.063-1.063H40.325zm9.567 53.151c-.587 0-1.063.476-1.063 1.063v9.568c0 .587.476 1.063 1.063 1.063h7.441c.587 0 1.063-.476 1.063-1.063V71.59c0-.587-.476-1.063-1.063-1.063h-7.44zm7.441-18.072c.587 0 1.063.476 1.063 1.063v9.568c0 .587-.476 1.063-1.063 1.063h-7.44a1.063 1.063 0 01-1.064-1.063v-9.568c0-.587.476-1.063 1.063-1.063h7.441zm-7.44-18.07c-.588 0-1.064.475-1.064 1.063v9.567c0 .587.476 1.063 1.063 1.063h7.441c.587 0 1.063-.476 1.063-1.063v-9.567c0-.588-.476-1.064-1.063-1.064h-7.44zM77.53 70.527c.587 0 1.063.476 1.063 1.063v9.568c0 .587-.476 1.063-1.063 1.063H70.09a1.063 1.063 0 01-1.063-1.063V71.59c0-.587.476-1.063 1.063-1.063h7.44zM70.09 52.456c-.587 0-1.063.476-1.063 1.063v9.568c0 .587.476 1.063 1.063 1.063h7.44c.588 0 1.064-.476 1.064-1.063v-9.568c0-.587-.476-1.063-1.063-1.063H70.09zm7.44-18.07c.588 0 1.064.475 1.064 1.063v9.567c0 .587-.476 1.063-1.063 1.063H70.09a1.063 1.063 0 01-1.063-1.063v-9.567c0-.588.476-1.064 1.063-1.064h7.44zM38.379 58.836H25.442c-.587 0-1.063.476-1.063 1.063v49.963c0 .587.476 1.063 1.063 1.063h12.937V58.836zM90 110.924h12.937c.587 0 1.063-.476 1.063-1.063V45.017c0-.588-.476-1.063-1.063-1.063H90v66.97z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgBuildingWhite1 = styled(BaseComponent)`
  outline: none;
`
export default SvgBuildingWhite1
