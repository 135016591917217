import {
  breakIdentifiers,
  breakpointIdentifiersList,
  createMediaQueries,
  themeBreakPoints,
} from './utils/createMediaQueries'

type Breakpoints = { [bp in breakIdentifiers | number]?: string }

export const mediaQueries = createMediaQueries(
  themeBreakPoints.map((bk: number) => `${bk}px`)
)

// font size
// index  0  1   2   3   4   5   6   7   8   9  10  11  12  13  14  15
// size   8, 9, 10, 12, 14, 16, 18, 20, 24, 30, 40, 50, 60, 70, 80, 90
export const fontSizes = [
  8,
  9,
  10,
  12,
  14,
  16,
  18,
  20,
  24,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
]

// index              0  1   2   3   4   5   6   7   8   9  10  11   12
export const space = [0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 80, 90, 100]
export const radii = [0, 2, 4, 8]

export type ColorsType = {
  success: string
  green: string[]
  background: string
  sand: string[]
  primary: string
  earth: string[]
  secondary: string
  black: string[]
  white: string
  red: string
  yellow: string
  orange: string
  blue: string[]
  functional: string
}

export const colors: ColorsType = {
  success: '#329C7F',
  green: ['#5BB099', '#99CEBF', '#CCE6DF'],
  background: '#E4E0D7',
  sand: ['#C9C6BE'],
  primary: '#4A4A48',
  earth: ['#EDEDED', '#DBDBDA'],
  secondary: '#2C2C2C',
  black: ['#414141', '#808080', '#ababab', '#f8f7f5'],
  white: '#fff',
  red: '#B92E2E',
  yellow: '#EDE443',
  orange: '#F3AD3D',
  blue: ['#3C5DB9'],
  functional: '#324E9C',
}

export const shadowColor = 'rgba(0, 0, 0, 0.125)'
export const baseShadow = '0 0 2px 0 rgba(0, 0, 0, .0625),'
export const shadows = [
  baseShadow + `0 2px 4px 0 ${shadowColor}`,
  baseShadow + `0 4px 8px 0 ${shadowColor}`,
  baseShadow + `0 12px 12px 0 ${shadowColor}`,
  baseShadow + `0 24px 24px 0 ${shadowColor}`,
]

type FontsTypes = {
  heading: string
  body: string
  monospace: string
}

export const fonts: FontsTypes = {
  heading: 'DIN Next LT Pro, Helvetica Neue, Helvetica, Arial, sans-serif',
  body: 'Sweet Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif',
  monospace: `"Mono Lisa", Monaco, monospace` /* Check out https://monolisa.dev for Mono Lisa font */,
}

export type ThemeProps = {
  colors: ColorsType
  space: number[]
  fontSizes: number[]
  radii: number[]
  fonts: FontsTypes
  fontWeights: {
    light: number
    normal: number
    bold: number
  }
  lineHeights: {
    body: number
    heading: number
  }
  letterSpacings: {
    normal: string
    body: string
    tight: string
    mega: string
  }
  borders: {
    none: string
    thin: string
    thick: string
  }
  shadows: string[]
  breakpoints: Breakpoints
}

const themeBreakPointsPx: Breakpoints = themeBreakPoints.map((bp) => `${bp}px`)

const breakpoints = breakpointIdentifiersList.reduce((acc, bp, index) => {
  acc[bp] = themeBreakPointsPx[index - 1] ?? '0'
  return acc
}, themeBreakPointsPx)

const theme = {
  colors,
  space,
  fontSizes,
  radii,
  fonts,
  fontWeights: {
    light: 200,
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.1,
  },
  letterSpacings: {
    normal: 'normal',
    body: '0.06em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  borders: {
    none: 'none',
    thin: '1px solid',
    thick: '2px solid',
  },
  shadows,
  breakpoints,
}

export default theme
