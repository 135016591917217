import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M110 112.893c0 .612-.496 1.107-1.107 1.107H18.107A1.107 1.107 0 0117 112.893V98.5c0-.611.496-1.107 1.107-1.107h90.786c.611 0 1.107.496 1.107 1.107v14.393zM110 89.643c0 .611-.496 1.107-1.107 1.107H18.107A1.107 1.107 0 0117 89.643V75.25c0-.611.496-1.107 1.107-1.107h90.786c.611 0 1.107.496 1.107 1.107v14.393z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgCoins1White = styled(BaseComponent)`
  outline: none;
`
export default SvgCoins1White
