import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2V1h5v1H5zm-.25-2A.75.75 0 004 .75V1h-.5A1.5 1.5 0 002 2.5v10A1.5 1.5 0 003.5 14h8a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0011.5 1H11V.75a.75.75 0 00-.75-.75h-5.5zM11 2v.25a.75.75 0 01-.75.75h-5.5A.75.75 0 014 2.25V2h-.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H11z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgClipboard = styled(BaseComponent)`
  outline: none;
`
export default SvgClipboard
