import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.364 24.555a.487.487 0 00-.487.487v7.745a1 1 0 01-1 1H11a1 1 0 00-1 1v23.339a1 1 0 001 1h32.11a1 1 0 001-1v-1.898a1 1 0 011-1h11.644a1 1 0 011 1v1.898a1 1 0 001 1h32.109a1 1 0 001-1V34.787a1 1 0 00-1-1H68.987a1 1 0 01-1-1v-7.745a.487.487 0 00-.488-.487H34.364zm6.335 6.847a1 1 0 011-1h18.466a1 1 0 011 1v.899a1 1 0 01-1 1H41.699a1 1 0 01-1-1v-.899z"
        fill="#4A4A48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.11 64.025a1 1 0 00-1-1H13.898a.487.487 0 01-.487-.488.974.974 0 00-.974-.974H11a1 1 0 00-1 1v30.648a1 1 0 001 1h79.863a1 1 0 001-1V62.563a1 1 0 00-1-1h-.95a.974.974 0 00-.974.974c0 .27-.218.488-.487.488H58.754a1 1 0 00-1 1v4.334a1 1 0 01-1 1H45.11a1 1 0 01-1-1v-4.334z"
        fill="#329C7F"
      />
      <rect
        x={56.602}
        y={94.9}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#F3AD3D"
      />
      <rect
        x={77.556}
        y={94.9}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#F3AD3D"
      />
      <rect
        x={98.509}
        y={94.9}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#F3AD3D"
      />
      <rect
        x={67.323}
        y={81.744}
        width={19.491}
        height={12.182}
        rx={1}
        fill="#F3AD3D"
      />
      <rect
        x={88.276}
        y={81.744}
        width={19.491}
        height={12.182}
        rx={1}
        fill="#F3AD3D"
      />
      <rect
        x={77.556}
        y={68.1}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#F3AD3D"
      />
    </Svg>
  )
}

const SvgBag = styled(BaseComponent)`
  outline: none;
`
export default SvgBag
