import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.45.95a.5.5 0 00-.5.5v2a.5.5 0 001 0v-1.5h1.5a.5.5 0 000-1h-2zm4.5 0a.5.5 0 000 1h3a.5.5 0 000-1h-3zm-.5 12.5a.5.5 0 01.5-.5h3a.5.5 0 110 1h-3a.5.5 0 01-.5-.5zm-3.5-7.5a.5.5 0 00-1 0v3a.5.5 0 001 0v-3zm11.5-.5a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5zm-2-4.5a.5.5 0 100 1h1.5v1.5a.5.5 0 001 0v-2a.5.5 0 00-.501-.5H11.45zm-10 10a.5.5 0 01.5.5v1.5h1.5a.5.5 0 110 1h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5zm12.5.5a.5.5 0 00-1 0v1.5h-1.5a.5.5 0 100 1h2a.5.5 0 00.5-.5v-2z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgGroup = styled(BaseComponent)`
  outline: none;
`
export default SvgGroup
