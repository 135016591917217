declare type PRect = Partial<DOMRect> & {
  readonly bottom: number
  readonly height: number
  readonly left: number
  readonly right: number
  readonly top: number
  readonly width: number
}

declare type Position = (
  targetRect?: PRect | null,
  popoverRect?: PRect | null
) => React.CSSProperties

const getDocumentDimensions = () => {
  const ownerDocument = document!
  const ownerWindow = ownerDocument.defaultView || window
  if (!ownerDocument) {
    return {
      width: 0,
      height: 0,
    }
  }

  return {
    width: ownerDocument.documentElement.clientWidth ?? ownerWindow.innerWidth,
    height:
      ownerDocument.documentElement.clientHeight ?? ownerWindow.innerHeight,
  }
}

const getCollisions = (
  targetRect: PRect,
  popoverRect: PRect,
  offsetLeft: number = 0,
  offsetBottom: number = 0
) => {
  const { width: windowWidth, height: windowHeight } = getDocumentDimensions()

  const collisions = {
    top: targetRect.top - popoverRect.height < 0,
    right: windowWidth < targetRect.left + popoverRect.width - offsetLeft,
    bottom:
      windowHeight < targetRect.bottom + popoverRect.height - offsetBottom,
    left: targetRect.left + targetRect.width - popoverRect.width < 0,
  }

  const directionRight = collisions.right && !collisions.left
  const directionLeft = collisions.left && !collisions.right
  const directionUp = collisions.bottom && !collisions.top
  const directionDown = collisions.top && !collisions.bottom

  return { directionRight, directionLeft, directionUp, directionDown }
}

const getTopPosition = (
  targetRect: PRect,
  popoverRect: PRect,
  isDirectionUp: boolean
) => ({
  top: isDirectionUp
    ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
    : `${targetRect.top + targetRect.height + window.pageYOffset}px`,
})

export const getPosition: Position = (targetRect, popoverRect) => {
  if (!targetRect || !popoverRect) {
    return {}
  }

  const { directionUp, directionRight } = getCollisions(targetRect, popoverRect)

  return {
    width: targetRect.width,
    left: directionRight
      ? `${targetRect.right - popoverRect.width + window.pageXOffset}px`
      : `${targetRect.left + window.pageXOffset}px`,
    ...getTopPosition(targetRect, popoverRect, directionUp),
  }
}
