import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 2h10a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5zM1 2.5A1.5 1.5 0 012.5 1h10A1.5 1.5 0 0114 2.5v10a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 011 12.5v-10zM7.5 4a.5.5 0 100-1 .5.5 0 000 1zM8 5.5a.5.5 0 11-1 0 .5.5 0 011 0zM7.5 8a.5.5 0 100-1 .5.5 0 000 1zm2.5-.5a.5.5 0 11-1 0 .5.5 0 011 0zm1.5.5a.5.5 0 100-1 .5.5 0 000 1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAspectRatio = styled(BaseComponent)`
  outline: none;
`
export default SvgAspectRatio
