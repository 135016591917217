import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-.691 3.516a4.5 4.5 0 11.707-.707l2.838 2.837a.5.5 0 01-.708.708L9.31 10.016zM4.25 6.5a.5.5 0 01.5-.5H6V4.75a.5.5 0 011 0V6h1.25a.5.5 0 010 1H7v1.25a.5.5 0 01-1 0V7H4.75a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgZoomIn = styled(BaseComponent)`
  outline: none;
`
export default SvgZoomIn
