import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.146 1.49a.5.5 0 01.708 0l5.657 5.656a.5.5 0 010 .708L7.854 13.51a.5.5 0 01-.708 0L1.49 7.854a.5.5 0 010-.708L7.146 1.49zM7.5 2.55L2.55 7.5l4.95 4.95 4.95-4.95L7.5 2.55z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgComponentInstance = styled(BaseComponent)`
  outline: none;
`
export default SvgComponentInstance
