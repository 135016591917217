import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.5a.5.5 0 11-1 0 .5.5 0 011 0zM5 13h5V2H5v11zm-1 0a1 1 0 001 1h5a1 1 0 001-1V2a1 1 0 00-1-1H5a1 1 0 00-1 1v11zm9.5-11a.5.5 0 100-1 .5.5 0 000 1zM2 3.5a.5.5 0 11-1 0 .5.5 0 011 0zm11.5.5a.5.5 0 100-1 .5.5 0 000 1zM2 5.5a.5.5 0 11-1 0 .5.5 0 011 0zm11.5.5a.5.5 0 100-1 .5.5 0 000 1zM2 7.5a.5.5 0 11-1 0 .5.5 0 011 0zm11.5.5a.5.5 0 100-1 .5.5 0 000 1zM2 9.5a.5.5 0 11-1 0 .5.5 0 011 0zm11.5.5a.5.5 0 100-1 .5.5 0 000 1zM2 11.5a.5.5 0 11-1 0 .5.5 0 011 0zm11.5.5a.5.5 0 100-1 .5.5 0 000 1zM2 13.5a.5.5 0 11-1 0 .5.5 0 011 0zm11.5.5a.5.5 0 100-1 .5.5 0 000 1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgContainer = styled(BaseComponent)`
  outline: none;
`
export default SvgContainer
