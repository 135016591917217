import React, { useState, forwardRef, useCallback, useEffect } from 'react'

import { Check, Cross1 } from '@linus-capital/icons'
import styled from 'styled-components'
import { ResponsiveValue } from 'styled-system'

import { Box } from '../../../atoms/Box'
import { MergeElementProps } from '../../../utils/types'
import FormField from '../../Forms/FormField'
import FormWrapper, { FormIconWrapper } from '../../Forms/FormWrapper'

export type Props = MergeElementProps<
  'input',
  {
    icon?: React.ReactNode
    multiline?: boolean
    rows?: number
    type?: string
    enableStatusIcons?: boolean
    onIconClick?: () => void
    error?: string
    inputHeight?: ResponsiveValue<number>
  }
>

const StyledCross = styled(Cross1).attrs(({ theme }) => ({
  color: theme.colors.red,
  width: 14,
}))``

const StyledCheck = styled(Check).attrs(({ theme }) => ({
  color: theme.colors.success,
  width: 14,
}))``

const TextFieldState = {
  CORRECT: 'CORRECT',
  ERROR: 'ERROR',
  CHANGING: 'CHANGING',
}

export const BasicTextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      id,
      error,
      value,
      defaultValue,
      onChange,
      onFocus,
      onBlur,
      icon,
      placeholder,
      disabled,
      multiline = false,
      width = '100%',
      enableStatusIcons = true,
      onIconClick,
      inputHeight = undefined,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(value ?? defaultValue)
    const [textFieldState, setTextFieldState] = useState(
      error ? TextFieldState.ERROR : TextFieldState.CHANGING
    )
    const [isFocused, setInternalFocus] = useState(false)
    const hasFocus = isFocused || !!internalValue
    const hasError = textFieldState === TextFieldState.ERROR

    useEffect(() => {
      setInternalValue(value)
    }, [value])

    useEffect(() => {
      if (error && !hasError) {
        setTextFieldState(TextFieldState.ERROR)
        // remove icons once the field was emptied after successful submission
      } else if (!value && textFieldState === TextFieldState.CORRECT) {
        setTextFieldState(TextFieldState.CHANGING)
      } else if (!isFocused && internalValue && !error) {
        setTextFieldState(TextFieldState.CORRECT)
      }
    }, [error, isFocused, value]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
        setInternalValue(event.target.value)
        setTextFieldState(TextFieldState.CHANGING)
      },
      [onChange]
    )

    const handleFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onFocus?.(event)
        setInternalFocus(true)
      },
      [onFocus]
    )

    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(event)
        setInternalFocus(false)
      },
      [onBlur]
    )

    return (
      <Box>
        <FormWrapper
          flexDirection="row"
          hasFocus={isFocused}
          hasError={hasError}
          disabled={!!disabled}
          width={isFocused ? width : `calc(${width}-2px)`}
          isValid={!!internalValue}
        >
          <FormField
            as={multiline ? 'textarea' : 'input'}
            name={name}
            id={id}
            ref={ref}
            hasError={hasError}
            value={internalValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            hasIconRight={icon || enableStatusIcons}
            disabled={disabled}
            inputHeight={inputHeight}
            {...props}
          />
          {icon && (
            <FormIconWrapper
              right={0}
              isInputFocus={hasFocus}
              error={hasError}
              disabled={disabled}
              onClick={onIconClick}
            >
              {icon}
            </FormIconWrapper>
          )}
          {enableStatusIcons &&
            !icon &&
            textFieldState !== TextFieldState.CHANGING && (
              <FormIconWrapper
                right={0}
                isInputFocus={hasFocus}
                error={hasError}
                disabled={disabled}
              >
                {hasError ? <StyledCross /> : <StyledCheck />}
              </FormIconWrapper>
            )}
        </FormWrapper>
      </Box>
    )
  }
)
