import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.818 4.682a.45.45 0 010 .636L3.086 7.05h8.828l-1.732-1.732a.45.45 0 01.636-.636l2.5 2.5a.45.45 0 010 .636l-2.5 2.5a.45.45 0 01-.636-.636l1.732-1.732H3.086l1.732 1.732a.45.45 0 11-.636.636l-2.5-2.5a.45.45 0 010-.636l2.5-2.5a.45.45 0 01.636 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgWidth = styled(BaseComponent)`
  outline: none;
`
export default SvgWidth
