import * as React from 'react'

import * as RebassForms from '@rebass/forms'
import styled from 'styled-components'

import { Box } from '../../atoms/Box'
import Text, { TextProps } from '../../atoms/Typography/Text'

// TODO: can get rid of RebassForms lib?
type LabelProps = Omit<RebassForms.LabelProps, 'css'>

const HelperOrErrorText = styled(Text).attrs({
  variant: 'BodyExtraExtraSmallBold',
})`
  padding-top: 3px;
  padding-left: 2px;
`

export type Props = {
  id?: string
  label?: number | string
  labelOptional?: string
  minHeight?: number | string
  error?: string
  errorProps?: TextProps
  labelProps?: LabelProps
  children: React.ReactNode
  helperText?: string
  name?: string | undefined
}

const FormItem = ({
  id,
  children,
  label,
  labelOptional,
  error,
  errorProps,
  helperText,
  minHeight = 25,
  name,
  ...other
}: Props) => (
  <Box {...other}>
    {label && (
      <Text as="label" variant="BodySmallMedium" htmlFor={id}>
        {label}
        {labelOptional?.trim() && (
          <Text as="span" color="black.1">
            <Text as="span" mx={1}>
              |
            </Text>
            {labelOptional}
          </Text>
        )}
      </Text>
    )}
    {children}
    <Box minHeight={minHeight}>
      {helperText && !error && (
        <HelperOrErrorText color="earth">{helperText}</HelperOrErrorText>
      )}
      {error && (
        <HelperOrErrorText
          color="red"
          {...errorProps}
          data-testid="errorMessage"
          data-test-name={name}
        >
          {error}
        </HelperOrErrorText>
      )}
    </Box>
  </Box>
)

export default FormItem
