import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.646 3.646a.5.5 0 000 .708L10.293 11H6a.5.5 0 000 1h5.5a.5.5 0 00.5-.5V6a.5.5 0 00-1 0v4.293L4.354 3.646a.5.5 0 00-.708 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgArrowBottomRight = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowBottomRight
