import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 .5a10.5 10.5 0 100 21 10.5 10.5 0 000-21zM9.5 15.193l-3.75-3.75 1.193-1.193L9.5 12.807l5.557-5.557 1.197 1.19L9.5 15.192z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCheckCircledDark = styled(BaseComponent)`
  outline: none;
`
export default SvgCheckCircledDark
