import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.4 96.2c0-.5.5-1 1-1h12c.5 0 1 .5 1 1v11c0 .6-.5 1-1 1h-12a1 1 0 01-1-1v-11z"
        fill="currentColor"
      />
      <path
        d="M12 105c0-.5.5-1 1-1h102c.5 0 1 .5 1 1v7c0 .5-.5 1-1 1H13a1 1 0 01-1-1v-7zm90.8-38.8a1 1 0 011.9 0L115.5 93a1 1 0 01-1 1.4H93a1 1 0 01-1-1.4l10.9-26.8zm-79.5 0a1 1 0 012 0L36 93a1 1 0 01-1 1.4H13.4a1 1 0 01-1-1.4l11-26.8z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7 17.2c0-.3.2-.6.5-.6h59.6c.3 0 .5.3.5.6v66.6l-4 10.3a1 1 0 00.9 1.4h3.1v8.7c0 .3-.2.6-.5.6H34.2a.6.6 0 01-.5-.6v-8.7h3.1a1 1 0 001-1.4l-4.1-10.3V17.2zM60 29.5c-.3 0-.6.3-.6.6v11.8c0 .3.3.6.6.6h7.8c.3 0 .6-.3.6-.6V30c0-.3-.3-.6-.6-.6H60zm-15.8.6c0-.3.3-.6.6-.6h7.9c.3 0 .5.3.5.6v11.8c0 .3-.2.6-.5.6h-7.9a.6.6 0 01-.6-.6V30zm.6 18c-.3 0-.6.2-.6.5v11.8c0 .3.3.6.6.6h7.9c.3 0 .5-.3.5-.6V48.6c0-.3-.2-.5-.5-.5h-7.9zm-.6 19c0-.3.3-.5.6-.5h7.9c.3 0 .5.2.5.6v11.7c0 .4-.2.6-.5.6h-7.9a.6.6 0 01-.6-.6V67.2zm15.8-19c-.3 0-.6.2-.6.5v11.8c0 .3.3.6.6.6h7.8c.3 0 .6-.3.6-.6V48.6c0-.3-.3-.5-.6-.5H60zm-.6 19c0-.3.3-.5.6-.5h7.8c.3 0 .6.2.6.6v11.7c0 .4-.3.6-.6.6H60a.6.6 0 01-.6-.6V67.2zm15.7-.5c-.3 0-.5.2-.5.6v11.7c0 .4.2.6.5.6H83c.3 0 .5-.2.5-.6V67.2c0-.4-.2-.6-.5-.6h-7.9zm-.5-18c0-.3.2-.5.5-.5H83c.3 0 .5.2.5.5v11.8c0 .3-.2.6-.5.6h-7.9a.6.6 0 01-.5-.6V48.6zm.5-19c-.3 0-.5.2-.5.5v11.8c0 .3.2.6.5.6H83c.3 0 .5-.3.5-.6V30c0-.3-.2-.6-.5-.6h-7.9zM60 90.3a1 1 0 00-1 1v12.2c0 .6.5 1 1 1h8.2c.5 0 1-.4 1-1V91.4c0-.6-.5-1-1-1H60z"
        fill="currentColor"
      />
      <path
        d="M100 94c0-.5.5-1 1-1h7c.5 0 1 .5 1 1v13c0 .5-.5 1-1 1h-7a1 1 0 01-1-1V94zm-81 0c0-.5.5-1 1-1h7c.5 0 1 .5 1 1v13c0 .5-.5 1-1 1h-7a1 1 0 01-1-1V94z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBuilding2 = styled(BaseComponent)`
  outline: none;
`
export default SvgBuilding2
