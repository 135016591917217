import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61.063a.45.45 0 00-.528.27l-.928 2.32-2.321.93a.45.45 0 00-.193.687l1.371 1.829-.948 3.792a.45.45 0 00.264.524L4.2 11.613l1.425 2.137a.45.45 0 00.498.183l3.5-1a.41.41 0 00.018-.006l3-1a.45.45 0 00.297-.33l1-4.5a.45.45 0 00-.169-.457l-1.82-1.365V3a.45.45 0 00-.249-.402l-3-1.5a.45.45 0 00-.092-.035l-4-1zm6.822 5.949l1.343 1.007-2.563 2.135a.45.45 0 00-.139.204l-.82 2.457-1.515-.947a.45.45 0 10-.477.764l1.142.713-2.21.632L4.873 11a.45.45 0 00-.2-.165L2.03 9.734l.821-3.284H5a.45.45 0 100-.9H2.725L1.712 4.2l1.802-.721 2.844.948a.45.45 0 00.493-.146l1.773-2.216 2.426 1.213v1.917l-2.217.887a.45.45 0 00-.26.276l-.5 1.5a.45.45 0 10.854.284L9.36 6.84l2.071-.828zM7.711 1.766L6.345 3.474l-2.25-.75.677-1.692 2.939.734zm2.5 10.023l1.905-.635.659-2.964-1.889 1.574-.675 2.025z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCrumpledPaper = styled(BaseComponent)`
  outline: none;
`
export default SvgCrumpledPaper
