import { useState } from 'react'

import {
  breakIdentifiers,
  breakpointsWidthMap,
  mapBreakpointIdentifierToIndex,
} from '../utils/createMediaQueries'
import { useMedia } from './useMedia'

export type BreakpointChangeEventListener = (breakpointChanged: {
  previous: breakIdentifiers | undefined
  current: breakIdentifiers
}) => void

const MOBILE_BREAKPOINT = 'sm'

const mediaQueries = Object.values(breakpointsWidthMap).map(
  ({ minWidth, maxWidth }) =>
    `(min-width: ${minWidth}) and (max-width: ${maxWidth}) `
)

const mediaQueriesLabels = Object.keys(breakpointsWidthMap)

export const useBreakpoints = (
  handleBreakpointChange?: BreakpointChangeEventListener
) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<
    breakIdentifiers | undefined
  >(undefined)
  const breakpointIdentifier = useMedia(
    mediaQueries,
    mediaQueriesLabels as breakIdentifiers[],
    MOBILE_BREAKPOINT as breakIdentifiers
  )

  if (currentBreakpoint !== breakpointIdentifier) {
    if (handleBreakpointChange) {
      handleBreakpointChange({
        previous: currentBreakpoint,
        current: breakpointIdentifier,
      })
    }

    setCurrentBreakpoint(breakpointIdentifier)
  }
  const breakpointIndex = mapBreakpointIdentifierToIndex(breakpointIdentifier)
  return { breakpointIdentifier, breakpointIndex }
}
