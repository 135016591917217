import React, { forwardRef } from 'react'

import FormItem, { Props as FormItemProps } from '../../Forms/FormItem'
import { BasicTextField, Props as BasicTextFieldProps } from './BasicTextField'

// +..<FormItem>................................................................+
// . *==<FormWrapper>=========================================================* .
// . I *--<FormField>-------------------------------------------------------* I .
// . I |  +--<FromIconWrapper>--+ | I .
// . I |  |      <SVG>          | | I .
// . I |  +---------------------+ | I .
// . I | ░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒ | I .
// . I *--------------------------------------------------------------------* I .
// . *========================================================================* .
// +............................................................................+

export type Props = BasicTextFieldProps & Omit<FormItemProps, 'children'>

const TextField = forwardRef<HTMLInputElement, Props>(
  (
    { id, label, labelOptional, error, errorProps, helperText, name, ...props },
    ref
  ) => (
    <FormItem
      id={id}
      label={label}
      labelOptional={labelOptional}
      error={error}
      errorProps={errorProps}
      helperText={helperText}
      name={name}
    >
      <BasicTextField ref={ref} id={id} error={error} name={name} {...props} />
    </FormItem>
  )
)

export default TextField
