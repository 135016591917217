import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.5 47.6c.6 0 1 .4 1 1v15.8c0 .5.3.9.8 1a11.4 11.4 0 11-15 11v-1.9c0-.5.4-1 1-1h3.5c.6 0 1 .5 1 1v1.8a5.9 5.9 0 106.6-5.8 3.8 3.8 0 01-3.4-3.8V48.6c0-.6.4-1 1-1h3.5z"
        fill="currentColor"
      />
      <path
        d="M50.8 73.4l10.4-8.7a1 1 0 011.6.8v17.3a1 1 0 01-1.6.8L50.8 75a1 1 0 010-1.5zm6-13l-10.4-8.7a1 1 0 00-1.6.8v17.3a1 1 0 001.6.8L56.8 62a1 1 0 000-1.5zm-6 39l10.4-8.7a1 1 0 011.6.7v17.4a1 1 0 01-1.6.8l-10.4-8.7a1 1 0 010-1.5zm6-13l-10.4-8.7a1 1 0 00-1.6.7v17.4a1 1 0 001.6.8l10.4-8.7a1 1 0 000-1.5zm4.4-28.3l-7.1-6a1 1 0 01.6-1.8h7.1c.6 0 1 .5 1 1v6a1 1 0 01-1.6.8zm-14.8 45.6l7.1 6a1 1 0 01-.6 1.8h-7.1a1 1 0 01-1-1v-6a1 1 0 011.6-.8z"
        fill="currentColor"
      />
      <path
        d="M116.4 49.2l-7.5-8.3a1 1 0 00-.7-.3h-89a1 1 0 00-1 1v8.3c0 .5.5 1 1 1h96.4a1 1 0 00.8-1.7z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.5 11.7a1 1 0 011.7.7v30.3c0 .5-.4 1-1 1h-3.5a1 1 0 01-1-1V25.6a1 1 0 00-1.7-.7l-20.5 20a1 1 0 01-1.4 0l-2.5-2.5a1 1 0 010-1.4l30-29.3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49 12a1 1 0 011.5-1l51.6 29.8c.5.2.7.9.4 1.3l-1.8 3.1a1 1 0 01-1.3.4L56 20.6a1 1 0 00-1.5.9v21.2c0 .5-.5 1-1 1H50a1 1 0 01-1-1V11.9z"
        fill="currentColor"
      />
      <rect x={39} y={109} width={30} height={9} rx={1} fill="currentColor" />
    </Svg>
  )
}

const SvgDevelopment = styled(BaseComponent)`
  outline: none;
`
export default SvgDevelopment
