import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
      <path
        d="M41.5 47.1L29.6 69.4a1 1 0 00.3 1.3l18.5 13.6c1.5 1.1 3.2 1.9 5 2.2L69 89.8a13 13 0 0012-3.6l7.7-7.9c4-4 4.9-10.2 2.2-15.2l-5.7-10.8a10.6 10.6 0 00-9.3-5.6c-1.8 0-3.5-.4-5-1.3l-4-2.2c-3-1.6-6.6-2-9.9-1.1l-14.8 4.4c-.4.2-.6.4-.7.6z"
        fill="#4a4a48"
      />
      <path
        d="M69.2 45.4h15.9c.4 0 .7.2.9.5L97.7 69c.2.4.1.9-.2 1.2l-4.6 4.5a3 3 0 01-3.8.3l-13.8-9.4a3.2 3.2 0 00-3.6.1c-6.1 4.3-11.2 4.5-13 3.9C46.5 66.3 47 66 51.8 61l15.4-14.7c.5-.6 1.3-.9 2-.9z"
        fill="#f3ad3d"
      />
      <path
        d="M9.5 65.2L24 36.9c.3-.5.9-.7 1.3-.4l11.9 6.1c.5.3.7.9.4 1.3L23.2 72.2c-.3.5-.9.7-1.3.4l-12-6.1c-.5-.2-.7-.8-.4-1.3zm109 0L104 36.9c-.3-.5-.9-.7-1.3-.4l-11.9 6.1c-.5.3-.7.9-.4 1.3l14.5 28.3c.3.5.9.7 1.3.4l11.9-6.1c.5-.2.7-.8.4-1.3z"
        fill="#329c7f"
      />
    </Svg>
  )
}

const SvgShakehand = styled(BaseComponent)`
  outline: none;
`
export default SvgShakehand
