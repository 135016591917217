import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M22 11c0 6.1-4.9 11-11 11S0 17.1 0 11 4.9 0 11 0s11 4.9 11 11zM10.1 5.1l-5.8 5.4c-.2.1-.3.3-.3.5l.2.5 5.8 5.4c.3.3.8.3 1 0 .3-.3.3-.8 0-1.1l-4.4-4.1h10.5c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7H6.6L11 6.2c.3-.3.3-.7 0-1-.2-.4-.6-.4-.9-.1z"
      />
    </Svg>
  )
}

const SvgArrowLeftCircle = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowLeftCircle
