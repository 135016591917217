import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.646 11.354a.5.5 0 010-.707L10.293 4H6a.5.5 0 010-1h5.5a.5.5 0 01.5.5V9a.5.5 0 01-1 0V4.707l-6.646 6.647a.5.5 0 01-.708 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgArrowTopRight = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowTopRight
