import React, { useRef, forwardRef, RefObject } from 'react'

import { Transition, TransitionStatus } from 'react-transition-group'
import { CSSProperties } from 'styled-components'

import { Box } from '../../atoms/Box'

const DURATION = 300

type Props = {
  open: boolean
  transitionStyles?: Record<TransitionStatus, CSSProperties>
  duration?: number
  children: React.ReactNode
}

const Fade = forwardRef<HTMLElement, Props>((props, forwardedRef) => {
  const localRef = useRef<HTMLDivElement>(null)
  const ref = (forwardedRef as RefObject<HTMLDivElement>) || localRef

  const transitionStylesDefault = {
    entering: {
      opacity: 0,
      height: ref.current?.scrollHeight,
    },
    entered: { opacity: 1, height: 'auto' },
    exiting: {
      opacity: 1,
      height: ref.current?.scrollHeight,
    },
    exited: { opacity: 0, height: 0 },
    unmounted: { opacity: 0, height: 0 },
  }
  const {
    open,
    duration = DURATION,
    transitionStyles = transitionStylesDefault,
    children,
  } = props

  return (
    <Transition in={open} timeout={duration} nodeRef={ref}>
      {(state: TransitionStatus) => (
        <Box
          ref={ref}
          css={{
            transition: `all ${duration}ms ease-in-out`,
            overflow: 'hidden',
            ...transitionStyles[state],
          }}
        >
          {children}
        </Box>
      )}
    </Transition>
  )
})

export default Fade
