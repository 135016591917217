import * as React from 'react'

import { SystemStyleObject } from '@styled-system/css'
import styled, { StyledComponent } from 'styled-components'
import {
  compose,
  space,
  color,
  border,
  layout,
  typography,
  shadow,
  position,
  flex,
  flexBasis,
  flexGrow,
  flexShrink,
  order,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  BordersProps,
  ShadowProps,
  PositionProps,
  BackgroundColorProps,
  FlexProps,
  FlexBasisProps,
  FlexGrowProps,
  FlexShrinkProps,
  OrderProps,
  grid,
  GridProps,
} from 'styled-system'

export type BoxProps = {
  children?: React.ReactNode | undefined
  color?: string
  tabIndex?: number
  css?: Omit<SystemStyleObject, 'children'>
} & SpaceProps &
  LayoutProps &
  TypographyProps &
  BordersProps &
  ShadowProps &
  PositionProps &
  BackgroundColorProps &
  FlexProps &
  FlexBasisProps &
  FlexGrowProps &
  FlexShrinkProps &
  OrderProps &
  GridProps

const cssStyles = (props: BoxProps) => props.css

export const Box: StyledComponent<'div', any, BoxProps> = styled(
  'div'
).attrs<BoxProps>(({ tabIndex }) => ({
  tabIndex,
}))(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  cssStyles,
  compose(
    space,
    color,
    border,
    layout,
    typography,
    shadow,
    position,
    flex,
    flexBasis,
    flexShrink,
    flexGrow,
    order,
    grid
  )
)

export default Box
