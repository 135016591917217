import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={12}
        y={109.935}
        width={103.229}
        height={8.065}
        rx={0.935}
        fill="#F3AD3D"
      />
      <rect
        x={16.302}
        y={73.374}
        width={25.807}
        height={30.108}
        rx={0.935}
        fill="#4A4A48"
      />
      <rect
        x={51.759}
        y={23.177}
        width={8.951}
        height={46.752}
        rx={0.935}
        transform="rotate(45.268 51.759 23.177)"
        fill="#329C7F"
      />
      <rect
        x={50.711}
        y={56.169}
        width={25.807}
        height={47.313}
        rx={0.935}
        fill="#4A4A48"
      />
      <rect
        x={85.12}
        y={38.965}
        width={25.807}
        height={64.518}
        rx={0.935}
        fill="#4A4A48"
      />
      <path
        d="M62.144 17.537c.521 0 .941.428.933.95L62.74 40.45c-.013.83-1.02 1.233-1.602.642L39.508 19.098c-.581-.591-.162-1.591.668-1.59l21.968.03z"
        fill="#329C7F"
      />
    </Svg>
  )
}

const SvgIncrease = styled(BaseComponent)`
  outline: none;
`
export default SvgIncrease
