import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
      <path
        d="M85.2 40c-.1-.6-.7-.9-1.2-.8-5.4 1.2-11 1.8-16.5 2a1 1 0 00-1 1v18.3c0 .6.4 1 1 1h19.6c.6 0 1-.5 1-1-.3-7.1-1.7-13.6-2.9-20.5zM67.8 10.2c-.6-.3-1.3.2-1.3.9v24.3c0 .6.5 1.1 1.1 1l15.5-2c.6-.1.9-.7.7-1.3-4-11.9-10-20.3-16-22.9zm-24.2 23c-.2.6.1 1.1.7 1.3a97 97 0 0016.2 2.1c.6 0 1-.4 1-1V11.3c0-.7-.6-1.1-1.2-.9-6.3 2.5-12.5 10.7-16.7 22.8zm-3.7 27.3c0 .6.4 1 1 1h19.6c.6 0 1-.4 1-1V41.8c0-.5-.4-1-1-1a93 93 0 01-16.6-2.1c-.5-.1-1.1.2-1.2.7-1.7 6.8-2.7 14-2.8 21.1zM88.1 67c0-.6-.4-1-1-1H67.5c-.6 0-1 .4-1 1v18.8c0 .5.4 1 1 1 5.9.2 11.1.9 16.6 2.1.5.1 1.1-.2 1.2-.7A94.8 94.8 0 0088.1 67zM60.5 86.3c.5 0 1-.5 1-1V67c0-.6-.4-1-1-1H40.9a1 1 0 00-1 1c.2 7.3 1.5 14.2 3.3 21 .1.6.7.9 1.3.7a66.6 66.6 0 0116-2.4zm-.2 31.3c.6.3 1.2-.2 1.2-.9V92.4c0-.6-.5-1-1-1-5.7.2-10.6.8-15.9 2-.4.1-.8.5-.7 1 .5 7.8 8.9 20 16.4 23.2zm6.2-.9c0 .7.6 1.1 1.3.9 6.3-2.6 12.5-10.7 16.7-22.8.2-.6-.1-1.2-.7-1.3a87.5 87.5 0 00-16.2-2c-.6 0-1 .4-1 1v24.2z"
        fill="#329c7f"
      />
      <path
        d="M91.4 61.5H117c.6 0 1-.5 1-1a51 51 0 00-12-29.9c-.3-.3-.7-.4-1.1-.3C99 32.6 94 34.9 89 36.8c-.5.2-.7.7-.6 1.2 1.9 7.6 3 15.6 3 23.5zm9.5 40.2c.5-.4.4-1.2-.2-1.5-4-2.1-8.1-3.7-12.4-5.1-.3-.1-.6.1-.7.4-1.8 6-6.7 13.5-9.8 17.7-.6.8 0 1.6.9 1.2a103 103 0 0022.2-12.7zm-25.6-89c-.5-.7 0-1.4.8-1.2a68.3 68.3 0 0124.8 14.3c.5.5.4 1.3-.2 1.6a64.3 64.3 0 01-12.4 5.3c-.5.1-1-.1-1.2-.6-4.6-10.9-9.5-16.5-11.8-19.4zM117.9 66c.6 0 1 .5 1 1a44.6 44.6 0 01-12.8 29.9 1 1 0 01-1.2.2A75.2 75.2 0 0090 90.7c-.5-.1-.9-.7-.7-1.2A101 101 0 0092.5 67c0-.6.5-1 1-1h24.4z"
        fill="#4a4a48"
      />
      <path
        d="M52.2 12.7c.5-.7 0-1.4-.8-1.2a68.3 68.3 0 00-24.8 14.3c-.5.5-.4 1.3.2 1.6 3.9 2.1 8.2 4.1 12.4 5.3.5.1 1-.1 1.2-.6 4.6-10.9 9.5-16.5 11.8-19.4zM39.8 37.9c.1-.5-.2-1-.6-1.2-3.7-1.4-12-4.9-15.9-6.3-.4-.1-.8 0-1.1.3a52.1 52.1 0 00-12.1 29.8c0 .6.4 1 1 1h24.4c.5 0 1-.4 1-1 .2-7.6 1.9-16.4 3.3-22.6zM11.1 66a1 1 0 00-1 1c.8 11.4 5 21.6 12 29.9.3.3.7.4 1.1.3 5.1-1.9 13.2-5.1 16-6.4.4-.2.6-.7.5-1.1A97 97 0 0136.6 67c0-.6-.5-1-1-1H11.1zm37.5 49.5c.9.3 1.6-.8 1-1.5a47.4 47.4 0 01-8.4-16.6c-.2-.6-.7-.9-1.3-.7a65.3 65.3 0 00-12 4.8c-.5.3-.7.9-.3 1.4a51 51 0 0021 12.6z"
        fill="#f3ad3d"
      />
    </Svg>
  )
}

const SvgGlobeColor = styled(BaseComponent)`
  outline: none;
`
export default SvgGlobeColor
