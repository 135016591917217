import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 94 20"
      {...props}
    >
      <defs>
        <clipPath id="idNow_svg__a">
          <path fill="none" d="M0 0h94v20H0z" />
        </clipPath>
        <mask
          id="idNow_svg__b"
          x={12.76}
          y={0}
          width={81.24}
          height={20}
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M94 0v20H12.76v-.58h2.53a9.84 9.84 0 009.9-9.58l.01-.16A9.8 9.8 0 0015.46 0h-.17z"
            fill="#fff"
            data-name="mask0 3322 31834"
          />
        </mask>
      </defs>
      <g clipPath="url(#idNow_svg__a)">
        <g mask="url(#idNow_svg__b)" fill="#1c1e39">
          <path d="M88.88 3.42a.43.43 0 00-.35-.17h-3.6a.43.43 0 00-.42.3l-2.48 7.93-3.76-7.93a.43.43 0 00-.1-.17.45.45 0 00-.32-.13h-1.58a.44.44 0 00-.31.13.42.42 0 00-.1.16l-3.77 7.94-2.48-7.93a.43.43 0 00-.41-.3h-3.61a.44.44 0 00-.35.17.4.4 0 00-.06.37l5 15.33a.43.43 0 00.41.3h2.33a.43.43 0 00.4-.25l3.74-7.94 3.75 7.94a.43.43 0 00.39.25h2.33a.43.43 0 00.4-.3L88.95 3.8a.4.4 0 00-.06-.37zM55.36 2.8a8.75 8.75 0 018.88 8.6 8.89 8.89 0 01-17.77 0 8.76 8.76 0 018.89-8.6zm0 4.2a4.47 4.47 0 00-4.54 4.4 4.54 4.54 0 009.07 0A4.47 4.47 0 0055.36 7zM43.7 10.57V19a.42.42 0 01-.44.42h-3.53a.42.42 0 01-.43-.42v-8.59c0-2.18-1.01-3.38-2.86-3.38A4.97 4.97 0 0032.48 9v10a.42.42 0 01-.43.42H28.5a.42.42 0 01-.43-.42V3.67a.42.42 0 01.43-.42h2.4a.43.43 0 01.43.32l.26 1.09a8.79 8.79 0 015.3-1.8c4.51 0 6.8 2.6 6.8 7.7z" />
        </g>
        <path
          d="M91.22 14.58A2.75 2.75 0 0194 17.26a2.8 2.8 0 01-5.59-.01 2.77 2.77 0 012.8-2.67z"
          fill="#ff6b40"
        />
        <path
          d="M15.3 0a9.8 9.8 0 019.9 9.68 9.83 9.83 0 01-9.9 9.74H8.4a.42.42 0 01-.42-.42V.42A.42.42 0 018.4 0zm-.1 4.05h-2.88v11.3h2.65a5.54 5.54 0 004.37-1.97 6.1 6.1 0 001.08-5.33 5.33 5.33 0 00-5.21-4zM3.91 0H.43A.42.42 0 000 .42L.01 19a.42.42 0 00.43.42h3.47a.42.42 0 00.43-.42V.42A.42.42 0 003.91 0z"
          fill="#1c1e39"
        />
      </g>
    </Svg>
  )
}

const SvgIdNow = styled(BaseComponent)`
  outline: none;
`
export default SvgIdNow
