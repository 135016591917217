import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6h8a1.5 1.5 0 010 3h-8a1.5 1.5 0 110-3zM1 7.5A2.5 2.5 0 013.5 5h8a2.5 2.5 0 010 5h-8A2.5 2.5 0 011 7.5zM4.5 7a.5.5 0 000 1h6a.5.5 0 000-1h-6z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBadge = styled(BaseComponent)`
  outline: none;
`
export default SvgBadge
