import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.5a2.5 2.5 0 01-2 2.45v7.55a.5.5 0 01-1 0V5.95a2.5 2.5 0 113-2.45z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSewingPinFilled = styled(BaseComponent)`
  outline: none;
`
export default SvgSewingPinFilled
