import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.354 2.354a.5.5 0 00-.708-.708l-11 11a.5.5 0 00.708.708l11-11zM2.037 8.467c.137.66.632 1.204 1.302 1.426l-.76.76a2.91 2.91 0 01-1.52-1.983c-.06-.283-.06-.61-.06-1.107v-.126c0-.497 0-.824.06-1.107.24-1.16 1.179-2.05 2.36-2.275C3.706 4 4.04 4 4.563 4H5.5a.5.5 0 010 1h-.875c-.604 0-.836.002-1.02.037-.802.154-1.413.752-1.568 1.496-.035.17-.037.385-.037.967 0 .583.002.798.037.967zm10.925-1.934a1.935 1.935 0 00-1.301-1.426l.76-.76a2.91 2.91 0 011.52 1.983c.059.283.059.61.059 1.107v.126c0 .497 0 .824-.059 1.107-.24 1.16-1.18 2.05-2.36 2.275-.288.055-.623.055-1.146.055H9.5a.5.5 0 110-1h.875c.604 0 .835-.002 1.019-.037.803-.154 1.414-.752 1.568-1.496.035-.17.038-.384.038-.967 0-.582-.003-.798-.038-.967z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLinkBreak1 = styled(BaseComponent)`
  outline: none;
`
export default SvgLinkBreak1
