import * as React from 'react'

import * as RebassForms from '@rebass/forms'

import { Box } from '../../atoms/Box'

export type LabelProps = Omit<RebassForms.LabelProps, 'css'>
export type InputProps = Omit<RebassForms.InputProps, 'css'>

export const Label = (props: LabelProps) => <RebassForms.Label {...props} />

export const Svg = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="currentcolor"
    {...props}
  />
)
