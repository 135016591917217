import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.05V4h3v3.05H6zm-1 0H1.5a.45.45 0 000 .9H5v3.3c0 .414.336.75.75.75h3.5a.75.75 0 00.75-.75v-3.3h3.5a.45.45 0 000-.9H10v-3.3A.75.75 0 009.25 3h-3.5a.75.75 0 00-.75.75v3.3zm4 .9V11H6V7.95h3z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAlignCenter = styled(BaseComponent)`
  outline: none;
`
export default SvgAlignCenter
