import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.25a.5.5 0 00-1 0V7.1H2.5a.4.4 0 100 .8H4v.65a3.5 3.5 0 107 0V7.9h1.5a.4.4 0 000-.8H11V3.25a.5.5 0 10-1 0V7.1H5V3.25zM5 7.9v.65a2.5 2.5 0 005 0V7.9H5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgStrikethrough = styled(BaseComponent)`
  outline: none;
`
export default SvgStrikethrough
