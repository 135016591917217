import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26 3.167L4.37 5.333V1l2.89 2.167zM1 8.222l2.889-2.166L1 3.889v4.333zM1 14l2.889-2.167L1 9.667V14zm6.74-5.778l2.89-2.166-2.89-2.167v4.333zM14 3.167l-2.889 2.166V1L14 3.167zm-2.889 7.944L14 8.944l-2.889-2.166v4.333zm-7.222 0L1 8.944l2.889-2.166v4.333zm.481-5.055l2.89 2.166V3.89L4.37 6.056zm-.481-.723L1 3.167 3.889 1v4.333zM7.74 3.167l2.889 2.166V1L7.74 3.167zM14 8.222l-2.889-2.166L14 3.889v4.333zm-2.889 3.611L14 14V9.667l-2.889 2.166z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgModulzLogo = styled(BaseComponent)`
  outline: none;
`
export default SvgModulzLogo
