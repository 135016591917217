import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V6h2.5a.5.5 0 01.407.79l-5 7a.5.5 0 01-.814 0l-5-7A.5.5 0 012.5 6H5V3.5zM6 4v2.5a.5.5 0 01-.5.5H3.472L7.5 12.64 11.528 7H9.5a.5.5 0 01-.5-.5V4H6z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgThickArrowDown = styled(BaseComponent)`
  outline: none;
`
export default SvgThickArrowDown
