import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 1.05a.45.45 0 01.45.45v12a.45.45 0 01-.9 0v-12a.45.45 0 01.45-.45zm-12 0a.45.45 0 01.45.45v12a.45.45 0 01-.9 0v-12a.45.45 0 01.45-.45zM4 6h7v3H4V6zm7.25-1a.75.75 0 01.75.75v3.5a.75.75 0 01-.75.75h-7.5A.75.75 0 013 9.25v-3.5A.75.75 0 013.75 5h7.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgJustifyStretch = styled(BaseComponent)`
  outline: none;
`
export default SvgJustifyStretch
