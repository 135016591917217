export const noop = () => {}

export const isBrowser = typeof window !== 'undefined'

export const isNavigator = typeof navigator !== 'undefined'

export const hexToRgb = (hex: string, alpha: number = 1) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const rgb = result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null

  return alpha !== 1 ? `rgba(${rgb}, ${alpha})` : `rgb(${rgb})`
}

export const generateUniqueId = (): string =>
  Math.random().toString(36).substring(2, 9)

export const safeParseInt = (value: string | number, radix = 10): number =>
  typeof value === 'number' ? value : parseInt(value, radix)

export const extractInitialsFromName = (name: string): string => {
  if (name) {
    const nameParts = name
      .split(' ')
      .filter((part) => part) // remove empty strings
      .map((part) => part[0].toUpperCase()) // get first letters

    if (nameParts.length === 2) {
      return `${nameParts[0]}${nameParts[1]}`
    } else if (nameParts.length > 2) {
      return `${nameParts[0]}${nameParts[2]}`
    }
    if (nameParts.length > 0) {
      return nameParts[0]
    }
  }
  return ''
}

export const partition = <T>(arr: T[], predicate: (value: T) => boolean) =>
  arr.reduce(
    (acc, cur) => {
      acc[predicate(cur) ? 0 : 1].push(cur)
      return acc
    },
    [[], []] as T[][]
  )

export const omit = <T extends object, K extends keyof T>(obj: T, keys: K[]) =>
  Object.keys(obj)
    .filter((k) => !keys.includes(k as K))
    .reduce((res, k) => Object.assign(res, { [k]: obj[k as K] }), {})
