import * as React from 'react'

import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components'

import theme, { ThemeProps } from './theme'

const CSSReset = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-weight: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
  }
  html,
  body {
    min-height: 100%;
    min-width: 100%;
  }
  body {
    padding: 0;
    margin: 0;
    font-size: ${theme.fontSizes[5]}px;
    font-family: ${theme.fonts.body};
    line-height: ${theme.lineHeights.body};
    letter-spacing: ${theme.letterSpacings.body};
    color: ${theme.colors.secondary};
    position: relative;
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
  a {
    color: currentColor;
    text-decoration: none;
  }
  textarea {
    resize: none;
  }
  strong {
    font-weight: bold;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
`

type Props = {
  children: React.ReactNode
  theme: ThemeProps
}

const ThemeProvider = ({ children, theme }: Props) => (
  <StyledThemeProvider theme={theme}>
    <CSSReset />
    {children}
  </StyledThemeProvider>
)

ThemeProvider.defaultProps = {
  theme,
}

export default ThemeProvider
