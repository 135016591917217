import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.7 86s-5.3-1-7.4 0c-1.5.7-7 2.7-10.1 3.8a1 1 0 00-.7 1v18.5c0 .6.5 1 1.1 1a82 82 0 015.7-.2c8.6.4 13 4 21.5 4.6 4.8.4 9 1.2 12.4 0 3.3-1.2 22.2-10.4 24.6-12.8 1-.6-2.4-9.6-5-8.6-7 2.6-18.2 7.8-22.2 7.6-4-.3-14.7-2.2-14.7-2.2l12.2.1s1.7.1 4.1-1.6c2.4-1.7 4.5-6.5 1-6.5a28 28 0 01-7.4-1.2L42.7 86z"
        fill="#4A4A48"
      />
      <rect x={7} y={87.2} width={12.2} height={26.7} rx={1} fill="#329C7F" />
      <path
        d="M86.5 51.8s5.3-.1 7.2-1.5c1.3-1 6.2-4 9-5.9.4-.2.6-.6.5-1l-4-18.1a1 1 0 00-1.2-.8c-1.8.5-4.3 1.2-5.5 1.4-8.5 1.5-13.5-1.1-22 0-4.8.7-9.1.8-12 2.7-3 1.9-19.6 14.9-21.4 17.8-1 .8 4.3 8.9 6.7 7.3 6.2-4 16.1-11.5 20-12.2 4-.6 15-1 15-1l-12 2.5s-1.8.3-3.8 2.5c-2 2.1-3 7.3.5 6.6 3.5-.8 7.4-.4 7.4-.4l15.6.1z"
        fill="#4A4A48"
      />
      <rect
        x={121.1}
        y={43.2}
        width={12.2}
        height={26.7}
        rx={1}
        transform="rotate(167.8 121 43.2)"
        fill="#fff"
      />
      <path fill="#4A4A48" d="M19 35.6L41.6 13 87 58.2 64.2 81z" />
      <path
        d="M25.3 35.6v-1.3l15-15H43l37.7 37.6v2.6l-15 15-1.4.1H63L25.3 36.9v-1.3z"
        fill="#329C7F"
      />
      <circle
        cx={38.2}
        cy={32.2}
        r={3}
        transform="rotate(-45 38.2 32.2)"
        fill="#F3AD3D"
      />
      <circle
        cx={67.2}
        cy={61.2}
        r={3}
        transform="rotate(-45 67.2 61.2)"
        fill="#F3AD3D"
      />
      <ellipse
        cx={52.5}
        cy={46.5}
        rx={7.5}
        ry={6.5}
        transform="rotate(-45 52.5 46.5)"
        fill="#F3AD3D"
      />
      <path
        d="M93.7 50.3c-2 1.4-7.2 1.5-7.2 1.5H70.9s-4-.5-7.4.3c-3.5.7-2.4-4.5-.5-6.6 2-2.2 3.7-2.5 3.7-2.5l12-2.4L70.5 26c8.5-1.2 13.5 1.4 22-.1 1.2-.2 3.7-.9 5.5-1.4a1 1 0 011.3.8l3.9 18c0 .5-.1 1-.5 1.1-2.8 1.8-7.7 4.9-9 5.9z"
        fill="#4A4A48"
      />
      <rect
        x={121.1}
        y={43.2}
        width={12.2}
        height={26.7}
        rx={1}
        transform="rotate(167.8 121 43.2)"
        fill="#329C7F"
      />
    </Svg>
  )
}

const SvgOrigination = styled(BaseComponent)`
  outline: none;
`
export default SvgOrigination
