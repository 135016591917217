import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.382 1.296A.5.5 0 013.84.997h7.66a.5.5 0 01.5.5V5.5a.5.5 0 01-.5.5H8.635l2.894 3.162a.5.5 0 01-.369.838H8v3.5a.5.5 0 01-.854.354l-4-4A.5.5 0 013 9.5v-4a.5.5 0 01.5-.5h2.865L3.471 1.835a.5.5 0 01-.089-.54zM7.72 5L4.975 1.997H11V5H7.72zm-.44 1H4v3h6.025L7.28 6zm-2.573 4L7 12.293V10H4.707z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFramerLogo = styled(BaseComponent)`
  outline: none;
`
export default SvgFramerLogo
