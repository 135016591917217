import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.625 2.5a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zm0 5a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zM7.5 13.625a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDotsVertical = styled(BaseComponent)`
  outline: none;
`
export default SvgDotsVertical
