import styled from 'styled-components'

import { Text, TextProps } from '../Typography'

export type TextClampProps = {
  linesShown: number
  children?: React.ReactNode
} & TextProps

const TextClamp = styled(Text)<TextClampProps>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.linesShown};
  overflow: hidden;
  padding-top: 0.1em;
`

export default TextClamp
