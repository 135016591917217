import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 1a.5.5 0 00-.5.5V6H3a1 1 0 00-1 1v1a1 1 0 001 1h11v4.5a.5.5 0 101 0v-12a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAlignRight = styled(BaseComponent)`
  outline: none;
`
export default SvgAlignRight
