import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 0a.5.5 0 00-.5.5v14a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V5h9.5a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5H.5zM1 4.075V1h3.075v3.075H1zm0 .85V14h3v-1.075H2.75a.425.425 0 110-.85H4v-1.15H2.25a.425.425 0 010-.85H4v-1.15H2.75a.425.425 0 110-.85H4v-1.15H2.75a.425.425 0 110-.85H4v-1.15H1zM4.925 4h1.15V2.75a.425.425 0 01.85 0V4h1.15V2.75a.425.425 0 01.85 0V4h1.15V2.25a.425.425 0 11.85 0V4h1.15V2.75a.425.425 0 01.85 0V4H14V1H4.925v3z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgRulerSquare = styled(BaseComponent)`
  outline: none;
`
export default SvgRulerSquare
