import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 1a.5.5 0 00-.5.5v12a.5.5 0 001 0V9h11a1 1 0 001-1V7a1 1 0 00-1-1H1V1.5A.5.5 0 00.5 1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAlignLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgAlignLeft
