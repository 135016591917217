import * as React from 'react'

import css from '@styled-system/css'
import styled from 'styled-components'
import { variant } from 'styled-system'

import { Box, BoxProps } from '../../atoms/Box'
import { useBreakpoints } from '../../hooks'
import { assert } from '../../utils/asserts'
import { breakIdentifiers } from '../../utils/createMediaQueries'
import { safeParseInt } from '../../utils/misc'
import { Size } from '../../utils/types'

const MAX_FORM_WIDTH = 670

type CornerCutSize = 'large' | 'small'

const variants = {
  default: {
    boxShadow: 0,
  },
  elevated: {
    boxShadow: 2,
  },
  'l-shape': {
    boxShadow: 0,
  },
  'l-shape-small': {
    boxShadow: 0,
  },
  outline: {
    boxShadow: 0,
  },
  'outline-small': {
    boxShadow: 0,
  },
}

const cornerCutSizePerVariant = {
  default: undefined,
  elevated: undefined,
  'l-shape': 'large',
  'l-shape-small': 'small',
  outline: 'large',
  'outline-small': 'small',
} as const

type Variant = keyof typeof variants

export type CardProps = BoxProps & {
  variant: Variant
  outlineThickness?: number
  outlineColor?: string
  $form?: boolean
  onClick?: () => void
}

const cornerCutSizes: Record<
  'large' | 'small',
  Record<breakIdentifiers, Size>
> = {
  large: {
    sm: {
      width: 36,
      height: 30,
    },
    md: {
      width: 48,
      height: 40,
    },
    lg: {
      width: 48,
      height: 40,
    },
    xl: {
      width: 48,
      height: 40,
    },
  },
  small: {
    sm: {
      width: 25,
      height: 20,
    },
    md: {
      width: 25,
      height: 20,
    },
    lg: {
      width: 25,
      height: 20,
    },
    xl: {
      width: 25,
      height: 20,
    },
  },
}

const cardVariants = variant({
  variants,
})

const getClipPath = (
  cornerCutSize: CornerCutSize,
  breakpoint: breakIdentifiers
) => {
  const { width, height } = cornerCutSizes[cornerCutSize][breakpoint]
  return `polygon(
        0 0,
        calc(100% - ${width}px) 0,
        calc(100% - ${width}px) ${height}px,
        100% ${height}px,
        100% 100%,
        0 100%
      )`
}

const CardWrapper = styled(Box)<{
  variant: Variant
  cornerCutSize: CornerCutSize | undefined
  breakpoint: breakIdentifiers
  $form: boolean
}>(({ cornerCutSize, breakpoint, $form }) => {
  let clipPath: string = ''

  if (cornerCutSize) {
    clipPath = getClipPath(cornerCutSize, breakpoint)
  }

  return css({
    position: 'relative',
    clipPath,
    ...($form && {
      maxWidth: MAX_FORM_WIDTH,
      mx: 'auto',
    }),
  })
}, cardVariants)

//  x0 x1            x2  x3  x4 x5
// (1)-------------------(2)       y0
//  |                     |
//  |  (12)----------(11) |        y1
//  |   |             |  (3)---(4) y2
//  |   |             |         |
//  |   |           (10)----(9) |  y3
//  |   |                    |  |
//  |  (7)------------------(8) |  y4
//  |   |                       |
// (14)(6/13)------------------(5) y5

// idea to use indices is based on the basic approach of 3D rendering
// https://docs.tizen.org/application/native/guides/graphics/polygon-mesh/
// (search for Figure: Vertex array and index array)
// The concept is slightly changed so instead of points we are indexing coordinates
const indices = [
  [0, 0],
  [3, 0],
  [3, 2],
  [5, 2],
  [5, 5],
  [1, 5],
  [1, 4],
  [4, 4],
  [4, 3],
  [2, 3],
  [2, 1],
  [1, 1],
  [1, 5],
  [0, 5],
  [0, 0],
]

// TODO: add memoize to utils and use it to cache the result
const StyledBorder = styled(Box)<{
  cornerCutSize: CornerCutSize
  thickness: number
  breakpoint: breakIdentifiers
}>(({ cornerCutSize, thickness, breakpoint }) => {
  const getClipPathBorder = (
    cornerCutSize: CornerCutSize,
    breakpoint: breakIdentifiers
  ) => {
    const { width, height } = cornerCutSizes[cornerCutSize][breakpoint]
    let dimensions = ''
    const widthInt = safeParseInt(width)
    const heightInt = safeParseInt(height)
    const pointX = [
      '1px',
      `${1 + thickness}px`,
      `calc(100% - ${widthInt + 1 + thickness}px)`,
      `calc(100% - ${widthInt + 1}px)`,
      `calc(100% - ${1 + thickness}px)`,
      'calc(100% - 1px)',
    ]
    const pointY = [
      '1px',
      `${1 + thickness}px`,
      `calc(${heightInt + 1}px)`,
      `calc(${heightInt + 1 + thickness}px)`,
      `calc(100% - ${1 + thickness}px)`,
      'calc(100% - 1px)',
    ]
    dimensions += 'polygon('
    let separator = ''
    for (let i = 0; i < indices.length; i++) {
      dimensions += `${separator} ${pointX[indices[i][0]]} ${
        pointY[indices[i][1]]
      }`
      separator = ', '
    }
    dimensions += ')'
    return dimensions
  }

  const clipPath = getClipPathBorder(cornerCutSize, breakpoint)

  return css({
    clipPath,
  })
})

const Card = ({
  variant,
  children,
  outlineThickness,
  outlineColor,
  $form = false,
  onClick,
  ...props
}: CardProps) => {
  const breakpoint = useBreakpoints()
  if (process.env.NODE_ENV !== 'production') {
    assert(
      (outlineThickness !== undefined || outlineColor !== undefined) &&
        variant !== 'outline' &&
        variant !== 'outline-small',
      `outlineThickness and/or outlineColor can be used only with 'outline' variant`
    )
  }
  const cornerCutSize = cornerCutSizePerVariant[variant]
  return (
    <CardWrapper
      variant={variant}
      cornerCutSize={cornerCutSize}
      breakpoint={breakpoint.breakpointIdentifier}
      $form={$form}
      onClick={onClick}
      {...props}
    >
      {(variant === 'outline' || variant === 'outline-small') && (
        <Box
          position="absolute"
          left={0}
          right={0}
          top={0}
          bottom={0}
          display="grid"
        >
          <StyledBorder
            cornerCutSize={cornerCutSize!}
            breakpoint={breakpoint.breakpointIdentifier}
            thickness={outlineThickness ?? 2}
            bg={outlineColor ?? 'secondary'}
          />
        </Box>
      )}
      {children}
    </CardWrapper>
  )
}

Card.defaultProps = {
  variant: 'default',
}

export default Card
