import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={100}
      height={100}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61 7.8c.2 0 .4 0 .6.2l24.1 23.8c.2.1.2.3.2.6v59c0 .4-.3.8-.7.8H14a.8.8 0 01-.8-.8V8.6c0-.4.3-.8.8-.8h47zM79 32L61 14v17.3c0 .4.3.7.7.7H79z"
        fill="#4A4A48"
      />
      <rect x={28.1} y={57} width={43.8} height={4.7} rx={0.8} fill="#329C7F" />
      <rect
        x={28.1}
        y={46.9}
        width={43.8}
        height={4.7}
        rx={0.8}
        fill="#F3AD3D"
      />
      <rect
        x={28.1}
        y={36.7}
        width={21.9}
        height={4.7}
        rx={0.8}
        fill="#F3AD3D"
      />
      <rect
        x={28.1}
        y={67.2}
        width={43.8}
        height={5.5}
        rx={0.8}
        fill="#329C7F"
      />
    </Svg>
  )
}

const SvgFileColor = styled(BaseComponent)`
  outline: none;
`
export default SvgFileColor
