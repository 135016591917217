import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={80}
      height={80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72.969 18.75H63.43a11.657 11.657 0 002.35-7.031C65.781 5.257 60.524 0 54.062 0 50.17 0 47.3 1.395 45.03 4.389 43.13 6.894 41.742 10.39 40 14.81c-1.742-4.42-3.13-7.916-5.03-10.421C32.7 1.395 29.83 0 25.938 0c-6.462 0-11.72 5.257-11.72 11.719 0 2.636.876 5.071 2.35 7.031H7.032A7.04 7.04 0 000 25.781v4.688c0 3.055 1.96 5.66 4.688 6.628v35.872A7.04 7.04 0 0011.718 80h56.563a7.04 7.04 0 007.031-7.031V37.097C78.04 36.129 80 33.524 80 30.469V25.78a7.04 7.04 0 00-7.031-7.031zm-28.667-2.072c3.59-9.109 4.956-11.99 9.76-11.99a7.04 7.04 0 017.032 7.03 7.04 7.04 0 01-7.032 7.032h-10.58c.288-.721.561-1.415.82-2.072zM25.938 4.688c4.804 0 6.17 2.881 9.76 11.99.259.657.532 1.35.82 2.072h-10.58a7.04 7.04 0 01-7.032-7.031 7.04 7.04 0 017.032-7.031zm4.687 70.624H11.719a2.346 2.346 0 01-2.344-2.343V37.5h21.25v37.813zm0-42.5H7.031a2.346 2.346 0 01-2.343-2.343V25.78a2.346 2.346 0 012.343-2.343h23.594v9.375zm14.063 42.5h-9.376V23.438h9.376v51.874zm25.937-2.343a2.346 2.346 0 01-2.344 2.344H49.375V37.5h21.25v35.469zm4.688-42.5a2.346 2.346 0 01-2.344 2.343H49.375v-9.374h23.594a2.346 2.346 0 012.344 2.343v4.688z"
        fill="#36354A"
      />
    </Svg>
  )
}

const SvgReferralIconGift = styled(BaseComponent)`
  outline: none;
`
export default SvgReferralIconGift
