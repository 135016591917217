import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.818.932a.45.45 0 00-.636 0l-1.75 1.75a.45.45 0 10.636.636L7 2.386V5.5a.5.5 0 001 0V2.386l.932.932a.45.45 0 00.636-.636L7.818.932zM8 9.5a.5.5 0 00-1 0v3.114l-.932-.932a.45.45 0 00-.636.636l1.75 1.75a.45.45 0 00.636 0l1.75-1.75a.45.45 0 00-.636-.636L8 12.614V9.5zm1-2a.5.5 0 01.5-.5h3.114l-.932-.932a.45.45 0 01.636-.636l1.75 1.75a.45.45 0 010 .636l-1.75 1.75a.45.45 0 01-.636-.636L12.614 8H9.5a.5.5 0 01-.5-.5zM3.318 6.068L2.386 7H5.5a.5.5 0 010 1H2.386l.932.932a.45.45 0 01-.636.636l-1.75-1.75a.45.45 0 010-.636l1.75-1.75a.45.45 0 11.636.636z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgMove = styled(BaseComponent)`
  outline: none;
`
export default SvgMove
