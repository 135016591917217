import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67 65"
      {...props}
    >
      <path
        fill="#414141"
        d="M6.75 17.75H57V53H6.75zM35.77 53h-7.58l-10.94 6H46.5z"
      />
      <path
        d="M30.75 53h-2.53l-10.97 6h6.94s3.44-1.1 5.02-2.63A5.12 5.12 0 0030.75 53zm-24-2.25c24 0 33.32-11.27 36.75-33H6.75v33z"
        fill="gray"
      />
      <path
        d="M22.5 23.75L30 30v8.75h-5.25V32h-4.5v6.75H15V30z"
        fill="#edf2f1"
        fillRule="evenodd"
      />
      <circle cx={11.57} cy={51.58} r={9.57} fill="#f8f7f5" />
      <circle cx={11.57} cy={51.58} r={9.57} fill="#329c7f" />
      <path d="M11.57 42A9.57 9.57 0 004.8 58.34l6.77-6.77z" fill="#5bb099" />
      <path
        d="M7.1 51.05l3.39 3.07 4.92-4.46"
        fill="none"
        stroke="#edf2f1"
        strokeWidth={2.25}
      />
      <path
        d="M45.95 32.41A11.46 11.46 0 0134.5 20.96h-9A20.45 20.45 0 0045.95 41.4zm11.46-11.45A11.46 11.46 0 0145.96 32.4v9A20.45 20.45 0 0066.4 20.96zM45.96 9.5A11.46 11.46 0 0157.4 20.95h9A20.45 20.45 0 0045.96.5zm0-9A20.45 20.45 0 0025.5 20.95h9A11.46 11.46 0 0145.95 9.5z"
        fill="#f8f7f5"
      />
      <path
        d="M30 20.95a15.96 15.96 0 1115.95 15.96A15.96 15.96 0 0130 20.96z"
        fill="#5bb099"
      />
      <path
        d="M43.38 26.43a2.42 2.42 0 002.54 1.7 2.43 2.43 0 002.57-1.67 6.9 6.9 0 00.16-2 5.98 5.98 0 00-.12-1.81 1.85 1.85 0 00-.89-1.05v-.03a1.85 1.85 0 00.89-1.05 4.9 4.9 0 00.12-1.54 5.89 5.89 0 00-.16-1.73 2.41 2.41 0 00-2.57-1.68 2.42 2.42 0 00-2.54 1.71 4.6 4.6 0 00-.16 1.4.19.19 0 00.18.22l1.38.13c.13.02.22-.04.22-.18a3.38 3.38 0 01.07-1 .9.9 0 011.67-.03 4.19 4.19 0 01.08 1.16 4.46 4.46 0 01-.08 1.31c-.12.4-.43.52-.9.52h-.27a.17.17 0 00-.18.18v1.3a.17.17 0 00.18.18h.27c.47 0 .78.14.9.54a5.06 5.06 0 01.08 1.46 5.53 5.53 0 01-.08 1.42.9.9 0 01-1.67-.02 3.38 3.38 0 01-.07-1c0-.15-.1-.2-.22-.19l-1.38.13a.19.19 0 00-.18.21 4.6 4.6 0 00.16 1.4z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgScreenThree = styled(BaseComponent)`
  outline: none;
`
export default SvgScreenThree
