import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.309 1a1 1 0 00-.894.553L1.053 4.276A.5.5 0 001 4.5V13a1 1 0 001 1h11a1 1 0 001-1V4.5a.5.5 0 00-.053-.224l-1.362-2.723A1 1 0 0011.691 1H3.309zm0 1H7v2H2.309l1-2zM8 4V2h3.691l1 2H8zm-.5 1H13v8H2V5h5.5zm-2 2a.5.5 0 000 1h4a.5.5 0 000-1h-4z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgArchive = styled(BaseComponent)`
  outline: none;
`
export default SvgArchive
