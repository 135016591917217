import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Wireless_White_svg__clip0)" fill="#fff">
        <path d="M70.26 83.944a10.132 10.132 0 00-7.029-2.841 10.132 10.132 0 00-7.028 2.841 10.074 10.074 0 00-2.72 5.142 10.136 10.136 0 00.566 5.802 10.021 10.021 0 003.661 4.507 9.87 9.87 0 005.523 1.692 9.87 9.87 0 005.523-1.692 10.021 10.021 0 003.662-4.507c.752-1.835.949-3.854.565-5.802a10.074 10.074 0 00-2.72-5.142h-.003zM43.7 73.454a.974.974 0 00-.012 1.399l3.841 3.86c.391.394 1.026.392 1.434.017a22.27 22.27 0 016.506-4.16 22.186 22.186 0 0117.058 0 22.27 22.27 0 016.506 4.16 1.036 1.036 0 001.434-.017l3.841-3.86a.975.975 0 00-.012-1.4 29.699 29.699 0 00-8.926-5.78 29.584 29.584 0 00-22.744 0 29.699 29.699 0 00-8.926 5.78z" />
        <path d="M30.56 60.251a.984.984 0 00-.008 1.405l3.842 3.86a1.02 1.02 0 001.425.01 40.797 40.797 0 0128.172-11.303 40.797 40.797 0 0128.172 11.303 1.02 1.02 0 001.426-.01l3.842-3.86a.984.984 0 00-.008-1.405A48.203 48.203 0 0063.99 46.756a48.203 48.203 0 00-33.43 13.495z" />
        <path d="M17.433 47.048a.989.989 0 00-.007 1.407l3.842 3.86c.39.393 1.025.393 1.423.007a59.343 59.343 0 0141.308-16.76 59.342 59.342 0 0141.308 16.76 1.015 1.015 0 001.424-.006l3.841-3.861a.99.99 0 00-.006-1.407c-12.476-12.145-29.173-18.95-46.567-18.95-17.394 0-34.09 6.805-46.566 18.95z" />
      </g>
      <defs>
        <clipPath id="Wireless_White_svg__clip0">
          <path
            fill="#fff"
            transform="translate(12 15)"
            d="M0 0h104v97.333H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

const SvgWirelessWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgWirelessWhite
