import React from 'react'

import styled, { keyframes, css } from 'styled-components'

import { Box, BoxProps } from '../../atoms/Box'
import { colors } from '../../theme'

const range = (count: number) =>
  Array(count)
    .fill(1)
    .map((_, index) => index + 1)

const shine = keyframes`
  0% { background-position: 100% 50%; }
  100% { background-position: -100% 50%; }
  `

const SKELETON_BACKGROUND = colors.earth[0]
const SKELETON_HIGHLIGHT = colors.earth[1]

const SKELETON_ANGLE = '90deg'

const getLinearBackground = (
  background: string,
  highlight: string
) => `linear-gradient(${SKELETON_ANGLE},
    ${background} 0%,
    ${background} 20%,
    ${highlight} 50%,
    ${background} 80%,
    ${background} 100%
  )`

export type Props = {
  circle?: boolean
  count?: number
  duration?: number
  backgroundColor?: string
  highlightColor?: string
} & Omit<BoxProps, 'children' | 'backgroundColor'>

const SkeletonElement = styled(Box)<{
  $duration: Props['duration']
  $circle: Props['circle']
  $single?: boolean
  $backgroundColor: string
  $highlightColor: string
}>`
  background: ${({ $backgroundColor, $highlightColor }) =>
    getLinearBackground($backgroundColor!, $highlightColor!)};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  background-size: 200% 200%;
  background-repeat: no-repeat;
  border-radius: ${({ $circle }) => ($circle ? '50%' : 'inherit')};
  display: ${({ $single = false }) => ($single ? 'block' : 'inline-block')};
  line-height: 1;
  ${({ $duration }) =>
    css`
      animation: ${shine} ${$duration}ms ease-in-out infinite;
    `};
`

const Skeleton = ({
  circle = false,
  count = 1,
  duration = 1200,
  width = '100%',
  height,
  backgroundColor = SKELETON_BACKGROUND,
  highlightColor = SKELETON_HIGHLIGHT,
  ...props
}: Props) => {
  if (count === 1) {
    return (
      <SkeletonElement
        as="span"
        $single
        $duration={duration}
        $circle={circle}
        $backgroundColor={backgroundColor}
        $highlightColor={highlightColor}
        height={height}
        width={width}
        {...props}
      />
    )
  }

  const numbers = range(count)

  return (
    <>
      {numbers.map((_, index) => (
        <SkeletonElement
          as="span"
          key={index}
          $duration={duration}
          $circle={circle}
          $backgroundColor={backgroundColor}
          $highlightColor={highlightColor}
          width={width}
          height={height}
          {...props}
        >
          &zwnj;
        </SkeletonElement>
      ))}
    </>
  )
}

export default Skeleton
