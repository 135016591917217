import React, {
  PropsWithChildren,
  createContext,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'

import { Cross1 } from '@linus-capital/icons'
import { DialogOverlay, DialogContent, DialogProps } from '@reach/dialog'
import css from '@styled-system/css'
import { Transition, TransitionStatus } from 'react-transition-group'
import styled from 'styled-components'

import { Box, BoxProps } from '../../atoms/Box'
import { IconButton } from '../../atoms/Button'
import { Flex } from '../../atoms/Flex'
import { ProgressBar } from '../ProgressBar'

const MODAL_ANIMATION_DURATION = 250

type ScrollBehavior = 'inside' | 'outside'

export type Props = {
  isOpen: boolean
  ariaLabel?: string
  ariaLabelledBy?: string
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  hasClose?: boolean
  /**
   *  If `true`, the modal will be centered on screen.
   * @default true
   */
  isCentered?: boolean
  /**
   * Where scroll behavior should originate.
   * - If set to `inside`, scroll only occurs within the `Body`.
   * - If set to `outside`, the entire `ModalContent` will scroll within the viewport.
   *
   * @default "outside"
   */
  scrollBehavior?: ScrollBehavior
} & BoxProps &
  DialogProps

const overlayTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
}

const contentTransitionStyles = {
  entering: { transform: 'scale(0.5)' },
  entered: { transform: 'scale(1)' },
  exiting: { transform: 'scale(0.5)' },
  exited: { transform: 'scale(0.5)' },
  unmounted: { transform: 'scale(0.5)' },
}

type BaseProps = {
  $transitionState: TransitionStatus
  $scrollBehavior: ScrollBehavior
  $isCentered?: boolean
}

export const ModalContext = createContext<{
  scrollBehavior: ScrollBehavior
}>({
  scrollBehavior: 'outside',
})

export const useModalContext = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error(
      'useModalContext: `context` is undefined. Seems you forgot to wrap modal components in `<Modal />`'
    )
  }

  return context
}

type ModalChildrenProps = BoxProps &
  PropsWithChildren<{
    progress?: number
  }>

const Body = ({ children, ...props }: ModalChildrenProps) => {
  const { scrollBehavior } = useModalContext()

  return (
    <Box
      flex={1}
      px={3}
      py={6}
      {...props}
      overflow={scrollBehavior === 'inside' ? 'auto' : undefined}
    >
      {children}
    </Box>
  )
}

const Footer = ({ children, ...props }: ModalChildrenProps) => (
  <Flex px={6} py={4} alignItems="center" {...props}>
    {children}
  </Flex>
)

const Header = ({ children, progress, ...props }: ModalChildrenProps) => (
  <>
    <Box
      borderBottom="thin"
      borderBottomColor="sand.0"
      px={3}
      py={6}
      width={1}
      {...props}
    >
      {children}
    </Box>
    {progress ? (
      <ProgressBar progress={progress} height={4} backgroundColor="sand.0" />
    ) : null}
  </>
)

const Overlay = styled(DialogOverlay)<BaseProps>(
  ({ $transitionState, $scrollBehavior, $isCentered }) =>
    css({
      overflow: $scrollBehavior === 'inside' ? 'hidden' : 'auto',
      display: 'flex',
      justifyContent: ' center',
      alignItems: $isCentered ? ' center' : 'flex-start',
      transition: `opacity ${MODAL_ANIMATION_DURATION}ms ease-out`,
      zIndex: 100,
      width: '100vw',
      height: '100vh',
      '@supports(height: -webkit-fill-available)': {
        height: '-webkit-fill-available',
      },
      ...overlayTransitionStyles[$transitionState],
    })
)

const Content = styled(DialogContent)<BaseProps>(({ $transitionState }) =>
  css({
    position: 'relative',
    borderRadius: 2,
    '&:focus': {
      outline: 'none',
    },
    transition: `transform ${MODAL_ANIMATION_DURATION}ms ease-out`,
    outline: 0,
    '&[data-reach-dialog-content]': {
      width: '100%',
      padding: 0,
    },
    ...contentTransitionStyles[$transitionState],
  })
)

const Modal = ({
  isOpen,
  onDismiss,
  children,
  ariaLabel = 'Modal',
  ariaLabelledBy,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  width = ['100%', '496px', '680px', '744px', '840px'],
  padding = 2,
  hasClose = true,
  scrollBehavior = 'outside',
  allowPinchZoom,
  initialFocusRef,
  isCentered = true,
  ...props
}: Props) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (shouldCloseOnEsc && (event as KeyboardEvent).key === 'Escape') {
        onDismiss?.()
      }
    },
    [onDismiss, shouldCloseOnEsc]
  )

  const handleOnClick = useCallback(() => {
    if (shouldCloseOnOverlayClick) {
      onDismiss?.()
    }
  }, [shouldCloseOnOverlayClick, onDismiss])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }
    return () => {
      if (isOpen) {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [handleKeyDown, isOpen])

  const childrenArray = useMemo(() => React.Children.toArray(children), [
    children,
  ])

  return (
    <Transition in={isOpen} unmountOnExit timeout={MODAL_ANIMATION_DURATION}>
      {(state: TransitionStatus) => (
        <Overlay
          $transitionState={state}
          isOpen={isOpen}
          onClick={handleOnClick}
          $scrollBehavior={scrollBehavior}
          allowPinchZoom={allowPinchZoom}
          initialFocusRef={initialFocusRef}
          $isCentered={isCentered}
        >
          <Content
            $transitionState={state}
            aria-label={ariaLabel}
            $scrollBehavior={scrollBehavior}
            aria-labelledby={ariaLabelledBy}
            as={Flex}
            flexDirection="column"
            backgroundColor="white"
            width={width}
            padding={padding}
            boxShadow={2}
            maxHeight={
              scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : undefined
            }
            my={5}
            {...props}
          >
            {childrenArray.map((element, index) => (
              <ModalContext.Provider value={{ scrollBehavior }} key={index}>
                {element}
              </ModalContext.Provider>
            ))}
            {hasClose && (
              <Box position="absolute" top={2} right={2} zIndex={1}>
                <IconButton icon={<Cross1 size={20} />} onClick={onDismiss} />
              </Box>
            )}
          </Content>
        </Overlay>
      )}
    </Transition>
  )
}

Modal.Header = Header
Modal.Footer = Footer
Modal.Body = Body

export default Modal
