import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.854 2.146a.5.5 0 010 .708L3.707 4H9a4.5 4.5 0 110 9H5a.5.5 0 010-1h4a3.5 3.5 0 100-7H3.707l1.147 1.146a.5.5 0 11-.708.708l-2-2a.5.5 0 010-.708l2-2a.5.5 0 01.708 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgReset = styled(BaseComponent)`
  outline: none;
`
export default SvgReset
