import React, { useMemo } from 'react'

import { Property } from 'csstype'
import styled from 'styled-components'
import {
  variant,
  SpaceProps,
  ResponsiveValue,
  LayoutProps,
} from 'styled-system'

import { Flex, Props as FlexProps } from '../Flex'
import { Spinner } from '../Spinner'
import { Text, TextProps } from '../Typography'

export type ButtonSize = 'small' | 'large'

const DISABLED_BUTTON_TRANSPARENCY = 0.7

const variants = {
  primary: {
    color: 'white',
    backgroundColor: 'success',
    '&:hover:not(:disabled)': {
      backgroundColor: 'green.0',
    },
    '&:disabled': {
      backgroundColor: 'green.0',
      color: 'green.2',
    },
  },
  secondary: {
    color: 'white',
    backgroundColor: 'secondary',
    '&:hover:not(:disabled)': {
      backgroundColor: 'black.0',
    },
    '&:disabled': {
      backgroundColor: 'black.1',
      color: 'black.2',
    },
  },
  ternary: {
    color: 'secondary',
    backgroundColor: 'earth.0',
    '&:hover:not(:disabled)': {
      backgroundColor: 'earth.1',
    },
    '&:disabled': {
      backgroundColor: 'black.2',
      color: 'earth.0',
    },
  },
  link: {
    backgroundColor: 'transparent',
    color: 'secondary',
    minWidth: 'auto',
    textDecoration: 'underline',
    '&:hover:not(:disabled)': {
      color: 'black.0',
    },
    '&:disabled': {
      color: 'black.1',
    },
  },
  functional: {
    backgroundColor: 'transparent',
    color: 'blue.0',
    minWidth: 'auto',
    textDecoration: 'underline',
    '&:hover:not(:disabled)': {
      color: 'functional',
    },
    '&:disabled': {
      color: 'black.1',
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: 'secondary',
    minWidth: 'auto',
    '&:hover:not(:disabled)': {
      color: 'black.0',
    },
    '&:disabled': {
      color: 'black.1',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    color: 'inherit',
    '&:hover:not(:disabled)': {
      color: 'black.0',
    },
    '&:disabled': {
      color: 'black.1',
    },
  },
  menu: {
    backgroundColor: 'earth.0',
    fontFamily: 'heading',
    fontSize: 4,
    color: 'secondary',
    textTransform: 'uppercase',
    textDecoration: 'none',
    lineHeight: 1,
    '&:hover:not(:disabled)': {
      backgroundColor: 'earth.1',
    },
    '&:disabled': {
      backgroundColor: 'earth.0',
      color: 'black.2',
    },
  },
}

export type Variants = keyof typeof variants

type ButtonInnerProps = {
  textVariant?:
    | TextProps['variant']
    | 'ButtonSmall'
    | 'ButtonMiddle'
    | 'ButtonLarge'
    | 'ButtonLandingPage'
  variant: Variants
  size?: ButtonSize
  textAlign?: 'left' | 'center' | 'right'
} & FlexProps

export type Props = {
  children: React.ReactNode
  icon?: React.ReactNode
  as?: React.ElementType
  iconRight?: React.ReactNode
  onClick?: (event: React.SyntheticEvent) => void
  onMouseDown?: (event: React.SyntheticEvent) => void
  onMouseUp?: (event: React.SyntheticEvent) => void
  disabled?: boolean
  pending?: boolean
  type?: 'button' | 'reset' | 'submit'
} & ButtonInnerProps &
  SpaceProps &
  LayoutProps

const buttonVariants = variant({
  variants,
})

export const buttonSize = variant({
  prop: 'size',
  variants: {
    small: {
      fontSize: 0,
    },
    large: {
      fontSize: 4,
    },
  },
})

const buttonTextAlign = variant({
  prop: 'textAlign',
  variants: {
    left: {
      textAlign: 'left',
    },
    center: {
      textAlign: 'center',
    },
    right: {
      textAlign: 'right',
    },
  },
})

export const ButtonWrapper = styled(Flex)<ButtonInnerProps>(
  {
    cursor: 'pointer',
    border: 0,
    outline: 0,
    alignContent: 'center',
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  buttonVariants,
  buttonSize,
  buttonTextAlign
)

export const calcButtonSpacing = (size?: ButtonSize) => {
  const smallPx = ['12px', '12px']
  const smallPy = ['9px', '14px']
  const largePx = ['22px', '22px']
  const largePy = ['19px', '24px']
  switch (size) {
    case 'small':
      return {
        px: smallPx,
        py: smallPy,
        iconM: [3, 22],
      }
    case 'large':
    default:
      return {
        px: largePx,
        py: largePy,
        iconM: [22, 32],
      }
  }
}

const Button = ({
  children,
  icon,
  iconRight,
  pending,
  onClick,
  onMouseDown,
  onMouseUp,
  size,
  minWidth,
  textAlign,
  variant,
  textVariant = 'ButtonMiddle',
  disabled,
  as = 'button',
  ...props
}: Props) => {
  const spacing: {
    px: ResponsiveValue<Property.Padding<number | string>>
    py: ResponsiveValue<Property.Padding<number | string>>
    iconM: ResponsiveValue<Property.Margin<number | string>>
  } = useMemo(() => calcButtonSpacing(size), [size])
  return (
    <ButtonWrapper
      as={as}
      alignItems="center"
      justifyContent={textAlign}
      textAlign={textAlign ?? 'center'}
      fontFamily="inherit"
      fontSize="inherit"
      letterSpacing="body"
      fontWeight="normal"
      minWidth={minWidth ?? 0}
      px={spacing.px}
      py={spacing.py}
      size={size}
      variant={variant}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      disabled={disabled}
      {...props}
    >
      {pending ? (
        <Spinner mx={2} my={-1} />
      ) : (
        <>
          {icon && (
            <Flex
              my={-2}
              css={{
                opacity: disabled ? DISABLED_BUTTON_TRANSPARENCY : 1,
                pointerEvents: 'none',
              }}
            >
              {icon}
            </Flex>
          )}
          <Text
            css={{ lineHeight: 1, pointerEvents: 'none' }}
            variant={textVariant}
            ml={icon ? spacing.iconM : undefined}
            mr={iconRight ? spacing.iconM : undefined}
            width="100%"
          >
            {children}
          </Text>
          {iconRight && (
            <Flex
              my={-2}
              css={{
                opacity: disabled ? DISABLED_BUTTON_TRANSPARENCY : 1,
                pointerEvents: 'none',
              }}
            >
              {iconRight}
            </Flex>
          )}
        </>
      )}
    </ButtonWrapper>
  )
}

Button.defaultProps = {
  variant: 'primary',
  size: 'medium',
  textAlign: 'center',
}

export default Button
