import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.354 11.354a.5.5 0 000-.707L4.707 4H9a.5.5 0 000-1H3.5a.5.5 0 00-.5.5V9a.5.5 0 001 0V4.707l6.646 6.647a.5.5 0 00.708 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgArrowTopLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowTopLeft
