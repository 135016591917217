import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={22}
      height={13}
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#2C2C2C"
        d="M0 0h22v1.941H0zM0 5.177h22v1.941H0zM0 10.353h22v1.941H0z"
      />
    </Svg>
  )
}

const SvgBars = styled(BaseComponent)`
  outline: none;
`
export default SvgBars
