import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 11V4H1v7h13zm1-7v7a1 1 0 01-1 1H1a1 1 0 01-1-1V4a1 1 0 011-1h13a1 1 0 011 1zM2 5.25A.25.25 0 012.25 5h3.5a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-3.5A.25.25 0 012 9.75v-4.5zM7.5 7a.5.5 0 000 1h3a.5.5 0 000-1h-3zM7 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zM7.5 5a.5.5 0 000 1h4a.5.5 0 000-1h-4z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgIdCardBlack = styled(BaseComponent)`
  outline: none;
`
export default SvgIdCardBlack
