import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 13.95a.45.45 0 000-.9h-12a.45.45 0 100 .9h12zm-2.432-6.382a.45.45 0 10-.636-.636L7.95 9.414V1.5a.45.45 0 00-.9 0v7.914L4.568 6.932a.45.45 0 10-.636.636l3.25 3.25a.45.45 0 00.636 0l3.25-3.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPinBottom = styled(BaseComponent)`
  outline: none;
`
export default SvgPinBottom
