import React, { useCallback, useState, forwardRef, useEffect } from 'react'

import { Check } from '@linus-capital/icons'
import { css } from '@styled-system/css'
import { Property } from 'csstype'
import styled from 'styled-components'

import { Box } from '../../atoms/Box'
import { MergeElementProps } from '../../utils/types'
import { Props as FormItemProps } from '../Forms/FormItem'
import { Label } from './Label'

type VerticalLabelAlign = 'top' | 'center' | 'bottom'

export type Props = MergeElementProps<
  'input',
  Omit<FormItemProps, 'children' | 'helperText'> & {
    verticalLabelAlign?: VerticalLabelAlign
    iconSize?: number
  }
>

const verticalAlignToFlex: Record<VerticalLabelAlign, Property.AlignItems> = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
} as const

export const StyledInput = styled(Box)`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledLabel = styled(Label)<{
  disabled?: boolean
  verticalLabelAlign: VerticalLabelAlign
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  align-items: ${({ verticalLabelAlign }) =>
    verticalAlignToFlex[verticalLabelAlign]};
`

const StyledCheckbox = styled(Box)<{
  hasError?: boolean
  checked?: boolean
  disabled?: boolean
  iconSize: number
}>(({ checked, hasError, disabled, iconSize }) =>
  css({
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'thin',
    borderColor: hasError ? 'red' : disabled ? 'black.1' : 'secondary',
    mr: 4,
    my: 1,
    p: `${Math.sqrt(iconSize / 22) * 5}px`,
    color: 'white',
    transition: 'all 150ms',
    svg: {
      visibility: checked ? 'visible' : 'hidden',
    },
    ...(checked && { backgroundColor: disabled ? 'green.1' : 'success' }),
  })
)

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      id,
      error,
      labelProps,
      children,
      checked,
      defaultChecked,
      disabled,
      onChange,
      verticalLabelAlign = 'center',
      iconSize = 22,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(
      checked ?? !!defaultChecked
    )

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event?.target.checked)
        onChange?.(event)
      },
      [onChange]
    )

    useEffect(() => {
      setInternalValue(checked ?? internalValue)
    }, [checked, internalValue])

    return (
      <StyledLabel
        htmlFor={id}
        {...labelProps}
        disabled={disabled}
        verticalLabelAlign={verticalLabelAlign}
      >
        <StyledInput
          as="input"
          type="checkbox"
          id={id}
          name={name}
          checked={internalValue}
          onChange={handleOnChange}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        <StyledCheckbox
          hasError={!!error}
          checked={internalValue}
          disabled={disabled}
          iconSize={iconSize}
        >
          <Check size={iconSize} />
        </StyledCheckbox>
        {children}
      </StyledLabel>
    )
  }
)

export default Checkbox
