import React, { useState, forwardRef, useCallback, useEffect } from 'react'

import { CaretDown, CheckCircledDark } from '@linus-capital/icons'
import {
  ListboxButton,
  ListboxInput,
  ListboxOption,
  ListboxPopover,
  ListboxList,
} from '@reach/listbox'
import css from '@styled-system/css'
import styled from 'styled-components'

import { Box } from '../../../atoms/Box'
import { Flex } from '../../../atoms/Flex'
import { TextClamp } from '../../../atoms/TextClamp'
import { space, colors } from '../../../theme'
import { INPUT_HEIGHT } from '../../../utils/constants'
import { getPosition } from '../../../utils/popoverPosition'

type SelectOption = {
  value: string
  title?: string
  label: string | React.ReactNode
  icon?: React.ReactNode
}

const getBoxShadow = ({ width, color }: { width: number; color: string }) =>
  `0 0 0 ${width}px ${color}`

const getBorderColor = (hasError?: boolean, hasValue?: boolean) => {
  if (hasError) {
    return colors.red
  }

  if (hasValue) {
    return colors.secondary
  }

  return colors.black[2]
}

const ArrowDownWrapper = styled(Flex)<{
  expanded?: boolean
  disabled?: boolean
}>`
  svg {
    transition: transform 0.3s ease-in-out 0s;
    fill: ${({ disabled, theme }) => disabled && theme.colors.black[1]};
  }
  ${({ expanded }) =>
    expanded &&
    `
    svg {
      transform: rotate(180deg);
    }
  `}
`

export type Props = {
  id?: string
  name: string
  value?: string
  error?: string
  defaultValue?: string
  options: SelectOption[]
  disabled?: boolean
  renderAsIcon?: boolean
  onChange?: (value: string) => void
  placeholder?: string
  withBorder?: boolean
  withCheckMark?: boolean
  className?: string
  popoverWidth?: string
  bg?: string
}

const StyledListboxButton = styled(ListboxButton)<{
  $hasError?: boolean
  $hasValue?: boolean
  $withBorder?: boolean
  $bg?: string
}>(({ $hasError, $hasValue, $withBorder, $bg }) =>
  css({
    '&[data-reach-listbox-button]': {
      outline: 'none',
      minWidth: '100%',
      width: '100%',
      height: INPUT_HEIGHT,
      padding: 3,
      bg: $bg ?? 'transparent',
      borderColor: 'transparent',
      boxShadow: getBoxShadow({
        width: 1,
        color: getBorderColor($hasError, $hasValue),
      }),
      color: $hasValue ? 'secondary' : 'black.1',
      alignItems: 'center',
    },
    '&[aria-expanded="true"]': {
      boxShadow: getBoxShadow({
        width: $withBorder ? 2 : 1,
        color: colors.black[0],
      }),
    },
    '&[aria-disabled="true"]': {
      cursor: 'not-allowed',
      backgroundColor: 'earth.0',
      boxShadow: getBoxShadow({
        width: 1,
        color: colors.black[2],
      }),
    },
  })
)

const StyledListBoxOption = styled(ListboxOption)<{
  display?: string
}>(({ display }) =>
  css({
    '&[data-reach-listbox-option]': {
      cursor: 'pointer',
      p: 0,
      display: display ?? 'flex',
      whiteSpace: 'normal',
    },
    '&[data-current-nav]': {
      backgroundColor: 'black.3',
      color: 'secondary',
    },
    '&[data-current-selected]': {
      fontWeight: 'bold',
    },
  })
)

const StyledListboxPopover = styled(ListboxPopover)<{
  $withBorder?: boolean
  width?: string
}>(({ $withBorder, width }) =>
  css({
    paddingY: 0,
    maxHeight: '30vh',
    overflow: 'auto',
    '&[data-reach-listbox-popover]': {
      width,
      outline: 'none',
      borderColor: 'transparent',
      boxShadow: getBoxShadow({
        width: $withBorder ? 2 : 1,
        color: $withBorder ? colors.black[0] : colors.black[2],
      }),
    },
  })
)

type SelectItemProps = SelectOption & {
  isSelected?: boolean
  withCheckMark?: boolean
  name: string
}

const SelectItem = ({
  value,
  label,
  icon,
  isSelected,
  withCheckMark,
  name,
}: SelectItemProps) => (
  <StyledListBoxOption
    value={value}
    data-test-select-item-value={`${name}-${value}`}
  >
    <Box width={space[withCheckMark ? 6 : 3]} />
    <Flex
      justifyContent="space-between"
      borderBottom="thin"
      borderBottomColor="earth.0"
      flex={1}
      py={2}
      pr={2}
    >
      <Flex alignItems="center">
        {icon && <Flex mr={2}>{icon}</Flex>}
        {label}
      </Flex>
      {isSelected && withCheckMark && (
        <CheckCircledDark my="auto" size={20} color="black.0" />
      )}
    </Flex>
  </StyledListBoxOption>
)

const ListboxButtonLabel = ({
  icon,
  label,
  title,
}: {
  icon?: React.ReactNode
  label?: string
  title?: string
}) => <TextClamp linesShown={1}>{icon ?? title ?? label}</TextClamp>

export const BasicSelect = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      id,
      error,
      value,
      defaultValue,
      options,
      onChange,
      disabled,
      renderAsIcon = false,
      placeholder,
      withBorder = true,
      withCheckMark = true,
      className,
      popoverWidth,
      bg,
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(value ?? defaultValue)
    const popoverRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (value) {
        setInternalValue(value)
      }
    }, [value])

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!event.isDefaultPrevented()) {
        const container = popoverRef.current
        if (!container) {
          return
        }

        window.requestAnimationFrame(() => {
          const element = container.querySelector(
            '[aria-selected=true]'
          ) as HTMLElement

          if (element) {
            const top = element.offsetTop - container.scrollTop
            const bottom = container.clientHeight - (top + element.clientHeight)

            if (bottom < 0) {
              container.scrollTop -= bottom
            }
            if (top < 0) {
              container.scrollTop += top
            }
          }
        })
      }
    }

    const handleOnChange = useCallback(
      (selectedValue: string) => {
        setInternalValue(selectedValue)
        onChange?.(selectedValue)
      },
      [onChange]
    )

    return (
      <ListboxInput
        id={id}
        name={name}
        value={internalValue}
        disabled={disabled}
        onChange={handleOnChange}
        ref={ref}
        className={className}
      >
        {({ isExpanded }) => (
          <>
            <StyledListboxButton
              arrow={
                <ArrowDownWrapper expanded={isExpanded} disabled={disabled}>
                  <CaretDown size={20} color="secondary" />
                </ArrowDownWrapper>
              }
              $hasError={internalValue ? false : !!error}
              $hasValue={!!internalValue}
              $withBorder={withBorder}
              $bg={bg}
            >
              {({ label, value }) => (
                <ListboxButtonLabel
                  title={
                    options.find(
                      (option) => option.value === value && internalValue
                    )?.title
                  }
                  label={label}
                  icon={
                    renderAsIcon
                      ? options.find((option) => option.value === value)?.icon
                      : undefined
                  }
                />
              )}
            </StyledListboxButton>
            <StyledListboxPopover
              position={getPosition}
              ref={popoverRef}
              onKeyDown={onKeyDown}
              $withBorder={withBorder}
              width={popoverWidth}
            >
              <ListboxList>
                <StyledListBoxOption value="default" display="none">
                  {placeholder}
                </StyledListBoxOption>
                {options.map((option) => (
                  <SelectItem
                    key={option.value}
                    {...option}
                    isSelected={option.value === internalValue}
                    withCheckMark={withCheckMark}
                    name={name}
                  />
                ))}
              </ListboxList>
            </StyledListboxPopover>
          </>
        )}
      </ListboxInput>
    )
  }
)
