import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.803 7.146a.5.5 0 11.707.708.5.5 0 01-.707-.708zm-.707 1.415a.5.5 0 11-.707.707.5.5 0 01.707-.707zm-1.414 1.414a.5.5 0 11-.707.707.5.5 0 01.707-.707zm-1.414 1.414a.5.5 0 11-.707.707.5.5 0 01.707-.707zM7.146 13.51a.5.5 0 11.708-.707.5.5 0 01-.708.707z"
        fill="currentColor"
      />
      <path
        d="M2.904 8.56a.5.5 0 10.707.708.5.5 0 00-.707-.707zm1.414 1.415a.5.5 0 10.707.707.5.5 0 00-.707-.707zm1.414 1.414a.5.5 0 10.707.707.5.5 0 00-.707-.707zM2.197 7.854a.5.5 0 00.064-.63.506.506 0 00-.771-.078.5.5 0 00.707.708zM3.61 5.732a.5.5 0 10-.707.707.5.5 0 00.707-.707zm1.414-1.414a.5.5 0 10-.707.707.5.5 0 00.707-.707zM6.44 2.904a.5.5 0 10-.707.707.5.5 0 00.707-.707zM7.854 1.49a.5.5 0 10-.708.707.5.5 0 00.708-.707zm1.414 1.414a.5.5 0 10-.707.707.5.5 0 00.707-.707zm1.414 1.414a.5.5 0 10-.707.707.5.5 0 00.707-.707zm1.414 1.414a.5.5 0 10-.707.707.5.5 0 00.707-.707z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgComponentPlaceholder = styled(BaseComponent)`
  outline: none;
`
export default SvgComponentPlaceholder
