import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={4.5} cy={2.5} r={0.6} fill="currentColor" />
      <circle cx={4.5} cy={4.5} r={0.6} fill="currentColor" />
      <circle cx={4.5} cy={6.499} r={0.6} fill="currentColor" />
      <circle cx={4.5} cy={8.499} r={0.6} fill="currentColor" />
      <circle cx={4.5} cy={10.498} r={0.6} fill="currentColor" />
      <circle cx={4.5} cy={12.498} r={0.6} fill="currentColor" />
      <circle cx={6.5} cy={2.5} r={0.6} fill="currentColor" />
      <circle cx={6.5} cy={4.5} r={0.6} fill="currentColor" />
      <circle cx={6.5} cy={6.499} r={0.6} fill="currentColor" />
      <circle cx={6.5} cy={8.499} r={0.6} fill="currentColor" />
      <circle cx={6.5} cy={10.498} r={0.6} fill="currentColor" />
      <circle cx={6.5} cy={12.498} r={0.6} fill="currentColor" />
      <circle cx={8.499} cy={2.5} r={0.6} fill="currentColor" />
      <circle cx={8.499} cy={4.5} r={0.6} fill="currentColor" />
      <circle cx={8.499} cy={6.499} r={0.6} fill="currentColor" />
      <circle cx={8.499} cy={8.499} r={0.6} fill="currentColor" />
      <circle cx={8.499} cy={10.498} r={0.6} fill="currentColor" />
      <circle cx={8.499} cy={12.498} r={0.6} fill="currentColor" />
      <circle cx={10.499} cy={2.5} r={0.6} fill="currentColor" />
      <circle cx={10.499} cy={4.5} r={0.6} fill="currentColor" />
      <circle cx={10.499} cy={6.499} r={0.6} fill="currentColor" />
      <circle cx={10.499} cy={8.499} r={0.6} fill="currentColor" />
      <circle cx={10.499} cy={10.498} r={0.6} fill="currentColor" />
      <circle cx={10.499} cy={12.498} r={0.6} fill="currentColor" />
    </Svg>
  )
}

const SvgDragHandleDots1 = styled(BaseComponent)`
  outline: none;
`
export default SvgDragHandleDots1
