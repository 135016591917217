import * as React from 'react'

import { Box } from '../Box'
import { Flex } from '../Flex'
import { Text } from '../Typography'
import { Props, TransparentButton } from './TransparentButton'

const DISABLED_BUTTON_TRANSPARENCY = 0.7

export const LinkButton = ({
  icon,
  iconRight,
  children,
  disabled,
  ...props
}: Props) => (
  <TransparentButton
    as="button"
    variant="transparent"
    disabled={disabled}
    {...props}
    px={[2, 5]}
    py={[1, 2, 2]}
  >
    <Flex alignItems="center">
      <Box css={{ opacity: disabled ? DISABLED_BUTTON_TRANSPARENCY : 1 }}>
        {icon}
      </Box>
      <Text
        variant="ButtonExtraExtraLarge"
        ml={icon ? [2, 4] : undefined}
        mr={iconRight ? [2, 4] : undefined}
      >
        {children}
      </Text>
      <Box css={{ opacity: disabled ? DISABLED_BUTTON_TRANSPARENCY : 1 }}>
        {iconRight}
      </Box>
    </Flex>
  </TransparentButton>
)
export default LinkButton
