import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5 75C80.897 75 95 60.897 95 43.5S80.897 12 63.5 12 32 26.103 32 43.5 46.103 75 63.5 75zm0-10.257c11.732 0 21.244-9.511 21.244-21.244s-9.512-21.244-21.245-21.244c-11.732 0-21.244 9.511-21.244 21.244S51.767 64.743 63.5 64.743z"
        fill="#4A4A48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.003 73.533a31.352 31.352 0 01-16.664 4.764 31.353 31.353 0 01-16.662-4.762l-1.488 39.958c-.053 1.404 1.71 2.07 2.598.982l14.137-17.307a1.465 1.465 0 012.254-.018l14.734 17.457c.898 1.064 2.636.392 2.584-1l-1.493-40.074z"
        fill="#F3AD3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5 61.814c10.114 0 18.314-8.2 18.314-18.314s-8.2-18.314-18.314-18.314c-10.115 0-18.315 8.2-18.315 18.314s8.2 18.314 18.314 18.314zm.001-12.21a6.105 6.105 0 100-12.21 6.105 6.105 0 000 12.21z"
        fill="#329C7F"
      />
      <circle cx={63.499} cy={43.5} r={3.663} fill="#4A4A48" />
    </Svg>
  )
}

const SvgRecommendation = styled(BaseComponent)`
  outline: none;
`
export default SvgRecommendation
