import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5h11a1 1 0 011 1v3a1 1 0 01-1 1H2a1 1 0 01-1-1V6a1 1 0 011-1zM0 6a2 2 0 012-2h11a2 2 0 012 2v3a2 2 0 01-2 2H2a2 2 0 01-2-2V6zm4.5.75a.75.75 0 100 1.5.75.75 0 000-1.5zm2.25.75a.75.75 0 111.5 0 .75.75 0 01-1.5 0zm3.75-.75a.75.75 0 100 1.5.75.75 0 000-1.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgButton = styled(BaseComponent)`
  outline: none;
`
export default SvgButton
