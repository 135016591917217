import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#4A4A48"
        d="M43.673 73.677L76.27 91.408l-2.026 3.723L41.648 77.4zM43.017 59.084L74.23 39.016l2.292 3.565-31.213 20.067z"
      />
      <circle cx={88.222} cy={34.778} r={19.778} fill="#4A4A48" />
      <circle cx={86.81} cy={98.349} r={14.127} fill="#F3AD3D" />
      <circle cx={34.54} cy={67.27} r={15.54} fill="#329C7F" />
    </Svg>
  )
}

const SvgSocial = styled(BaseComponent)`
  outline: none;
`
export default SvgSocial
