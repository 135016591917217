import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M61.77 41.81a1 1 0 00-1 1v5.197c0 .423-.268.795-.655.966-3.326 1.47-5.665 4.772-5.665 8.637 0 5.227 4.253 9.48 9.48 9.48a3.162 3.162 0 013.16 3.16 3.162 3.162 0 01-3.16 3.16c-1.4 0-2.59-.914-3.003-2.177-.172-.525-.605-.983-1.157-.983h-4.32c-.552 0-1.005.449-.947.998.363 3.43 2.576 6.296 5.612 7.638.387.171.655.544.655.967v5.196a1 1 0 001 1h4.32a1 1 0 001-1v-5.197c0-.422.268-.795.655-.966 3.326-1.47 5.665-4.771 5.665-8.636 0-5.228-4.252-9.48-9.48-9.48a3.162 3.162 0 01-3.16-3.16 3.162 3.162 0 013.16-3.16c1.4 0 2.59.913 3.003 2.176.173.525.605.984 1.157.984h4.32c.552 0 1.005-.45.947-.999-.363-3.429-2.576-6.296-5.612-7.638-.387-.17-.655-.543-.655-.966V42.81a1 1 0 00-1-1h-4.32zM68.15 10.072a.987.987 0 00-1.06.988v23.856c0 .509.384.935.887 1.008 12.414 1.791 22.184 11.561 23.98 23.959.072.503.498.887 1.007.887H116.8a.987.987 0 00.987-1.06c-2.06-26.293-23.34-47.578-49.637-49.638zM41.176 82.217c.36-.36.39-.933.084-1.34-3.57-4.743-5.77-10.565-5.77-16.946 0-14.294 10.639-26.023 24.392-28.006.504-.073.888-.499.888-1.008V11.062a.987.987 0 00-1.06-.988C32.016 12.242 10 35.69 10 63.93c0 13.316 5.084 25.462 13.12 34.846a.986.986 0 001.447.049l16.609-16.61z"
        fill="#fff"
      />
      <path
        d="M117.927 68.15a.987.987 0 00-.987-1.06H93.09c-.51 0-.935.384-1.008.888C90.099 81.732 78.37 92.37 64.076 92.37c-6.381 0-12.203-2.2-16.946-5.77a1.024 1.024 0 00-1.34.084l-16.609 16.609a.986.986 0 00.05 1.447c9.383 8.036 21.53 13.12 34.845 13.12 28.241 0 51.683-22.017 53.851-49.71z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgCircleCurrencyWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgCircleCurrencyWhite
