import { css } from '@styled-system/css'
import styled from 'styled-components'
import { ResponsiveValue } from 'styled-system'

import { Box } from '../../atoms/Box'
import { Text } from '../../atoms/Typography'
import { INPUT_HEIGHT } from '../../utils/constants'
import { PADDING_ICON, PADDING_X_SMALL } from '../../utils/inputFieldSpacing'
import { MergeElementProps } from '../../utils/types'

export type Props = MergeElementProps<
  typeof Box,
  {
    disabled?: boolean
    hasIcon?: boolean
    hasIconRight?: boolean
    inputHeight?: ResponsiveValue<number>
  }
>

export const FormField = styled(Text).attrs({ variant: 'BodySmall' })<Props>(
  ({ disabled, hasIconRight, inputHeight }) =>
    css({
      width: '100%',
      outline: 'none',
      transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      lineHeight: 1,
      color: disabled ? 'black.1' : 'secondary',
      cursor: disabled ? 'not-allowed' : 'initial',
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: PADDING_X_SMALL,
      borderRightWidth: hasIconRight ? PADDING_ICON : PADDING_X_SMALL,
      fontFamily: 'inherit',
      height: inputHeight || INPUT_HEIGHT,
    })
)

export default FormField
