import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.2 40a1 1 0 00-1.2-.8c-5.5 1.2-11 1.8-16.5 2a1 1 0 00-1 1v18.3c0 .6.4 1 1 1H87c.5 0 1-.5 1-1-.3-7-1.7-13.6-3-20.5zM67.8 10.2c-.7-.2-1.3.3-1.3 1v24.3c0 .6.5 1 1.1 1l15.5-2c.6-.1 1-.7.7-1.3-4-12-10-20.4-16-23zm-24.2 23a1 1 0 00.7 1.3 93.4 93.4 0 0016.2 2c.5 0 1-.4 1-1V11.3c0-.6-.6-1.1-1.2-.9C54 13 47.8 21.1 43.6 33.2zm47.8 28.3H117c.5 0 1-.5 1-1-.8-11-4.9-21.4-12-30a1 1 0 00-1.2-.2c-5.8 2.2-11 4.5-15.9 6.4a1 1 0 00-.6 1.2 95.4 95.4 0 013 23.6zm-51.4-1c0 .6.4 1 1 1h19.5c.6 0 1-.4 1-1V41.8c0-.6-.4-1-1-1A86 86 0 0144 38.5a1 1 0 00-1.2.7c-1.8 7-2.8 14-2.9 21.2zM88 67c0-.6-.4-1-1-1H67.6a1 1 0 00-1 1v18.8c0 .5.4 1 1 1 5.9.2 11 .9 16.6 2.1.5.1 1-.2 1.1-.7 1.8-7 2.8-14 2.9-21.2zM60.5 86.3c.6 0 1-.5 1-1V67c0-.6-.4-1-1-1H41a1 1 0 00-1 1c.2 7.3 1.5 14.2 3.3 21 .2.5.8.8 1.3.7 5-1.5 11-2.2 16-2.4zm-.2 31.3c.6.2 1.2-.3 1.2-1V92.5c0-.5-.5-1-1-1a82 82 0 00-15.9 2 1 1 0 00-.7 1c.5 7.8 8.9 20 16.4 23.2zm40.7-15.9a1 1 0 00-.3-1.5 75 75 0 00-12.4-5c-.3-.1-.6 0-.7.3-1.8 6-6.7 13.5-9.8 17.7-.6.8 0 1.6.9 1.2 5.3-2.3 16.8-7.9 22.2-12.7zm-48.8-89c.6-.6 0-1.4-.8-1.1a68.2 68.2 0 00-24.8 14.3 1 1 0 00.2 1.5c3.9 2 8.2 4.1 12.4 5.4.4.1 1-.2 1.1-.6 4.7-11 9.6-16.6 12-19.5zm23.1 0c-.6-.6 0-1.4.8-1.1a68.2 68.2 0 0124.8 14.3 1 1 0 01-.2 1.5c-3.9 2-8.2 4.1-12.4 5.4a1 1 0 01-1.1-.6c-4.7-11-9.6-16.6-12-19.5zm-8.8 104c0 .6.6 1.1 1.2.9 6.3-2.6 12.5-10.7 16.7-22.8a1 1 0 00-.7-1.3 98.1 98.1 0 00-16.2-2 1 1 0 00-1 1v24.2zM39.8 37.9a1 1 0 00-.6-1.2c-3.7-1.4-12-4.9-16-6.3a1 1 0 00-1 .3A51.9 51.9 0 0010 60.5c0 .5.4 1 1 1h24.4c.5 0 1-.4 1-1 .2-7.6 1.9-16.4 3.3-22.6zM11 66a1 1 0 00-1 1c.8 11.4 5 21.7 12 30 .3.3.8.4 1.1.3 5.1-1.9 13.2-5.1 16-6.4.5-.2.7-.7.6-1.2-1.9-7.4-3-15-3.1-22.7 0-.6-.4-1-1-1H11.1zm106.9 0c.5 0 1 .5 1 1A44.7 44.7 0 01106 97a1 1 0 01-1.1.2 73 73 0 00-15-6.4 1 1 0 01-.7-1.3c1.9-7.3 3.2-15 3.3-22.5 0-.6.4-1 1-1h24.4zm-69.3 49.5c1 .3 1.6-.7 1-1.5a48.1 48.1 0 01-8.4-16.6 1 1 0 00-1.3-.7c-4.2 1.4-8 2.8-12 4.8a1 1 0 00-.3 1.4 50.2 50.2 0 0021 12.6z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgGlobeInverted = styled(BaseComponent)`
  outline: none;
`
export default SvgGlobeInverted
