import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={72}
      height={50}
      viewBox="0 0 72 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M62.054 0H0v50h71.321V8.871h-9.267V0z" fill="#4A4A48" />
      <path
        d="M11.777 18.834H8.865v22.817h7.426v-2.852h-4.514V18.834zM19.343 41.65h2.912V18.835h-2.912v22.817zM34.176 34.007l-5.795-15.173H25.47v22.817h2.912V26.449l5.795 15.202h2.912V18.834h-2.912v15.173zM47.461 18.834v16.742c0 2.652-.437 3.508-2.126 3.508-1.689 0-2.125-.856-2.125-3.508V18.834h-2.912v16.742c0 4.592 1.66 6.36 5.037 6.36 3.378 0 5.038-1.768 5.038-6.36V18.834h-2.912zM59.385 28.417c-1.718-1.283-2.737-2.282-2.737-4.364 0-1.996.67-2.652 1.922-2.652 1.252 0 2.038.656 2.038 4.078h2.912c0-4.791-1.572-6.93-4.95-6.93s-4.834 2.139-4.834 5.504c0 3.737 1.98 5.305 4.048 6.874 2.154 1.654 2.97 2.595 2.97 5.077 0 2.395-.524 3.08-1.893 3.08-1.718 0-2.359-.856-2.359-5.22H53.59c0 6.161 1.485 8.072 5.271 8.072 3.407 0 4.805-2.168 4.805-5.932 0-4.136-1.806-5.733-4.28-7.587z"
        fill="#F9F9F9"
      />
    </Svg>
  )
}

const SvgLinus = styled(BaseComponent)`
  outline: none;
`
export default SvgLinus
