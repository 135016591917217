import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 .5a.5.5 0 01.5-.5h12a.5.5 0 010 1H9v4a1 1 0 01-1 1H7a1 1 0 01-1-1V1H1.5A.5.5 0 011 .5zM7 9a1 1 0 00-1 1v4H1.5a.5.5 0 000 1h12a.5.5 0 000-1H9v-4a1 1 0 00-1-1H7z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSpaceBetweenVertically = styled(BaseComponent)`
  outline: none;
`
export default SvgSpaceBetweenVertically
