import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.711.797a.5.5 0 00-.422 0l-6 2.8A.5.5 0 001 4.05v6.9a.5.5 0 00.289.453l6 2.8a.5.5 0 00.422 0l6-2.8A.5.5 0 0014 10.95v-6.9a.5.5 0 00-.289-.453l-6-2.8zM7.5 3.157L5.98 2.51 7.5 1.8l1.52.71-1.52.646zm.196 1.003l2.542-1.08 2.034.949L7.5 6.057 2.728 4.029l2.034-.95L7.304 4.16a.5.5 0 00.392 0zM8 6.93l5-2.124V7.93l-1.918.882a1 1 0 00-.582.908v2.078L8 12.965V6.93zm3.5 4.402l1.5-.7V9.03l-1.5.69v1.612zM7 6.93v6.034l-2.498-1.166V9.72a1 1 0 00-.582-.908L2 7.929V4.806L7 6.93zm-5 3.7l1.502.702V9.72L2 9.03v1.602z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCodesandboxLogo = styled(BaseComponent)`
  outline: none;
`
export default SvgCodesandboxLogo
