import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V6H8.5a.5.5 0 01-.5-.5V2H3.5zm5.5.707L11.293 5H9V2.707zM2 2.5A1.5 1.5 0 013.5 1h5a.5.5 0 01.354.146l4 4A.5.5 0 0113 5.5v7a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 12.5v-10z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFile = styled(BaseComponent)`
  outline: none;
`
export default SvgFile
