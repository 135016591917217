import * as React from 'react'

import {
  Disclosure,
  DisclosureProps,
  DisclosureButton,
  DisclosureButtonProps,
  DisclosurePanel,
} from '@reach/disclosure'
import styled from 'styled-components'

import { Box } from '../../atoms/Box'

const StyledBox = styled(Box)`
  cursor: pointer;
`

type CollapseButtonProps = {
  onClick?: () => void
} & DisclosureButtonProps

export const CollapseButton = (props: CollapseButtonProps) => (
  <DisclosureButton as={StyledBox} {...props} />
)

export const CollapsePanel = DisclosurePanel

export type CollapseProps = DisclosureProps

const Collapse = Disclosure

export default Collapse
