import React, { createContext, useCallback, useState } from 'react'

import { generateUniqueId } from '../../utils/misc'
import { WithChildren } from '../../utils/types'
import { ToastState, ToastId, ToastPosition, ToastProps } from './Toast.type'
import ToastManager from './ToastManager'

type Options = Pick<
  ToastProps,
  'id' | 'message' | 'position' | 'status' | 'isClosable' | 'duration'
>

type ToastProviderProps = {
  addToast: (toast: Options) => void
  addSuccess: (message: string) => void
  addError: (toast: string) => void
}

const ToastContext = createContext<ToastProviderProps>({
  addToast: () => {},
  addSuccess: () => {},
  addError: () => {},
})

export const useToast = () => {
  const context = React.useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

const getToastPosition = (toasts: ToastState, id: ToastId) =>
  Object.values(toasts)
    .flat()
    .find((toast) => toast.id === id)?.position

const ToastProvider = ({ children }: WithChildren) => {
  const [state, setState] = useState<ToastState>({
    'top-left': [],
    'top-right': [],
    'bottom-left': [],
    'bottom-right': [],
  })

  const removeToast = useCallback((id: ToastId, position: ToastPosition) => {
    setState((prevState) => ({
      ...prevState,
      [position]: prevState[position].filter((toast) => toast.id != id),
    }))
  }, [])

  const closeToast = useCallback((id: ToastId) => {
    setState((prevState) => {
      const position = getToastPosition(prevState, id)

      if (!position) {
        return prevState
      }

      return {
        ...prevState,
        [position]: prevState[position].map((toast) => {
          // id may be string or number
          if (toast.id == id) {
            return {
              ...toast,
              requestClose: true,
            }
          }

          return toast
        }),
      }
    })
  }, [])

  const createToast = useCallback(
    (options: Options) => {
      const id = options?.id ?? generateUniqueId()

      const position = options.position ?? 'top-right'

      return {
        id,
        position,
        message: options.message,
        duration: options.duration,
        onRequestRemove: () => removeToast(String(id), position),
        status: options.status,
        requestClose: false,
        isClosable: options.isClosable,
        onClose: () => closeToast(String(id)),
      }
    },
    [closeToast, removeToast]
  )

  const addToast = useCallback(
    (option: Options) => {
      const toast = createToast(option)
      const { position, id } = toast

      setState((prevToasts) => {
        const isTop = position.includes('top')

        /**
         * - If the toast is positioned at the top edges, the
         * recent toast stacks on top of the other toasts.
         *
         * - If the toast is positioned at the bottom edges, the recent
         * toast stacks below the other toasts.
         */
        const toasts = isTop
          ? [toast, ...prevToasts[position]]
          : [...prevToasts[position], toast]

        return {
          ...prevToasts,
          [position]: toasts,
        }
      })

      return id
    },
    [createToast]
  )

  const addSuccess = useCallback(
    (message: string) => {
      addToast({ message, position: 'bottom-right', status: 'success' })
    },
    [addToast]
  )

  const addError = useCallback(
    (message: string) => {
      addToast({ message, position: 'bottom-right', status: 'error' })
    },
    [addToast]
  )

  return (
    <ToastContext.Provider value={{ addToast, addSuccess, addError }}>
      <ToastManager toastState={state} />
      {children}
    </ToastContext.Provider>
  )
}

export default ToastProvider
