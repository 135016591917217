import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.05H5.525a1.475 1.475 0 000 2.95H7V2.05zm0-1h2.475a2.475 2.475 0 011.492 4.45A2.475 2.475 0 018 9.463V11.425A2.475 2.475 0 114.033 9.45a2.471 2.471 0 01-.983-1.975c0-.807.386-1.523.983-1.975a2.475 2.475 0 011.492-4.45H7zm1 1V5h1.475a1.475 1.475 0 100-2.95H8zm-2.475 6.9H7V6H5.525a1.475 1.475 0 00-.006 2.95h.006zM4.05 11.425c0-.813.657-1.472 1.47-1.475H7v1.475a1.475 1.475 0 01-2.95 0zM8 7.472a1.475 1.475 0 110 .006v-.006z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFigmaLogo = styled(BaseComponent)`
  outline: none;
`
export default SvgFigmaLogo
