import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.203 1.043a.5.5 0 00-.635.709L3.921 7.5.568 13.248a.5.5 0 00.635.709l13.5-6a.5.5 0 000-.914l-13.5-6zM4.846 7.1L2.212 2.586 13.27 7.5 2.212 12.414 4.846 7.9H9a.4.4 0 100-.8H4.846z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPaperPlane = styled(BaseComponent)`
  outline: none;
`
export default SvgPaperPlane
