import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2H6v11h3V2zm1 0v11h2.5a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H10zM2.5 2H5v11H2.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5zm0-1A1.5 1.5 0 001 2.5v10A1.5 1.5 0 002.5 14h10a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0012.5 1h-10z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLayout = styled(BaseComponent)`
  outline: none;
`
export default SvgLayout
