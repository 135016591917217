import { TouchEvent, useEffect, useState } from 'react'

import useEventCallback from 'use-event-callback'

import { useCaptureOptions } from './useCaptureOptions'

class Vector2d {
  x: number
  y: number

  static ZERO: Vector2d = new Vector2d(0, 0)

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }
  subtract(point: Vector2d): Vector2d {
    return new Vector2d(point.x - this.x, point.y - this.y)
  }
  lengthSq(): number {
    return this.x * this.x + this.y * this.y
  }
}

type UseSwiping = (
  elementRef: React.RefObject<HTMLElement | Document>,
  options: {
    threshold: number
    onSwipeStart?: () => void
    onSwipeEnd?: () => void
  }
) => {
  startPosition: Vector2d
  currentPosition: Vector2d
  diffPosition: Vector2d
  isMoving: boolean
}

const TOUCH_START_EVENT = 'touchstart'
const TOUCH_MOVE_EVENT = 'touchmove'
const TOUCH_END_EVENT = 'touchend'
const MOUSE_DOWN_EVENT = 'mousedown'
const MOUSE_MOVE_EVENT = 'mousemove'
const MOUSE_UP_EVENT = 'mouseup'

// used some parts of https://github.com/leandrowd/react-easy-swipe/blob/master/src/react-swipe.js

/**
 * getPosition returns a position element that works for mouse or touch events
 * @param  {TouchEvent | MouseEvent} event the received event
 * @return {Vector2d} coords
 */
const getPosition = (event: TouchEvent | MouseEvent): Vector2d => {
  if ('touches' in event) {
    const { pageX, pageY } = event.touches[0]
    return new Vector2d(pageX, pageY)
  }

  const { screenX, screenY } = event
  return new Vector2d(screenX, screenY)
}

export const useSwiping: UseSwiping = (elementRef, options) => {
  const { threshold, onSwipeStart, onSwipeEnd } = options
  const [isMoving, setMoving] = useState(false)
  const [isMouseDown, setMouseDown] = useState(false)
  const [isSwiping, setSwiping] = useState(false)
  const [startPosition, setStartPosition] = useState<Vector2d>(
    new Vector2d(0, 0)
  )
  const [currentPosition, setCurrentPosition] = useState<Vector2d>(
    new Vector2d(0, 0)
  )
  const [diffPosition, setDiffPosition] = useState<Vector2d>(new Vector2d(0, 0))

  const isCaptureAvailable = useCaptureOptions()

  const handleMove = useEventCallback((event: TouchEvent | MouseEvent) => {
    if (!isSwiping && !isMouseDown) {
      return
    }
    if (!isMoving) {
      if (diffPosition.lengthSq() > threshold) {
        setMoving(true)
        if (onSwipeStart) {
          onSwipeStart()
        }
      }
    }
    const current = getPosition(event)
    setCurrentPosition(current)
    setDiffPosition(startPosition.subtract(current))
  })

  const handleTouchStart = useEventCallback(
    (event: TouchEvent | MouseEvent) => {
      const current = getPosition(event)
      setStartPosition(current)
      setCurrentPosition(current)
      setDiffPosition(Vector2d.ZERO)
      setSwiping(true)
    }
  )

  const handleTouchEnd = useEventCallback(() => {
    setSwiping(false)
    setMoving(false)
    if (onSwipeEnd) {
      onSwipeEnd()
    }
  })

  const handleMouseMove = useEventCallback((event: MouseEvent) => {
    if (isMouseDown) {
      handleMove(event)
    }
    if (event.cancelable) {
      event.preventDefault()
    }
  })

  const handleMouseUp = useEventCallback(() => {
    document.removeEventListener(MOUSE_MOVE_EVENT, handleMouseMove)
    document.removeEventListener(MOUSE_UP_EVENT, handleMouseUp)
    setMouseDown(false)
    handleTouchEnd()
  })

  const handleMouseDown = useEventCallback((event: MouseEvent) => {
    document.addEventListener(MOUSE_MOVE_EVENT, handleMouseMove)
    document.addEventListener(MOUSE_UP_EVENT, handleMouseUp)
    setMouseDown(true)
    handleTouchStart(event)
  })

  useEffect(() => {
    if (!elementRef.current) {
      return
    }
    const element = elementRef.current
    element.addEventListener(MOUSE_DOWN_EVENT, handleMouseDown as EventListener)
    element.addEventListener(
      TOUCH_START_EVENT,
      handleTouchStart as EventListener
    )
    element.addEventListener(
      TOUCH_MOVE_EVENT,
      handleMove as EventListener,
      isCaptureAvailable
        ? {
            capture: true,
            passive: false,
          }
        : false
    )
    element.addEventListener(TOUCH_END_EVENT, handleTouchEnd as EventListener)
    return () => {
      element.removeEventListener(
        TOUCH_END_EVENT,
        handleTouchEnd as EventListener
      )
      element.removeEventListener(TOUCH_MOVE_EVENT, handleMove as EventListener)
      element.removeEventListener(
        TOUCH_START_EVENT,
        handleTouchStart as EventListener
      )
      element.removeEventListener(
        MOUSE_DOWN_EVENT,
        handleMouseDown as EventListener
      )
      if (isMouseDown) {
        element.removeEventListener(
          MOUSE_MOVE_EVENT,
          handleMouseMove as EventListener
        )
        element.removeEventListener(
          MOUSE_UP_EVENT,
          handleMouseUp as EventListener
        )
      }
    }
  }, [
    elementRef,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleMove,
    handleTouchEnd,
    handleTouchStart,
    isCaptureAvailable,
    isMouseDown,
  ])

  return {
    startPosition,
    currentPosition,
    diffPosition,
    isMoving,
  }
}
