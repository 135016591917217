import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5a.5.5 0 00-.8-.4L3.333 4H1.5A1.5 1.5 0 000 5.5v4A1.5 1.5 0 001.5 11h1.833L7.2 13.9a.5.5 0 00.8-.4v-12zM3.8 4.9L7 2.5v10l-3.2-2.4a.5.5 0 00-.3-.1h-2a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h2a.5.5 0 00.3-.1zm6.283.156a.4.4 0 10-.666.443 3.623 3.623 0 010 4.002.4.4 0 10.666.443 4.423 4.423 0 000-4.888z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSpeakerQuiet = styled(BaseComponent)`
  outline: none;
`
export default SvgSpeakerQuiet
