import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3h9v9H3V3zM2 3a1 1 0 011-1h9a1 1 0 011 1v9a1 1 0 01-1 1H3a1 1 0 01-1-1V3zm8.35 2.511a.5.5 0 00-.825-.566L6.64 9.15l-1.443-1.74a.5.5 0 00-.77.638l1.866 2.25a.5.5 0 00.797-.037l3.26-4.749z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCheckbox = styled(BaseComponent)`
  outline: none;
`
export default SvgCheckbox
