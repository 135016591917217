import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.181 1.682a.45.45 0 01.637 0l2.5 2.5a.45.45 0 01-.637.636L7.95 3.086v8.828l1.731-1.732a.45.45 0 01.637.636l-2.5 2.5a.45.45 0 01-.637 0l-2.5-2.5a.45.45 0 01.637-.636l1.732 1.732V3.086L5.317 4.818a.45.45 0 01-.637-.636l2.5-2.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgHeight = styled(BaseComponent)`
  outline: none;
`
export default SvgHeight
