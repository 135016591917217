import React from 'react'

import { Close, HamburgerMenu } from '@linus-capital/icons'
import { css } from '@styled-system/css'
import styled from 'styled-components'

import { Box, BoxProps } from '../../atoms/Box'
import { Button, IconButton } from '../../atoms/Button'
import { Variants as ButtonVariants } from '../../atoms/Button/Button'
import { Flex } from '../../atoms/Flex'
import { H5, H7, Text } from '../../atoms/Typography'
import { useToggle, useScrollLock } from '../../hooks'
import {
  Collapse,
  CollapseButton,
  CollapsePanel,
  CollapseStatusIcon,
} from '../../molecules/Collapse'
import { Hide } from '../../molecules/Hide'
import { Person } from '../../molecules/Person'
import { Popup } from '../../molecules/Popup'
import Header from './Header'

type LinkProps = {
  label: string
  onClick: () => any
  phone?: string
}

type Person = {
  firstName: string
  lastName: string
  position?: string
  imageSrc?: string
}

export type Props = {
  onLogoClick?: () => void
  manager: Person
  investor: Person
  headerLinks?: LinkProps[]
  userProfileActions: LinkProps[]
  accountManagerActions: LinkProps[]
}

const formatName = (person: Person): string =>
  `${person.firstName} ${person.lastName}`

const StyledLink = styled(Button).attrs<{
  href: string
  variant?: ButtonVariants
  textAlign?: string
}>(({ href, variant, textAlign }) => ({
  href,
  variant: variant ?? 'transparent',
  textAlign: textAlign ?? 'center',
}))(() =>
  css({
    backgroundColor: ['primary[0]', 'primary[0]', 'transparent'],
    whiteSpace: 'nowrap',
    '&:hover:not(:disabled)': {
      backgroundColor: ['primary[1]', 'primary[1]', 'transparent'],
    },
  })
)

const StyledCloseButton = styled(IconButton)(
  css({
    display: 'flex',
    lineHeight: '1em',
    px: 2,
    py: 3,
    minWidth: 0,
    width: 'auto',
  })
)

const HamburgerMenuIcon = <HamburgerMenu size={30} />
const CloseIcon = <Close size={20} />
const StyledItemsSeparator = styled(Box)(
  css({
    bg: 'background',
    height: '1px',
  })
)

const PersonButton = ({
  person,
  ...boxProps
}: { person: Person } & BoxProps) => {
  const fullName = formatName(person)
  return (
    <Person
      p={3}
      imagePath={person.imageSrc}
      altText={fullName}
      fullName={fullName}
      positionTitle={person.position}
      {...boxProps}
    />
  )
}

const StyledPersonButton = styled(PersonButton)(
  css({
    position: 'relative',
    borderLeft: 'thin',
    borderColor: 'background',
  })
)

const PersonMenuContent = ({
  dropdownMenu,
  isInvestor,
  buttonRectangle,
}: {
  dropdownMenu: LinkProps[]
  isInvestor: boolean
  buttonRectangle?: DOMRect
}) => (
  <Box p={3} minWidth={buttonRectangle?.width}>
    {dropdownMenu.map(({ label, onClick, phone }, idx) => (
      <Box key={idx}>
        {phone && (
          <Text mb={3} as="a" href={phone} variant="BodyExtraExtraSmall">
            {phone}
          </Text>
        )}
        <Button
          width="100%"
          tabIndex={0}
          variant="ternary"
          textVariant="ButtonSmall"
          onClick={onClick}
          textAlign={isInvestor ? 'left' : 'center'}
        >
          {label}
        </Button>
        {idx !== dropdownMenu.length - 1 && !isInvestor && (
          <StyledItemsSeparator mt={3} />
        )}
      </Box>
    ))}
  </Box>
)

const DesktopMenu = ({
  headerLinks,
  manager,
  investor,
  accountManagerActions,
  userProfileActions,
  ...boxProps
}: Props & BoxProps) => (
  <Hide sm md>
    <Box width="100%" flex="1" {...boxProps}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flex="1 0 auto" justifyContent="center">
          {headerLinks?.map(({ label, onClick }) => (
            <StyledLink key={label} tabIndex={0} onClick={onClick}>
              <H7>{label}</H7>
            </StyledLink>
          ))}
        </Flex>

        <Flex>
          <Popup
            id="menu_1"
            minWidth="30%"
            buttonRenderFn={(isOpen, handleClick) => (
              <Flex alignItems="center" onClick={handleClick}>
                <StyledPersonButton person={manager} />
                <CollapseStatusIcon isExpanded={isOpen} />
              </Flex>
            )}
          >
            <PersonMenuContent
              dropdownMenu={accountManagerActions}
              isInvestor={false}
            />
          </Popup>

          <Popup
            id="menu_2"
            minWidth="30%"
            buttonRenderFn={(isOpen, handleClick) => (
              <Flex alignItems="center" onClick={handleClick}>
                <StyledPersonButton person={investor} />
                <CollapseStatusIcon isExpanded={isOpen} />
              </Flex>
            )}
          >
            <PersonMenuContent
              dropdownMenu={userProfileActions}
              isInvestor={true}
            />
          </Popup>
        </Flex>
      </Flex>
    </Box>
  </Hide>
)

const MobileMenuContent = ({
  headerLinks,
  accountManagerActions,
  userProfileActions,
  manager,
  investor,
  closePopup,
}: Omit<Props, 'onLogoClick'> & { closePopup?: () => void }) => {
  const {
    isOpen: isManagerOpen,
    handleToggle: handleHandleCollapseToggle,
  } = useToggle()
  const {
    isOpen: isInvestorOpen,
    handleToggle: handleInvestorCollapseToggle,
  } = useToggle()
  useScrollLock()

  return (
    <Flex
      flexDirection="column"
      overflow="auto"
      maxHeight="100vh"
      height="100vh"
    >
      <Flex justifyContent="right" minWidth={['100vw', 'auto', 'auto']}>
        <StyledCloseButton icon={CloseIcon} onClick={closePopup} />
      </Flex>
      {headerLinks?.map(({ label, onClick }) => (
        <StyledLink
          key={label}
          tabIndex={0}
          textAlign="left"
          variant="menu"
          onClick={onClick}
        >
          <H5>{label}</H5>
        </StyledLink>
      ))}
      <Collapse open={isManagerOpen} onChange={handleHandleCollapseToggle}>
        <CollapseButton>
          <Flex justifyContent="space-between" alignItems="center">
            <PersonButton person={manager} />
            <CollapseStatusIcon isExpanded={isManagerOpen} />
          </Flex>
        </CollapseButton>
        <CollapsePanel as={Box}>
          <PersonMenuContent
            dropdownMenu={accountManagerActions}
            isInvestor={false}
          />
        </CollapsePanel>
      </Collapse>

      <StyledItemsSeparator />

      <Collapse open={isInvestorOpen} onChange={handleInvestorCollapseToggle}>
        <CollapseButton>
          <Flex justifyContent="space-between" alignItems="center">
            <PersonButton person={investor} />
            <CollapseStatusIcon isExpanded={isInvestorOpen} />
          </Flex>
        </CollapseButton>
        <CollapsePanel as={Box}>
          <PersonMenuContent
            dropdownMenu={userProfileActions}
            isInvestor={true}
          />
        </CollapsePanel>
      </Collapse>
    </Flex>
  )
}

const MobileMenu = ({
  accountManagerActions,
  userProfileActions,
  manager,
  investor,
  headerLinks,
  ...boxProps
}: Props & BoxProps) => (
  // TODO: it might be better to move call to this hook out of the component
  <Hide lg xl>
    <Box {...boxProps}>
      <Flex position="relative" justifyContent="flex-end">
        <Popup
          position={() => ({ top: 0, right: '-100vw' })}
          id="menu_3"
          buttonRenderFn={(_isOpen, handleOpenClick) => (
            <Flex>
              <IconButton icon={HamburgerMenuIcon} onClick={handleOpenClick} />
            </Flex>
          )}
        >
          <MobileMenuContent
            headerLinks={headerLinks}
            accountManagerActions={accountManagerActions}
            userProfileActions={userProfileActions}
            manager={manager}
            investor={investor}
          />
        </Popup>
      </Flex>
    </Box>
  </Hide>
)

export const PrivateHeader = (props: Props) => (
  <Header onLogoClick={props.onLogoClick}>
    <DesktopMenu {...props} />
    <MobileMenu {...props} />
  </Header>
)

export default PrivateHeader
