import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.59 4.129a1.4 1.4 0 00-1.98 0L8.837 20.902 2.39 14.455a1.4 1.4 0 00-1.98 1.98l7.437 7.437a1.4 1.4 0 001.98 0L27.59 6.109a1.4 1.4 0 000-1.98z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCheck = styled(BaseComponent)`
  outline: none;
`
export default SvgCheck
