import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.854 1.146a.5.5 0 00-.707 0L3.714 8.578a1 1 0 00-.212.314L2.04 12.303a.5.5 0 00.657.657l3.411-1.463a1 1 0 00.314-.211l7.432-7.432a.5.5 0 000-.708l-2-2zm-7.432 8.14L11.5 2.206 12.793 3.5l-7.078 7.078-1.496.641-.438-.438.64-1.496z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPencil1 = styled(BaseComponent)`
  outline: none;
`
export default SvgPencil1
