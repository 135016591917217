import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.553 1c.2 0 .38.12.46.303L8.01 8.3a.5.5 0 11-.92.394l-.975-2.277H2.99l-.976 2.277a.5.5 0 01-.92-.394l3-6.997A.5.5 0 014.552 1zm0 1.77l1.199 2.797H3.354l1.199-2.798zm6.503 6.232a.5.5 0 00.466-.317l2.751-7.002a.5.5 0 00-.93-.366l-2.287 5.818L8.77 1.317a.5.5 0 10-.931.366l2.752 7.002a.5.5 0 00.465.317zm3.898 3.498a.4.4 0 01-.118.283l-2 2a.4.4 0 01-.565-.566l1.317-1.317H1.519l1.318 1.317a.4.4 0 01-.566.566l-2-2a.4.4 0 010-.566l2-2a.4.4 0 01.566.566L1.519 12.1h12.069l-1.317-1.317a.4.4 0 01.565-.566l2 2a.4.4 0 01.118.283z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLetterSpacing = styled(BaseComponent)`
  outline: none;
`
export default SvgLetterSpacing
