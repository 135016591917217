import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.947.165a1 1 0 00-.894 0l-6.5 3.25A1 1 0 000 4.309V12a1 1 0 001 1h13a1 1 0 001-1V4.309a1 1 0 00-.553-.894l-6.5-3.25zm5.622 3.928L7.5 1.06 1.431 4.093 7.5 7.291l6.069-3.198zM1 4.883V12h13V4.884L7.71 8.198a.45.45 0 01-.42 0L1 4.884z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgEnvelopeOpen = styled(BaseComponent)`
  outline: none;
`
export default SvgEnvelopeOpen
