import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 1a.5.5 0 00-.5.5v12a.5.5 0 101 0v-12a.5.5 0 00-.5-.5zM.5 1a.5.5 0 00-.5.5v12a.5.5 0 001 0v-12A.5.5 0 00.5 1zM2 7a1 1 0 011-1h3a1 1 0 011 1v1a1 1 0 01-1 1H3a1 1 0 01-1-1V7zm7-1a1 1 0 00-1 1v1a1 1 0 001 1h3a1 1 0 001-1V7a1 1 0 00-1-1H9z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSpaceEvenlyHorizontally = styled(BaseComponent)`
  outline: none;
`
export default SvgSpaceEvenlyHorizontally
