import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M108 48.107a1 1 0 01-1 1H21a1 1 0 01-1-1V37.371a1 1 0 011-1h86a1 1 0 011 1v10.736zM108 30.737a1 1 0 01-1 1H21a1 1 0 01-1-1V20a1 1 0 011-1h86a1 1 0 011 1v10.737zM107.998 116.759a1 1 0 01-1 1H68.472a1 1 0 01-1-1v-10.737a1 1 0 011-1h38.526a1 1 0 011 1v10.737zM107.998 99.697a1 1 0 01-1 1H68.472a1 1 0 01-1-1V88.96a1 1 0 011-1h38.526a1 1 0 011 1v10.737zM107.998 82.636a1 1 0 01-1 1H68.472a1 1 0 01-1-1V71.899a1 1 0 011-1h38.526a1 1 0 011 1v10.737zM107.998 65.573a1 1 0 01-1 1H68.472a1 1 0 01-1-1V54.836a1 1 0 011-1h38.526a1 1 0 011 1v10.737zM60.526 116.759a1 1 0 01-1 1H21a1 1 0 01-1-1v-10.737a1 1 0 011-1h38.526a1 1 0 011 1v10.737zM60.526 99.697a1 1 0 01-1 1H21a1 1 0 01-1-1V88.96a1 1 0 011-1h38.526a1 1 0 011 1v10.737zM60.526 82.636a1 1 0 01-1 1H21a1 1 0 01-1-1V71.899a1 1 0 011-1h38.526a1 1 0 011 1v10.737zM60.526 65.573a1 1 0 01-1 1H21a1 1 0 01-1-1V54.836a1 1 0 011-1h38.526a1 1 0 011 1v10.737z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgCoins3White = styled(BaseComponent)`
  outline: none;
`
export default SvgCoins3White
