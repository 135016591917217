import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5.877a6.623 6.623 0 00-5.023 10.94l-.83.83a.5.5 0 10.707.707l.83-.83a6.623 6.623 0 009.34-9.34l.83-.83a.5.5 0 10-.708-.708l-.83.83A6.597 6.597 0 007.5.878zm3.642 2.274a5.673 5.673 0 00-7.992 7.992l7.992-7.992zm-7.284 8.698a5.673 5.673 0 007.992-7.992L3.857 11.85z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgValueNone = styled(BaseComponent)`
  outline: none;
`
export default SvgValueNone
