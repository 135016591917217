import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 233.3 167.9"
      {...props}
    >
      <path fill="#fff" d="M10.4 12.8v142.3h212.5v-117h-31.6V12.8z" />
      <path
        d="M197.9 112.8c0-10.2-3.1-14.5-11-17.6l-3.6-1.4c-4.8-1.9-5.8-3.5-5.8-9.1 0-4.9 1.8-7.2 5.5-7.2 3.9 0 5.5 2.7 5.7 7.7 0 .5.4.8 1 .7l7.1-1.1c.5-.2.8-.4.8-1-.5-9.4-4.9-14.7-14.8-14.7s-14.6 5.7-14.6 16c0 9.4 2.5 13.7 10.8 17l3.6 1.4c4.7 1.9 5.9 4 5.9 10.4 0 6.5-1.9 8.6-5.7 8.6-4.1 0-5.9-2.5-6.2-8.8 0-.5-.4-.9-1-.8l-7.4 1.2c-.5.1-.7.4-.7 1 .3 10.5 5.3 15.7 15 15.7 11.4 0 15.4-6.2 15.4-18m-52.9 18c9.3 0 14.9-5.4 14.9-16.1V70.8c0-.5-.4-.9-.9-.9h-7.7c-.5 0-.9.4-.9.9V116c0 3.8-1.9 6.3-5.5 6.3-3.5 0-5.6-2.5-5.6-6.3V70.8c0-.5-.4-.9-.9-.9h-7.7c-.5 0-.9.4-.9.9v43.9c.2 10.7 6 16.1 15.2 16.1m-56-1.7c0 .5.4.9.9.9h6.5c.5 0 .9-.4.9-.9V87.6h.5l11.7 41.5c.2.6.5.9 1.2.9h8.7c.5 0 .9-.4.9-.9V70.8c0-.5-.4-.9-.9-.9h-6.5c-.5 0-.9.4-.9.9v41.5h-.4l-11.1-41.5c-.2-.6-.5-.9-1.2-.9h-9.4c-.5 0-.9.4-.9.9v58.3zm-19.2 0c0 .5.4.9.9.9h7.7c.5 0 .9-.4.9-.9V70.8c0-.5-.4-.9-.9-.9h-7.7c-.5 0-.9.4-.9.9v58.3zm-34.4 0c0 .5.4.9.9.9h24.6c.5 0 .9-.4.9-.9v-6.8c0-.5-.4-.9-.9-.9H45.4c-.4 0-.5-.2-.5-.5V70.8c0-.5-.4-.9-.9-.9h-7.7c-.5 0-.9.4-.9.9v58.3z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLinusLogoInv = styled(BaseComponent)`
  outline: none;
`
export default SvgLinusLogoInv
