import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.818.682a.45.45 0 00-.636 0l-2 2a.45.45 0 00.636.636L7.05 2.086V5.5a.45.45 0 10.9 0V2.086l1.232 1.232a.45.45 0 00.636-.636l-2-2zm.132 12.232V9.5a.45.45 0 10-.9 0v3.414l-1.232-1.232a.45.45 0 00-.636.636l2 2a.45.45 0 00.636 0l2-2a.45.45 0 10-.636-.636L7.95 12.914zM1.5 7a.5.5 0 000 1h12a.5.5 0 100-1h-12z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgRowSpacing = styled(BaseComponent)`
  outline: none;
`
export default SvgRowSpacing
