import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 2H8v5h5V2.5a.5.5 0 00-.5-.5zm.5 6H8v5h4.5a.5.5 0 00.5-.5V8zM7 7V2H2.5a.5.5 0 00-.5.5V7h5zM2 8v4.5a.5.5 0 00.5.5H7V8H2zm.5-7A1.5 1.5 0 001 2.5v10A1.5 1.5 0 002.5 14h10a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0012.5 1h-10z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgGrid = styled(BaseComponent)`
  outline: none;
`
export default SvgGrid
