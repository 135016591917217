import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={60}
      height={53}
      viewBox="0 0 60 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58 44h-5v-5a2 2 0 00-2-2h-5V20.727c.153-.078.294-.152.42-.22a2.994 2.994 0 001.56-2.624L48 11a3 3 0 00-3-3h-8V4a4 4 0 00-4-4H15a4 4 0 00-4 4v4H3a3 3 0 00-3 3l.02 6.88a3 3 0 001.564 2.63c.124.067.264.14.416.217V43a3 3 0 003 3h11v5a2 2 0 002 2h12a1.977 1.977 0 001-.278c.303.18.648.276 1 .278h12a1.977 1.977 0 001-.278c.303.18.648.276 1 .278h12a2 2 0 002-2v-5a2 2 0 00-2-2zm-7-5v5H39v-5h12zm-21 5h-5v-5h12v5h-7zm9-7h-7v-5h12v5h-5zM13 4a2 2 0 012-2h18a2 2 0 012 2v4h-2V5a1 1 0 00-1-1H16a1 1 0 00-1 1v3h-2V4zm18 4H17V6h14v2zM3 10h42a1 1 0 011 1l-.02 6.883a.992.992 0 01-.516.87c-.228.123-.511.268-.84.426l-.035.017c-2.411 1.149-7.495 3.043-15.59 3.623V22a2 2 0 00-2-2h-6a2 2 0 00-2 2v.819c-8.1-.58-13.18-2.474-15.587-3.625-.013-.006-.024-.013-.037-.018-.326-.157-.608-.3-.835-.424a1 1 0 01-.52-.875L2 11a1 1 0 011-1zm24 16h-6v-4h6v4zm-5 2h4v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2zM5 44a1 1 0 01-1-1V21.636a48.734 48.734 0 0015 3.187V26a1.993 1.993 0 001 1.722V30a3 3 0 003 3h2a3 3 0 003-3v-2.278A1.993 1.993 0 0029 26v-1.177a48.728 48.728 0 0015-3.187V30H32a2 2 0 00-2 2v5h-5a2 2 0 00-2 2v5H5zm13 7v-5h12v5H18zm14 0v-5h12v5H32zm14 0v-5h12v5H46z"
        fill="#2C2C2C"
      />
    </Svg>
  )
}

const SvgApartment = styled(BaseComponent)`
  outline: none;
`
export default SvgApartment
