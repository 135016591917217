import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 1a.5.5 0 01.5.5v12a.5.5 0 01-1 0v-12a.5.5 0 01.5-.5zm-2 2a.5.5 0 01.5.5v10a.5.5 0 01-1 0v-10a.5.5 0 01.5-.5zm4 0a.5.5 0 01.5.5v10a.5.5 0 01-1 0v-10a.5.5 0 01.5-.5zm-8 1a.5.5 0 01.5.5v9a.5.5 0 01-1 0v-9a.5.5 0 01.5-.5zm-4 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8a.5.5 0 01.5-.5zm6 0a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8a.5.5 0 01.5-.5zm-4 2a.5.5 0 01.5.5v6a.5.5 0 01-1 0v-6a.5.5 0 01.5-.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBarChart = styled(BaseComponent)`
  outline: none;
`
export default SvgBarChart
