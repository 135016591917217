import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.004 94.71a.004.004 0 00.004-.003c.285-9.688 8.164-18.101 19.73-22.566.426-.164.906-.01 1.177.357 4.273 5.773 11.132 9.515 18.865 9.515 7.455 0 14.098-3.478 18.394-8.9a1.015 1.015 0 011.192-.317c10.684 4.602 17.853 12.675 18.126 21.907 0 .004.003.007.007.007.005 0 .008.004.008.008v17.229c0 .553-.447 1-1 1H24a1 1 0 01-1-1V94.714c0-.002.002-.004.004-.004z"
        fill="#4A4A48"
      />
      <circle cx={64.191} cy={48.687} r={22.63} fill="#4A4A48" />
      <path
        d="M38.948 16H27.633a2.83 2.83 0 00-2.828 2.829V35.8a2.83 2.83 0 005.657 0V22.657a1 1 0 011-1h7.486a2.83 2.83 0 000-5.657z"
        fill="#329C7F"
      />
      <path
        d="M38.948 65.24h-7.486a1 1 0 01-1-1V51.098a2.83 2.83 0 00-5.657 0V68.07a2.83 2.83 0 002.828 2.828h11.315a2.83 2.83 0 000-5.657z"
        fill="#F3AD3D"
      />
      <path
        d="M101.172 17.676H89.857a2.83 2.83 0 000 5.658h7.486a1 1 0 011 1v13.143a2.83 2.83 0 005.658 0V20.505a2.83 2.83 0 00-2.829-2.829z"
        fill="#329C7F"
      />
      <path
        d="M101.172 49.945a2.83 2.83 0 00-2.829 2.828v13.144a1 1 0 01-1 1h-7.486a2.83 2.83 0 000 5.657h11.315a2.83 2.83 0 002.829-2.828V52.774a2.83 2.83 0 00-2.829-2.83z"
        fill="#F3AD3D"
      />
    </Svg>
  )
}

const SvgVerification = styled(BaseComponent)`
  outline: none;
`
export default SvgVerification
