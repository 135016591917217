import * as React from 'react'

import { css } from '@styled-system/css'
import styled from 'styled-components'
import { FlexboxProps, SpaceProps } from 'styled-system'

import Flex from '../../atoms/Flex'

type Props = {
  children: React.ReactNode
}

type FormIconProps = {
  error?: boolean
  isInputFocus?: boolean
  withIcon?: boolean
  disabled?: boolean
} & Props &
  SpaceProps &
  FlexboxProps

const FormWrapper = styled(Flex)<{
  hasFocus: boolean
  hasError: boolean
  disabled: boolean
  isValid?: boolean
}>(({ hasFocus, hasError, isValid = false, disabled }) => {
  const getBorderColor = () => {
    if (disabled) {
      return 'black.1'
    } else if (hasError) {
      return 'red'
    } else if (hasFocus) {
      return 'black.0'
    } else if (isValid) {
      return 'secondary'
    }
    return 'black.2'
  }

  return css({
    position: 'relative',
    border: hasFocus ? 'thick' : 'thin',
    m: hasFocus ? 0 : '1px',
    bg: disabled ? 'earth.0' : 'none',
    borderColor: getBorderColor(),
  })
})

export const FormIconWrapper = styled(Flex)<FormIconProps>(
  ({ error, isInputFocus, disabled, onClick }) => {
    const getColor = () => {
      if (disabled) {
        return 'black.1'
      } else if (error) {
        return 'red'
      } else if (isInputFocus) {
        return 'primary'
      }
      return 'inherit'
    }

    return css({
      position: 'absolute',
      height: '100%',
      alignItems: 'center',
      px: 4,
      cursor: onClick ? 'pointer' : 'auto',
      color: getColor(),
    })
  }
)

export default FormWrapper
