import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67 19.887c0-.49.397-.887.887-.887h44.326c.489 0 .886.397.886.887v88.226c0 .49-.397.887-.886.887H96.04c.53 0 .96-.43.96-.96V101h7.04c.53 0 .96-.43.96-.96V91h2.04c.53 0 .96-.43.96-.96V79.96a.96.96 0 00-.96-.96H103v-9.04a.96.96 0 00-.96-.96H67V19.887z"
        fill="#fff"
      />
      <rect
        x={15}
        y={67.262}
        width={28.704}
        height={41.462}
        rx={0.96}
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 70.613c0 .169-.07.33-.193.446l-7.506 7.084a.96.96 0 00-.04 1.357l7.466 7.911a1 1 0 01.273.687v12.688a7.683 7.683 0 007.683 7.683h26.39v-.001-.001h17.729c.53 0 .96-.43.96-.96v-5.773a.96.96 0 00-.96-.961H77.46a.387.387 0 110-.773h24.58c.53 0 .96-.43.96-.96v-7.08a.96.96 0 00-.96-.96H77.573a.5.5 0 110-1h27.467c.53 0 .96-.43.96-.96v-8.08a.96.96 0 00-.96-.96H77.677a.604.604 0 110-1.208h22.616a.96.96 0 00.961-.96V70.96a.96.96 0 00-.961-.96H56.646a1 1 0 01-.966-.742l-1.779-6.64a1 1 0 00-1.652-.468l-8.126 7.668a.666.666 0 01-.457.181h-.052a.614.614 0 00-.614.614z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.836 34.335a.865.865 0 00-.612 1.06L57.228 69h8.105V29.915l-16.497 4.42zm18.06-4.839V69h13.78l16.693-4.473a.865.865 0 00.612-1.06l-10.35-38.63a.865.865 0 00-1.06-.613l-19.675 5.272z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgMoneyWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgMoneyWhite
