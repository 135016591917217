import React, { PropsWithChildren, ReactNode } from 'react'

import {
  Accordion as ReachAccordion,
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'

import { Box, BoxProps } from '../../atoms/Box'
import { Button, ButtonProps } from '../../atoms/Button'
import FadeIn from '../Transition/FadeIn'

type ChildrenProps = (props: { isExpanded: boolean }) => ReactNode

export const AccordionButton = ({
  children,
  iconRight,
  ...props
}: PropsWithChildren<Omit<ButtonProps, 'variant'>>) => (
  <Button
    as={ReachAccordionButton}
    variant="text"
    px={0}
    py={0}
    width="100%"
    justifyContent="flex-start"
    iconRight={iconRight}
    textAlign="left"
    {...props}
  >
    {children}
  </Button>
)

export const Accordion = ({
  children,
  openedIndex = Infinity,
  multiple = true,
  collapsible = true,
}: PropsWithChildren<{
  openedIndex?: number
  multiple?: boolean
  collapsible?: boolean
}>) => (
  <ReachAccordion
    collapsible={collapsible}
    defaultIndex={openedIndex}
    multiple={multiple}
  >
    {children}
  </ReachAccordion>
)

const AccordionItemChildren = ({ children }: { children: ChildrenProps }) => {
  const { isExpanded } = useAccordionItemContext()
  return <>{children({ isExpanded })}</>
}

export const AccordionItem = ({
  children,
  disabled = false,
  ...props
}: {
  children: ChildrenProps
  disabled?: boolean
} & BoxProps) => (
  <ReachAccordionItem as={Box} disabled={disabled} width="100%" {...props}>
    <AccordionItemChildren>{children}</AccordionItemChildren>
  </ReachAccordionItem>
)

export const AccordionPanel = ({
  children,
  duration = 500,
  ...props
}: PropsWithChildren<{ duration?: number } & BoxProps>) => {
  const { isExpanded } = useAccordionItemContext()
  return (
    <ReachAccordionPanel
      as={FadeIn}
      open={isExpanded}
      duration={duration}
      {...props}
    >
      <Box {...props}>{children}</Box>
    </ReachAccordionPanel>
  )
}

Accordion.Button = AccordionButton
Accordion.Item = AccordionItem
Accordion.Panel = AccordionPanel
