import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.354 2.354a.5.5 0 00-.708-.708l-.717.718a.45.45 0 00-.429-.314h-8a.45.45 0 00-.45.45v2a.45.45 0 10.9 0V2.95h3v4.393l-5.304 5.303a.5.5 0 00.708.708L6.95 8.757v3.293H5.754a.45.45 0 100 .9h3.5a.45.45 0 000-.9H8.05V7.657l5.304-5.303zM8.05 6.243l3-3V2.95h-3v3.293z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgTextNone = styled(BaseComponent)`
  outline: none;
`
export default SvgTextNone
