import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.587L1.852 13H13.5a.5.5 0 00.5-.5V2.587zM.763 13.807l.062.073.03-.026c.195.094.414.146.645.146h12a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-.763-1.307l-.062-.073-.03.025A1.494 1.494 0 0013.5 1h-12A1.5 1.5 0 000 2.5v10c0 .56.307 1.05.763 1.307zM1 12.413L13.148 2H1.5a.5.5 0 00-.5.5v9.913z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgViewNone = styled(BaseComponent)`
  outline: none;
`
export default SvgViewNone
