import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107 107" {...props}>
      <path
        fill="currentColor"
        d="M88.2 39.2H82V19.9l-3.4 2.7c-4.6 3.7-18.2 13.2-26 13.2H21c-1.2 0-2.2 1-2.2 2.2v27.1c0 1.2 1 2.2 2.2 2.2h10L16.4 84.6H37l13.4-17.3h2.2c7.8 0 21.4 9.5 26 13.2l3.4 2.7V63.9h6.2V39.2zM50.5 63.1H23V40h27.5v23.1zM84 59.7h-2V43.4h2v16.3z"
      />
    </Svg>
  )
}

const SvgMegaphone = styled(BaseComponent)`
  outline: none;
`
export default SvgMegaphone
