import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 68 68"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M9.58 50.33c.1-3.45 2.84-6.75 7.4-8.68a11.74 11.74 0 0017.85.3c4.18 1.97 6.65 5.1 6.75 8.38v6.83h-32v-6.83z"
          fill="gray"
          stroke="gray"
          strokeWidth={2.55}
          data-name="mask1 7 1508"
          id="person-one_svg__a"
        />
      </defs>
      <defs>
        <mask
          id="person-one_svg__b"
          x={8.98}
          y={16.48}
          width={36.72}
          height={46.58}
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx={25.77}
            cy={30.22}
            r={8.81}
            fill="gray"
            stroke="gray"
            strokeWidth={2.55}
            data-name="mask0 7 1508"
          />
        </mask>
        <mask
          id="person-one_svg__c"
          x={8.3}
          y={16.48}
          width={39.46}
          height={46.58}
          maskUnits="userSpaceOnUse"
        >
          <use
            strokeWidth={2.55}
            data-name="mask1 7 1508"
            xlinkHref="#person-one_svg__a"
          />
        </mask>
        <mask
          id="person-one_svg__d"
          x={0.63}
          y={48.22}
          width={30.64}
          height={16.6}
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M.63 51.18a2.96 2.96 0 012.96-2.96h24.72a2.96 2.96 0 012.96 2.96v10.68a2.96 2.96 0 01-2.96 2.95H3.6a2.96 2.96 0 01-2.96-2.95z"
            fill="#329c7f"
            data-name="mask2 7 1508"
          />
        </mask>
      </defs>
      <use strokeWidth={2.55} xlinkHref="#person-one_svg__a" />
      <circle
        cx={25.77}
        cy={30.22}
        r={8.81}
        fill="gray"
        stroke="gray"
        strokeWidth={2.55}
      />
      <g mask="url(#person-one_svg__b)">
        <path
          d="M45.7 45.73c-2.05-5.49-15.3-34.27-14.07-28.48 2.4 11.33-.34 21.96-8.23 23.68-3 15.1-11.07 16.75-14.42 17.33l3.1 4.8 23.67-2.4z"
          fill="#414141"
        />
      </g>
      <g mask="url(#person-one_svg__c)">
        <path
          d="M47.76 45.73c-2.06-5.49-15.3-34.27-14.07-28.48 2.4 11.33-.34 21.96-8.24 23.68-2.99 15.1-11.07 16.75-14.41 17.33l3.09 4.8h30.54z"
          fill="#414141"
        />
      </g>
      <path
        d="M.63 51.18a2.96 2.96 0 012.96-2.96h24.72a2.96 2.96 0 012.96 2.96v10.68a2.96 2.96 0 01-2.96 2.95H3.6a2.96 2.96 0 01-2.96-2.95z"
        fill="#5bb099"
      />
      <g mask="url(#person-one_svg__d)">
        <path
          d="M17.82 61.66c9.71-3.75 13.45-13.44 13.45-13.44v16.6H.63s12.01-1.16 17.19-3.16z"
          fill="#329c7f"
        />
      </g>
      <circle cx={6.38} cy={56.52} r={1.91} fill="#fff" />
      <circle cx={12.77} cy={56.52} r={1.91} fill="#fff" />
      <circle cx={19.15} cy={56.52} r={1.91} fill="#fff" />
      <circle cx={25.53} cy={56.52} r={1.91} fill="#fff" />
      <path
        d="M47.23 32.89A12.13 12.13 0 0135.1 20.76h-7.66a19.79 19.79 0 0019.8 19.79zm12.13-12.13A12.13 12.13 0 0147.23 32.9v7.66a19.79 19.79 0 0019.79-19.79zM47.23 8.63a12.13 12.13 0 0112.13 12.13h7.66A19.79 19.79 0 0047.23.97zm0-7.66a19.79 19.79 0 00-19.79 19.8h7.66A12.13 12.13 0 0147.23 8.62z"
        fill="#f3f1ee"
      />
      <path
        d="M31.27 20.76A15.96 15.96 0 0147.23 4.8 15.96 15.96 0 0163.2 20.76a15.96 15.96 0 01-15.96 15.96 15.96 15.96 0 01-15.96-15.96z"
        fill="#5bb099"
      />
      <path
        d="M46.74 24.63a.14.14 0 00.15.15h1.26a.14.14 0 00.15-.15V14.52a.14.14 0 00-.15-.16H47a.46.46 0 00-.28.1l-1.33.98c-.12.09-.15.15-.15.26V17c0 .12.09.15.16.1l1.3-.93h.04z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgPersonOne = styled(BaseComponent)`
  outline: none;
`
export default SvgPersonOne
