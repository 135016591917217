import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1.75H1V.25h13v1.5z"
        fill="currentColor"
      />
      <rect
        x={8}
        y={10}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 8 10)"
        fill="currentColor"
      />
      <rect
        x={2}
        y={10}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 2 10)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={12}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 8 12)"
        fill="currentColor"
      />
      <rect
        x={2}
        y={12}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 2 12)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={8}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 8 8)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={14}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 8 14)"
        fill="currentColor"
      />
      <rect
        x={2}
        y={8}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 2 8)"
        fill="currentColor"
      />
      <rect
        x={2}
        y={14}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 2 14)"
        fill="currentColor"
      />
      <rect
        x={10}
        y={8}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 10 8)"
        fill="currentColor"
      />
      <rect
        x={10}
        y={14}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 10 14)"
        fill="currentColor"
      />
      <rect
        x={12}
        y={8}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 12 8)"
        fill="currentColor"
      />
      <rect
        x={12}
        y={14}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 12 14)"
        fill="currentColor"
      />
      <rect
        x={6}
        y={8}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 6 8)"
        fill="currentColor"
      />
      <rect
        x={6}
        y={14}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 6 14)"
        fill="currentColor"
      />
      <rect
        x={4}
        y={8}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 4 8)"
        fill="currentColor"
      />
      <rect
        x={4}
        y={14}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 4 14)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={6}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 8 6)"
        fill="currentColor"
      />
      <rect
        x={2}
        y={6}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 2 6)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={4}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 8 4)"
        fill="currentColor"
      />
      <rect
        x={2}
        y={4}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 2 4)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={10}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 14 10)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={12}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 14 12)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={8}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 14 8)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={14}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 14 14)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={6}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 14 6)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={4}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(-180 14 4)"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBorderTop = styled(BaseComponent)`
  outline: none;
`
export default SvgBorderTop
