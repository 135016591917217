import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0a.5.5 0 01.5.5v1.307A5.624 5.624 0 0113.007 7H14.5a.5.5 0 010 1h-1.511A5.625 5.625 0 018 12.989V14.5a.5.5 0 01-1 0v-1.493A5.624 5.624 0 011.807 8H.5a.5.5 0 010-1h1.289A5.624 5.624 0 017 1.789V.5a.5.5 0 01.5-.5zM8 12.032V9.5a.5.5 0 00-1 0v2.554A4.675 4.675 0 012.763 8H5.5a.5.5 0 000-1H2.742A4.674 4.674 0 017 2.742V5.5a.5.5 0 001 0V2.763A4.675 4.675 0 0112.054 7H9.5a.5.5 0 000 1h2.532A4.675 4.675 0 018 12.032z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCrosshair2 = styled(BaseComponent)`
  outline: none;
`
export default SvgCrosshair2
