import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 9h7L7.5 4.5 4 9z" fill="currentColor" />
    </Svg>
  )
}

const SvgTriangleUp = styled(BaseComponent)`
  outline: none;
`
export default SvgTriangleUp
