import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" {...props}>
      <path
        d="M400 0H48A48 48 0 000 48v352a48 48 0 0048 48h137.2V295.7h-63V224h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V224h68.8l-11 71.7h-57.8V448H400a48 48 0 0048-48V48a48 48 0 00-48-48z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFacebook = styled(BaseComponent)`
  outline: none;
`
export default SvgFacebook
