import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zm-.5.972A5.673 5.673 0 007 13.15V1.849zM8 13.15A5.673 5.673 0 008 1.849V13.15z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgHalf1 = styled(BaseComponent)`
  outline: none;
`
export default SvgHalf1
