import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={34}
      height={10}
      viewBox="0 0 34 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.249 4.198h32.945v1.604H.25V4.198z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.535.19L34 5.329 29.457 9.81l-1.082-.87L32.1 5.265 28.382.985 29.535.19z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgArrowRight = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowRight
