import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.364 24.555a.487.487 0 00-.487.487v7.745a1 1 0 01-1 1H11a1 1 0 00-1 1v23.339a1 1 0 001 1h32.11a1 1 0 001-1v-1.898a1 1 0 011-1h11.644a1 1 0 011 1v1.898a1 1 0 001 1h32.109a1 1 0 001-1V34.787a1 1 0 00-1-1H68.987a1 1 0 01-1-1v-7.745a.487.487 0 00-.488-.487H34.364zm6.335 6.847a1 1 0 011-1h18.466a1 1 0 011 1v.899a1 1 0 01-1 1H41.699a1 1 0 01-1-1v-.899z"
        fill="#fff"
      />
      <rect
        x={56.602}
        y={94.9}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#fff"
      />
      <rect
        x={77.556}
        y={94.9}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#fff"
      />
      <rect
        x={98.509}
        y={94.9}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#fff"
      />
      <rect
        x={87.491}
        y={93.826}
        width={19.491}
        height={12}
        rx={1}
        transform="rotate(-180 87.491 93.826)"
        fill="#fff"
      />
      <rect
        x={108.491}
        y={93.826}
        width={19.491}
        height={12}
        rx={1}
        transform="rotate(-180 108.491 93.826)"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.11 63.996a1 1 0 00-1-1H13.897a.487.487 0 01-.487-.487.946.946 0 00-.945-.946H11a1 1 0 00-1 1v30a1 1 0 001 1h56.026a1.006 1.006 0 01-.026-.226V81.614a1 1 0 011-1h9V67.892a1 1 0 011-1h13.863v-4.329a1 1 0 00-1-1h-.978a.946.946 0 00-.946.946c0 .269-.218.487-.487.487H58.753a1 1 0 00-1 1v4.209a1 1 0 01-1 1H45.11a1 1 0 01-1-1v-4.21z"
        fill="#fff"
      />
      <rect
        x={78.232}
        y={68}
        width={19.491}
        height={12.669}
        rx={1}
        fill="#fff"
      />
    </Svg>
  )
}

const SvgBagWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgBagWhite
