import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85 7.5a5.65 5.65 0 1111.3 0 5.65 5.65 0 01-11.3 0zM7.5.85a6.65 6.65 0 100 13.3 6.65 6.65 0 000-13.3zM7 8V3.128A4.4 4.4 0 0111.872 8H7z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPieChart = styled(BaseComponent)`
  outline: none;
`
export default SvgPieChart
