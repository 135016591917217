import { useEffect, useRef, useState } from 'react'

import ResizeObserver from 'resize-observer-polyfill'

import { isBrowser } from '../utils/misc'

const RESIZE_EVENT = 'resize'
const SCROLL_EVENT = 'scroll'

const createPortal = (): HTMLDivElement | undefined =>
  isBrowser ? document.createElement('div') : undefined

export const usePortal = (id: string) => {
  const portalElementRef = useRef<HTMLDivElement | undefined>(createPortal())
  // TODO: once we introduce forwardRef we should get rid of this element-id based approach
  const buttonElementRef = useRef<Element | null>(
    isBrowser ? document.querySelector(`#${id}`) : null
  )
  const [domRect, setDomRect] = useState<DOMRect | undefined>(undefined)

  useEffect(() => {
    const handlerResize = () => {
      buttonElementRef.current = document.querySelector(`#${id}`)
      if (buttonElementRef.current) {
        const {
          left,
          top,
          right,
          bottom,
        } = buttonElementRef.current.getBoundingClientRect()
        const updatedRect = new DOMRect(left, top, right - left, bottom - top)
        setDomRect(updatedRect)
      }
    }

    const root = portalElementRef.current

    if (!isBrowser || !root) {
      return
    }

    const element = document.querySelector(`#${id}`)
    if (!element) {
      throw new Error(`Can't find element with ID=${id}`)
    }
    const resizeObserver = new ResizeObserver(handlerResize)

    resizeObserver.observe(element)

    document.querySelector('body')?.append(root)

    // do we need both ResizeObserver and resize event? Yes
    // 1) position of element can change wo resize (for example due to layout adjust)
    // 2) element won't change the size on resize element
    // => we need to adjust the position of menu in both cases
    window.addEventListener(RESIZE_EVENT, handlerResize, false)
    window.addEventListener(SCROLL_EVENT, handlerResize, false)

    return () => {
      window.removeEventListener(RESIZE_EVENT, handlerResize)
      window.addEventListener(SCROLL_EVENT, handlerResize)
      root.remove()
      resizeObserver.unobserve(element)
    }
  }, [id])

  return [portalElementRef, domRect, buttonElementRef] as const
}
