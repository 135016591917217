import styled from 'styled-components'
import { system, ColorProps, TypographyProps, variant } from 'styled-system'

import { Box, BoxProps } from '../Box'

const MEDIUM = 500
const BOLD = 'bold'
const UPPERCASE = 'uppercase'

const textTransform = system({
  textTransform: true,
})

const variants = {
  // 24, 24, 24
  BodyLarge: {
    fontSize: 8,
    lineHeight: 1.4,
  },
  // 24, 24, 24
  BodyLargeMedium: {
    fontSize: 8,
    fontWeight: MEDIUM,
    lineHeight: 1.4,
  },
  // 20, 20, 20
  BodyMiddle: {
    fontSize: 7,
    lineHeight: 1.4,
  },
  BodyMiddleLP: {
    fontSize: [4, 7, 7],
    lineHeight: 1.4,
  },
  // 20, 20, 20
  BodyMiddleMedium: {
    fontSize: 7,
    fontWeight: MEDIUM,
    lineHeight: 1.4,
  },
  // 16, 16, 16
  BodySmall: {
    fontSize: 5,
    lineHeight: 1.4,
  },
  // 16, 16, 16
  BodySmallMedium: {
    fontSize: 5,
    fontWeight: MEDIUM,
    lineHeight: 1.4,
  },
  // 16, 16, 16
  BodySmallBold: {
    fontSize: 5,
    fontWeight: BOLD,
    lineHeight: 1.4,
  },
  // 16, 16, 16
  BodySmallCaps: {
    fontSize: 5,
    textTransform: UPPERCASE,
    lineHeight: 1.4,
  },
  // 14, 14, 14
  BodyExtraSmall: {
    fontSize: 4,
    lineHeight: 1.4,
  },
  // 14, 14, 14
  BodyExtraSmallBold: {
    fontSize: 4,
    fontWeight: BOLD,
    lineHeight: 1.4,
  },
  // 14, 14, 14
  BodyExtraSmallCaps: {
    fontSize: 4,
    fontWeight: BOLD,
    lineHeight: 1.4,
    textTransform: UPPERCASE,
  },
  // 12, 12, 12
  BodyExtraExtraSmall: {
    fontSize: 3,
    lineHeight: 1.4,
  },
  // 12, 12, 12
  BodyExtraExtraSmallMedium: {
    fontSize: 3,
    fontWeight: MEDIUM,
    lineHeight: 1.4,
  },
  // 12, 12, 12
  BodyExtraExtraSmallBold: {
    fontSize: 3,
    fontWeight: BOLD,
    lineHeight: 1.4,
  },
  // 12, 12, 12
  BodyExtraExtraSmallCaps: {
    fontSize: 3,
    textTransform: UPPERCASE,
    lineHeight: 1.4,
  },
  // 12, 12, 12
  Label: {
    fontSize: 3,
    fontWeight: BOLD,
    lineHeight: 1.4,
  },
  // 10, 10, 10
  LabelSmall: {
    fontSize: 2,
    fontWeight: BOLD,
    lineHeight: 1.4,
  },
  // 20, 20, 20
  ButtonExtraExtraLarge: {
    fontSize: 7,
    fontWeight: BOLD,
    textTransform: UPPERCASE,
  },
  // 18, 18, 18
  ButtonExtraLarge: {
    fontSize: 6,
    fontWeight: BOLD,
    textTransform: UPPERCASE,
  },
  // 16, 16, 16
  ButtonLarge: {
    fontSize: 5,
    fontWeight: BOLD,
    textTransform: UPPERCASE,
  },
  // 14, 14, 14
  ButtonMiddle: {
    fontSize: 4,
    fontWeight: BOLD,
    textTransform: UPPERCASE,
  },
  // 16, 16, 16
  ButtonLandingPage: {
    fontSize: 5,
    fontWeight: BOLD,
    lineHeight: 1.13,
  },
  // 12, 12, 12
  ButtonSmall: {
    fontSize: 3,
    fontWeight: BOLD,
    textTransform: UPPERCASE,
  },
  // 8, 8, 8
  ButtonExtraSmall: {
    fontSize: 0,
    fontWeight: BOLD,
    textTransform: UPPERCASE,
  },
  // 16, 18, 18
  LinkLarge: {
    fontSize: [5, 6, 6, 6],
  },
  // 14, 16, 16
  Link: {
    fontSize: [4, 5, 5, 5],
  },
  // 14, 16, 16
  Field: {
    fontSize: [4, 5, 5, 5],
  },
  // 10, 10, 10
  FieldSmall: {
    fontSize: 2,
  },
}

const typographyVariants = variant({
  variants,
})

export type TextProps = {
  children?: React.ReactNode
  as?: React.ElementType | undefined
  textTransform?: string
  variant?: keyof typeof variants
} & ColorProps &
  TypographyProps &
  BoxProps

const Text = styled(Box)<TextProps>`
  white-space: break-spaces;
  ${textTransform}
  ${typographyVariants}
`

export default Text
