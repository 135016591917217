import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 1.1a.4.4 0 100 .8h8a.4.4 0 000-.8h-8zM5 4.25a.5.5 0 00-1 0v5.3a3.5 3.5 0 007 0v-5.3a.5.5 0 00-1 0v5.3a2.5 2.5 0 01-5 0v-5.3z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgOverline = styled(BaseComponent)`
  outline: none;
`
export default SvgOverline
