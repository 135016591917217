import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4.1a.4.4 0 100 .8h10a.4.4 0 000-.8h-10zm0 2a.4.4 0 100 .8h10a.4.4 0 000-.8h-10zm-.4 2.4c0-.22.18-.4.4-.4h10a.4.4 0 010 .8h-10a.4.4 0 01-.4-.4zm.4 1.6a.4.4 0 000 .8h10a.4.4 0 000-.8h-10z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDragHandleHorizontal = styled(BaseComponent)`
  outline: none;
`
export default SvgDragHandleHorizontal
