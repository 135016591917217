import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={52.731}
        y={71.608}
        width={15}
        height={42}
        rx={1.5}
        transform="rotate(21.036 52.731 71.608)"
        fill="#fff"
      />
      <rect
        x={42.499}
        y={56.424}
        width={45}
        height={12}
        rx={1.5}
        transform="rotate(21.036 42.499 56.424)"
        fill="#fff"
      />
      <rect
        x={47.345}
        y={43.823}
        width={45}
        height={12}
        rx={1.5}
        transform="rotate(21.036 47.345 43.823)"
        fill="#fff"
      />
      <rect
        x={52.19}
        y={31.223}
        width={45}
        height={12}
        rx={1.5}
        transform="rotate(21.036 52.19 31.223)"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.396 45.915a19.44 19.44 0 003.284-5.566c3.791-9.859-.97-20.864-10.636-24.581-9.665-3.717-20.574 1.261-24.365 11.12a19.437 19.437 0 00-1.292 6.332l33.01 12.695z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgMicrophoneWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgMicrophoneWhite
