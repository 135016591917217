import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8 27.7c.1-3 2.6-5.7 6.2-7h.3A7.3 7.3 0 0026 21c0-.2.2-.2.4-.1 3.3 1.4 5.5 4 5.6 6.8v5.4c0 .2-.1.3-.3.3H8.1a.3.3 0 01-.3-.3v-5.4z"
        fill="currentColor"
      />
      <circle cx={20.1} cy={13.6} r={7.1} fill="currentColor" />
    </Svg>
  )
}

const SvgPersonSolid = styled(BaseComponent)`
  outline: none;
`
export default SvgPersonSolid
