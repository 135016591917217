import React from 'react'

import { ArrowLeft } from '@linus-capital/icons'
import css from '@styled-system/css'
import styled from 'styled-components'

import { Box } from '../../atoms/Box'
import { Button } from '../../atoms/Button'
import { Flex } from '../../atoms/Flex'
import { Text } from '../../atoms/Typography'
import { Hide } from '../../molecules/Hide'
import { PageContainer } from '../../molecules/PageContainer'
import { grid } from '../../utils/constants'
import { LanguageSelector, LanguageSelectorProps } from '../LanguageSelector'

const Main = styled(Flex)(
  css({
    bg: 'sand.0',
    justifyContent: 'space-between',
    fontSize: [2, 2, 3, 4],
    alignItems: 'center',
    height: [40, 40, 50, 72],
    width: ['100%', 308, 372, 491],
  })
)

const Link = styled(Text)`
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
`

const Divider = styled(Box)(
  css({
    height: '100%',
    width: 2,
    backgroundColor: 'white',
    opacity: 0.5,
  })
)

// TODO
export type Props = {
  imprintLink: string
  termLink: string
  imprintText: string
  termsText: string
  backButtonText: string
  showLanguageSelector?: boolean
  onBackButtonClick: () => void
  withBackButton?: boolean
} & LanguageSelectorProps

export const Footer = ({
  imprintText,
  imprintLink,
  termsText,
  termLink,
  onChange,
  language,
  backButtonText,
  onBackButtonClick,
  withBackButton = true,
  showLanguageSelector = true,
}: Props) => (
  <Flex as="footer" mt={[5, 6]}>
    <PageContainer width="100%">
      <Flex
        px={grid[1]}
        alignItems="center"
        justifyContent={[
          'center',
          'flex-end',
          withBackButton ? 'space-between' : 'flex-end',
        ]}
      >
        <Hide sm md>
          {withBackButton && (
            <Button
              variant="transparent"
              icon={<ArrowLeft />}
              onClick={onBackButtonClick}
              p={0}
            >
              {backButtonText}
            </Button>
          )}
        </Hide>
        <Main>
          <Flex flex={1} justifyContent="space-evenly" alignItems="center">
            <Link
              as="a"
              target="_blank"
              href={imprintLink}
              rel="noopener noreferrer"
            >
              {imprintText}
            </Link>
            <Link
              as="a"
              target="_blank"
              href={termLink}
              rel="noopener noreferrer"
            >
              {termsText}
            </Link>
          </Flex>
          {showLanguageSelector && (
            <>
              <Divider />
              <Flex
                width={[65, 65, 65, 126]}
                pl={2}
                alignItems="center"
                justifyContent="center"
              >
                <LanguageSelector onChange={onChange} language={language} />
              </Flex>
            </>
          )}
        </Main>
      </Flex>
    </PageContainer>
  </Flex>
)
