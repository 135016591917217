import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.537.827a.375.375 0 00-.074 0l-3.5.35a.375.375 0 00-.266.152L.7 5.425a.373.373 0 00.012.465l6.498 7.898a.375.375 0 00.58 0l6.498-7.898a.374.374 0 00.087-.238V5.65v-.005-.017a.373.373 0 00-.075-.203L11.303 1.33a.375.375 0 00-.266-.152l-3.5-.35zm3.388 4.448v-.023l-.003.023h.003zm.01-.1h2.253l-1.939-2.649-.315 2.649zm-.364-3.291l-2.527-.253 2.13 3.58.397-3.327zm-3.615-.253l-2.527.253.396 3.326 2.13-3.579zm-3.206.895L1.812 5.175h2.254L3.75 2.526zM1.794 6.025l4.965 6.034-2.535-5.992a.301.301 0 01-.015-.042H1.794zm3.357 0L7.5 12.108l2.35-6.083H5.15zm5.64 0a.3.3 0 01-.015.042l-2.535 5.992 4.965-6.034H10.79zM7.5 2.183l1.84 3.092H5.66L7.5 2.183z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSketchLogo = styled(BaseComponent)`
  outline: none;
`
export default SvgSketchLogo
