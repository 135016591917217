import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3.05a.45.45 0 01.45.45v4a.45.45 0 01-.9 0V4.586L4.586 11.05H7.5a.45.45 0 010 .9h-4a.45.45 0 01-.45-.45v-4a.45.45 0 11.9 0v2.914l6.464-6.464H7.5a.45.45 0 110-.9h4z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSize = styled(BaseComponent)`
  outline: none;
`
export default SvgSize
