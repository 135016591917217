import { useEffect, useRef } from 'react'

const TEST_EVENT = 'test'

export const useCaptureOptions = () => {
  const supportsCaptureOption = useRef(false)

  useEffect(() => {
    const dummyListener: EventListener = () => {}
    try {
      window.addEventListener(
        TEST_EVENT,
        dummyListener,
        Object.defineProperty({}, 'capture', {
          get: function get() {
            supportsCaptureOption.current = true
            return 'ok'
          },
        })
      )
      window.removeEventListener(TEST_EVENT, dummyListener)
    } catch (e) {} // eslint-disable-line no-empty
  }, [])

  return supportsCaptureOption.current
}
