import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V4.707L9.293 2H3.5zM2 2.5A1.5 1.5 0 013.5 1h6a.5.5 0 01.354.146l2.926 2.927c.141.14.22.332.22.53V12.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 012 12.5v-10zm2.75 5a.5.5 0 01.5-.5H7V5.25a.5.5 0 011 0V7h1.75a.5.5 0 010 1H8v1.75a.5.5 0 01-1 0V8H5.25a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFilePlus = styled(BaseComponent)`
  outline: none;
`
export default SvgFilePlus
