import * as React from 'react'

import { Linus } from '@linus-capital/icons'

import { Box } from '../../atoms/Box'
import { IconButton } from '../../atoms/Button'
import { Flex } from '../../atoms/Flex'
import { PageContainer } from '../../molecules/PageContainer'
import { grid } from '../../utils/constants'
import { WithChildren } from '../../utils/types'

export type Props = WithChildren<{
  onLogoClick?: () => void
}>

const Header = ({ onLogoClick, children }: Props) => (
  <Box as="header" boxShadow={0} bg="white">
    <PageContainer>
      <Flex justifyContent="space-between" alignItems="center" px={grid[1]}>
        <Flex mr={4} flexShrink={0}>
          {onLogoClick && (
            <IconButton
              size="small"
              onClick={onLogoClick}
              icon={<Linus size={[57, 78]} />}
            />
          )}
          {!onLogoClick && <Linus size={[57, 78]} />}
        </Flex>
        <Box flex="1 1 auto">{children}</Box>
      </Flex>
    </PageContainer>
  </Box>
)

export default Header
