import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.443.435A1.438 1.438 0 012.45 0a1.166 1.166 0 01.833.338l1.314 1.31A1.309 1.309 0 014.5 3.49l-.35.35a.211.211 0 00-.015.282 26.458 26.458 0 003.744 3.742.212.212 0 00.283-.014l.351-.35a1.438 1.438 0 011.015-.432 1.17 1.17 0 01.833.338l1.305 1.306a1.308 1.308 0 01-.097 1.843l-.798.798A2.159 2.159 0 019.259 12a1.789 1.789 0 01-.96-.27A25.908 25.908 0 01.265 3.7 2.011 2.011 0 01.642 1.23l.801-.795z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPhone = styled(BaseComponent)`
  outline: none;
`
export default SvgPhone
