import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.739a.25.25 0 01-.403.197L1.004 1.697a.25.25 0 010-.394L2.597.063A.25.25 0 013 .262v.74h6V.26a.25.25 0 01.404-.197l1.592 1.239a.25.25 0 010 .394l-1.592 1.24A.25.25 0 019 2.738V2H3v.739zM9.5 5h-7a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5zm-7-1A1.5 1.5 0 001 5.5v7A1.5 1.5 0 002.5 14h7a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 009.5 4h-7zm12.239 2H14v6h.739a.25.25 0 01.197.403l-1.239 1.593a.25.25 0 01-.394 0l-1.24-1.593a.25.25 0 01.198-.403H13V6h-.739a.25.25 0 01-.197-.403l1.239-1.593a.25.25 0 01.394 0l1.24 1.593a.25.25 0 01-.198.403z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDimensions = styled(BaseComponent)`
  outline: none;
`
export default SvgDimensions
