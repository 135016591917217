import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.146 6.854a.5.5 0 00.708-.708l-4-4a.5.5 0 00-.708 0l-4 4a.5.5 0 10.708.708L7.5 3.207l3.646 3.647zm0 6a.5.5 0 00.708-.708l-4-4a.5.5 0 00-.708 0l-4 4a.5.5 0 00.708.708L7.5 9.207l3.646 3.647z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDoubleArrowUp = styled(BaseComponent)`
  outline: none;
`
export default SvgDoubleArrowUp
