import React from 'react'

import styled from 'styled-components'
import { system, ResponsiveValue, TLengthStyledSystem } from 'styled-system'

import { Flex, Props as FlexProps } from '../../atoms/Flex'
import { omit } from '../../utils/misc'

type SpaceValue = number

type DirectionValue = 'vertical' | 'horizontal'

type StackBaseProps = Omit<FlexProps, 'wrap'> & {
  /**
   * Spacing (gap) between items.
   */
  gap?: ResponsiveValue<SpaceValue>

  /**
   * In which direction should the children be distributed
   */
  direction?: ResponsiveValue<DirectionValue>

  /**
   * Whether to wrap elements if there is no room to fit them
   */
  wrap?: ResponsiveValue<boolean>
}

export type StackProps = StackBaseProps & {
  as?: React.ElementType
}

const toPx = (value: TLengthStyledSystem): string =>
  typeof value === 'number' ? `${value}px` : value

const StackBase = styled((props: StackProps) => (
  <Flex {...omit(props, ['gap', 'direction', 'wrap'])} />
))<StackBaseProps>(
  {},
  system({
    gap: {
      // Using `&&` as property means we inject styles to the root element as an object
      // By using double && we increase specificity of this CSS selector
      property: '&&' as any,
      scale: 'space',
      // @ts-ignore solves the undefined is not assignable to string[]
      transform: (value: SpaceValue, scale: Theme['space']) => {
        const gapValue = toPx(scale[value])
        // And here instead of the value for the property we return an object
        return {
          '--gap': gapValue,
          position: 'relative',
          width: `calc(100% + var(--gap))`,
          marginTop: `calc(-1 * var(--gap))`,
          marginLeft: `var(--root-ml)`,
          '> *': {
            marginTop: `var(--gap)`,
            marginLeft: `var(--child-ml)`,
          },
        }
      },
    },
    wrap: {
      property: 'flexWrap',
      transform: (value: boolean) => (value ? 'wrap' : 'nowrap'),
    },
  }),
  // Because we are using `property: "&&" in both cases, putting them into one `system` call
  // will produce conflicts and break for the case when both `gap` and `direction` are arrays.
  system({
    direction: {
      property: '&&' as any,
      transform: (value: DirectionValue) => ({
        '--root-ml': value === 'vertical' ? 0 : 'calc(-1 * var(--gap))',
        '--child-ml': value === 'vertical' ? 0 : 'var(--gap)',
        flexDirection: value === 'vertical' ? 'column' : 'row',
      }),
    },
  })
)

/**
 Use Stack to distribute children evenly with a specified gap between.
 */
const Stack = ({
  as,
  children,
  gap,
  direction,
  wrap,
  alignItems,
  alignContent,
  justifyItems,
  justifyContent,
  ...props
}: StackProps) => (
  // We have to use forwardedAs in order for Section to accept `as` prop.
  // See https://github.com/styled-components/styled-components/issues/3268
  <Flex forwardedAs={as} {...props}>
    <StackBase
      gap={gap}
      direction={direction}
      wrap={wrap}
      alignItems={alignItems}
      alignContent={alignContent}
      justifyItems={justifyItems}
      justifyContent={justifyContent}
    >
      {children}
    </StackBase>
  </Flex>
)

Stack.defaultProps = {
  direction: 'vertical',
  wrap: false,
}

export default Stack
