import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.004 94.69a.004.004 0 00.004-.004c.292-9.93 8.373-18.553 20.233-23.125.425-.164.906-.01 1.177.357 4.376 5.922 11.409 9.763 19.338 9.763 7.645 0 14.456-3.57 18.858-9.134.284-.36.771-.497 1.192-.316 10.956 4.713 18.308 12.988 18.589 22.45 0 .005.003.009.008.009.004 0 .008.003.008.008v17.677a1 1 0 01-1 1H25a1 1 0 01-1-1V94.693c0-.001.002-.003.004-.003zM65.262 14a29.499 29.499 0 00-28.357 21.368 1.023 1.023 0 01-.978.747h-.028a1 1 0 00-1 1v22.618a1 1 0 001 1h6.207a1 1 0 001-1V37.115a1 1 0 00-1-1h-2.483c-.66 0-1.139-.629-.935-1.256A27.945 27.945 0 0185.02 23.741a27.944 27.944 0 016.815 11.118c.204.627-.275 1.256-.935 1.256H88.42a1 1 0 00-1 1v22.618a1 1 0 001 1h.501a1 1 0 011 1v3.43a1 1 0 01-1 1h-7.29a.779.779 0 100 1.556h9.069c.43 0 .778-.348.778-.778v-5.208a1 1 0 011-1h2.148a1 1 0 001-1V37.115a1 1 0 00-1-1h-.028c-.456 0-.852-.31-.978-.747A29.505 29.505 0 0076.55 16.246 29.499 29.499 0 0065.261 14z"
        fill="#fff"
      />
      <rect
        x={66.472}
        y={64.322}
        width={13.993}
        height={9.069}
        rx={0.611}
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.779}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.596 63.007c.646.063 1.479.115 1.853-.416a20.924 20.924 0 003.834-12.106c0-11.609-9.411-21.02-21.02-21.02-11.61 0-21.02 9.411-21.02 21.02 0 11.182 8.73 20.324 19.745 20.983A.964.964 0 0065 70.492v-5.935c0-.86.697-1.557 1.557-1.557h13.886c.051 0 .102.002.153.007z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgCallWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgCallWhite
