import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1a.5.5 0 01.407.21l5 7A.5.5 0 0112.5 9H10v2.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V9H2.5a.5.5 0 01-.407-.79l5-7A.5.5 0 017.5 1zM3.472 8H5.5a.5.5 0 01.5.5V11h3V8.5a.5.5 0 01.5-.5h2.028L7.5 2.36 3.472 8z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgThickArrowUp = styled(BaseComponent)`
  outline: none;
`
export default SvgThickArrowUp
