import styled from 'styled-components'
import { flexbox, FlexboxProps } from 'styled-system'

import { Box, BoxProps } from '../Box'

export type Props = FlexboxProps & BoxProps

const Flex = styled(Box)<Props>({ display: 'flex' }, flexbox)

export default Flex
