import React, { useMemo } from 'react'

import { css } from '@styled-system/css'
import styled from 'styled-components'
import { FlexboxProps } from 'styled-system'

import { Box, BoxProps } from '../../atoms/Box'
import { Flex } from '../../atoms/Flex'
import { H7 } from '../../atoms/Typography'
import { extractInitialsFromName } from '../../utils/misc'
import { OptimizedImage } from '../OptimizedImage'

type AvatarImageProps = {
  src?: string
  alt?: string
}

export type Props = {
  size: number
  name?: string
  initials?: string | ((name: string) => string)
} & AvatarImageProps &
  Omit<BoxProps, 'children'> &
  FlexboxProps

const StyledFlex = styled(Flex)(() =>
  css({
    borderRadius: '50%',
    backgroundColor: 'secondary',
    alignItems: 'center',
    justifyContent: 'center',
  })
)

const ImageWrapper = styled(Box)(
  css({
    height: 50,
    width: 50,
    minWidth: 50,
    marginRight: 4,
    '> img': {
      borderRadius: '50%',
      objectFit: 'cover',
      maxWidth: '100%',
    },
  })
)

const Avatar = ({
  src,
  alt,
  name,
  initials = extractInitialsFromName,
  size,
  ...rest
}: Props) => {
  const text = useMemo(
    () => (typeof initials === 'string' ? initials : initials(name ?? '')),
    [initials, name]
  )

  if (src) {
    return (
      <ImageWrapper>
        <OptimizedImage
          alt={alt ?? ''}
          imageUrl={src}
          transformation={[{ width: 400, height: 400 }]}
          loading="lazy"
        />
      </ImageWrapper>
    )
  }
  return (
    <StyledFlex width={size} height={size} title={alt || name} {...rest}>
      <H7 color="white" pt="0.2em">
        {text}
      </H7>
    </StyledFlex>
  )
}

export default Avatar
