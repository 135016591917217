import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.891 2.194a.5.5 0 01.115.697L2.474 12H13.5a.5.5 0 010 1h-12a.5.5 0 01-.406-.791l7.1-9.9a.5.5 0 01.697-.115zM11.1 6.5a.5.5 0 111 0 .5.5 0 01-1 0zM10.4 4a.5.5 0 100 1 .5.5 0 000-1zm1.7 4.5a.5.5 0 111 0 .5.5 0 01-1 0zm1.3 1.5a.5.5 0 100 1 .5.5 0 000-1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAngle = styled(BaseComponent)`
  outline: none;
`
export default SvgAngle
