import * as React from 'react'

import { Flex } from '../../atoms/Flex'
import { MergeElementProps } from '../../utils/types'

type Props = MergeElementProps<'label', {}>

export const Label = (props: Props) => (
  <Flex as="label" alignItems="center" {...props} width={'100%'} />
)

export default Label
