import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1.75a.5.5 0 01.47.33l3 8.32a.5.5 0 11-.94.34l-.982-2.724H8.952L7.97 10.74a.5.5 0 01-.94-.339l3-8.32a.5.5 0 01.47-.33zm0 1.974l1.241 3.442H9.26L10.5 3.724zM2.5 2.1c.22 0 .4.18.4.4v7.034l1.317-1.317a.4.4 0 01.565.566l-2 2a.4.4 0 01-.565 0l-2-2a.4.4 0 11.565-.566L2.1 9.534V2.5c0-.22.18-.4.4-.4zM.1 13.5a.4.4 0 01.4-.4h14a.4.4 0 110 .8H.5a.4.4 0 01-.4-.4z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAlignBaseline = styled(BaseComponent)`
  outline: none;
`
export default SvgAlignBaseline
