import React from 'react'

import { Box } from '../../atoms/Box'

export type ProgressBarProps = {
  progress?: number
  percentageColor?: string
  backgroundColor?: string
  height?: number
}

const ProgressBar = ({
  progress = 1,
  height = 9,
  percentageColor = 'success',
  ...props
}: ProgressBarProps) => (
  <Box maxWidth={'100%'} height={height} bg="secondary" {...props}>
    <Box width={progress / 100} height="inherit" bg={percentageColor} />
  </Box>
)

export default ProgressBar
