import React, { ReactNode, useEffect, useRef } from 'react'

import { Box, BoxProps } from '../../atoms/Box'
import { TextClamp } from '../../atoms/TextClamp'
import { TextProps } from '../../atoms/Typography'
import { useToggle } from '../../hooks'
import { FadeIn } from '../Transition'

export type Props = {
  text: string | ReactNode
  children: (props: { isOpen: boolean; onClick: () => void }) => ReactNode
  linesShownWhenClosed?: number
  textVariant?: TextProps['variant']
  duration?: number
  isOpen?: boolean
} & BoxProps

export const ShowMore = ({
  text,
  linesShownWhenClosed = 2,
  duration = 300,
  textVariant = 'BodyExtraExtraSmallMedium',
  children,
  ...props
}: Props) => {
  const { isOpen, handleToggle } = useToggle(false)
  const { isOpen: delayedValue, setValue: setDelayedValue } = useToggle(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const initialHeight = useRef('')

  const transitionStyles = {
    entering: {
      height: ref.current?.scrollHeight,
    },
    entered: { height: 'auto' },
    exiting: {
      height: ref.current?.scrollHeight,
    },
    exited: { height: initialHeight.current },
    unmounted: { height: 0 },
  }

  useEffect(() => {
    if (!initialHeight.current && ref.current?.clientHeight) {
      initialHeight.current = `${ref.current?.clientHeight}px`
    }
    const timerId = setTimeout(() => {
      setDelayedValue(isOpen)
    }, duration)
    return () => clearTimeout(timerId)
  }, [duration, isOpen, setDelayedValue])

  return (
    <Box p={4} bg="black.3" borderTop="thin" borderColor="earth.1" {...props}>
      <FadeIn
        open={isOpen && delayedValue}
        ref={ref}
        transitionStyles={transitionStyles}
        duration={duration}
      >
        <TextClamp
          linesShown={
            !delayedValue && !isOpen ? linesShownWhenClosed : Infinity
          }
          variant={textVariant}
        >
          {text}
        </TextClamp>
      </FadeIn>
      {children({ isOpen: delayedValue, onClick: handleToggle })}
    </Box>
  )
}
