import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 448.1" {...props}>
      <path
        d="M162.7 178L92.6 301.5c-4.9 8.3-10.8 12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1 0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18 12.2l44.7 77.5zM382.6 14.1l-144 253v.3L330.2 434c3.9 7.1.2 14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5L290.9 12.2C295.5 4.1 301.3 0 308.4 0h65.7c8 0 12.3 6.7 8.5 14.1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgXing = styled(BaseComponent)`
  outline: none;
`
export default SvgXing
