import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95 6H11v3H7.95V6zm0-1V1.5a.45.45 0 00-.9 0V5h-3.3a.75.75 0 00-.75.75v3.5c0 .414.336.75.75.75h3.3v3.5a.45.45 0 10.9 0V10h3.3a.75.75 0 00.75-.75v-3.5a.75.75 0 00-.75-.75h-3.3zm-.9 4H4V6h3.05v3z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgJustifyCenter = styled(BaseComponent)`
  outline: none;
`
export default SvgJustifyCenter
