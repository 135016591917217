import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.966 22.575a1.029 1.029 0 00-1.26-.728l-14.904 3.994a1.029 1.029 0 00-.727 1.26l8.254 30.803c.147.549.71.874 1.26.727l40.74-10.916c.548-.147.874-.711.727-1.26l-8.254-30.803a1.029 1.029 0 00-1.26-.727L73.63 18.651a1.029 1.029 0 00-.728 1.26l1.598 5.961a1.029 1.029 0 01-.728 1.26l-7.949 2.13a1.029 1.029 0 01-1.26-.727l-1.597-5.961z"
        fill="#4A4A48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.018 56.357a1.029 1.029 0 00-1.26-.727l-14.904 3.993a1.029 1.029 0 00-.727 1.26l9.052 33.785c.147.548.711.874 1.26.727l40.74-10.916c.549-.147.874-.711.727-1.26l-9.052-33.784a1.029 1.029 0 00-1.26-.728l-13.912 3.728a1.029 1.029 0 00-.727 1.26l1.54 5.746a1.029 1.029 0 01-.728 1.26l-7.95 2.13a1.029 1.029 0 01-1.26-.727l-1.539-5.747z"
        fill="#F3AD3D"
      />
      <rect
        x={64.714}
        y={96.656}
        width={50.407}
        height={6.172}
        rx={1.029}
        transform="rotate(-15 64.714 96.656)"
        fill="#329C7F"
      />
      <rect
        x={32.874}
        y={13.598}
        width={6.172}
        height={82.297}
        rx={1.029}
        transform="rotate(-15 32.874 13.598)"
        fill="#329C7F"
      />
      <rect
        x={38.835}
        y={12}
        width={6.172}
        height={26.746}
        rx={1.029}
        transform="rotate(75 38.835 12)"
        fill="#329C7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.981 112.298c6.311-1.691 10.057-8.178 8.366-14.49-1.691-6.31-8.178-10.056-14.49-8.365-6.31 1.692-10.056 8.178-8.364 14.489 1.69 6.311 8.177 10.057 14.488 8.366zm-2.129-7.949a3.6 3.6 0 10-1.862-6.955 3.6 3.6 0 001.862 6.955z"
        fill="#4A4A48"
      />
    </Svg>
  )
}

const SvgLogistics = styled(BaseComponent)`
  outline: none;
`
export default SvgLogistics
