import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.354 3.646a.5.5 0 010 .708L4.707 11H9a.5.5 0 010 1H3.5a.5.5 0 01-.5-.5V6a.5.5 0 011 0v4.293l6.646-6.647a.5.5 0 01.708 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgArrowBottomLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowBottomLeft
