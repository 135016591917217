import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.5a.5.5 0 01.5-.5h5.793L12 4.707V12.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-10zM3.5 1A1.5 1.5 0 002 2.5v10A1.5 1.5 0 003.5 14h8a1.5 1.5 0 001.5-1.5V4.604a.75.75 0 00-.22-.53L9.854 1.145A.5.5 0 009.5 1h-6zm1.75 6a.5.5 0 000 1h4.5a.5.5 0 000-1h-4.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFileMinus = styled(BaseComponent)`
  outline: none;
`
export default SvgFileMinus
