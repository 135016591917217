import { useCallback, useState } from 'react'

export const useToggle = (initialState = false) => {
  const [isOpen, setOpen] = useState<boolean>(initialState)
  const handleToggle = useCallback(() => {
    setOpen((currentState) => !currentState)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const setValue = useCallback((value: boolean) => {
    setOpen(value)
  }, [])
  return { isOpen, handleToggle, handleClose, setValue }
}
