import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 10a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm0 1a4.48 4.48 0 002.809-.984l2.837 2.838a.5.5 0 00.708-.708L10.016 9.31A4.5 4.5 0 106.5 11zM4.75 6a.5.5 0 000 1h3.5a.5.5 0 000-1h-3.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgZoomOut = styled(BaseComponent)`
  outline: none;
`
export default SvgZoomOut
