import * as React from 'react'

import Text, { TextProps } from './Text'

const Heading = (props: TextProps) => (
  <Text
    as="h3"
    fontWeight="400"
    fontFamily="heading"
    textTransform="uppercase"
    lineHeight={1}
    {...props}
  />
)

// mobile, tablet, desktop/large desktop
// 40, 60, 90
export const H1 = (props: TextProps) => (
  <Heading as="h1" fontSize={[10, 12, 12, 15]} fontWeight="400" {...props} />
)

// 30, 40, 60
export const H2 = (props: TextProps) => (
  <H1 as="h2" fontSize={[9, 10, 10, 12]} {...props} textTransform="uppercase" />
)

// 24, 30, 40
export const H3 = (props: TextProps) => (
  <Heading as="h3" fontSize={[8, 9, 9, 10]} {...props} />
)

// 20, 24, 30
export const H4 = (props: TextProps) => (
  <Heading as="h4" fontSize={[7, 8, 8, 9]} {...props} />
)

// 18, 20, 24
export const H5 = (props: TextProps) => (
  <Heading as="h5" fontSize={[6, 7, 7, 8]} {...props} />
)

// 16, 18, 20
export const H6 = (props: TextProps) => (
  <Heading as="h6" fontSize={[5, 6, 6, 7]} {...props} />
)

// 14, 16, 18
export const H7 = (props: TextProps) => (
  <Heading as="h6" fontSize={[4, 5, 5, 6]} {...props} />
)

// 12, 14, 18
export const H8 = (props: TextProps) => (
  <Heading as="h6" fontSize={[3, 4, 4, 5]} {...props} />
)
