import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2h13v11H1V2zM0 2a1 1 0 011-1h13a1 1 0 011 1v11a1 1 0 01-1 1H1a1 1 0 01-1-1V2zm4.875 5.5a2.625 2.625 0 115.25 0 2.625 2.625 0 01-5.25 0zM7.5 3.875a3.625 3.625 0 100 7.25 3.625 3.625 0 000-7.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgMaskOff = styled(BaseComponent)`
  outline: none;
`
export default SvgMaskOff
