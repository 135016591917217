import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5.75L9.75 3h-4.5L7.5.75zm0 13.5L9.75 12h-4.5l2.25 2.25zm-4.5-9L.75 7.5 3 9.75v-4.5zM14.25 7.5L12 5.25v4.5l2.25-2.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAllSides = styled(BaseComponent)`
  outline: none;
`
export default SvgAllSides
