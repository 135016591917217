import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 415.8" {...props}>
      <path
        d="M459.4 103.6c.3 4.6.3 9.1.3 13.7 0 138.7-105.6 298.6-298.6 298.6A297 297 0 010 368.7c8.4.9 16.9 1.3 25.3 1.3 47.2.1 93.1-15.7 130.3-44.8a105.1 105.1 0 01-98.1-72.8 107.2 107.2 0 0047.4-2c-49-9.9-84.2-53-84.1-103v-1.3a105.2 105.2 0 0047.4 13.3A105 105 0 0135.7 19.2 298.4 298.4 0 00252.1 129a105 105 0 01179-95.8c23.5-4.5 46-13.1 66.6-25.3a104.6 104.6 0 01-46.1 57.8A211 211 0 00512 49.5a229 229 0 01-52.6 54.1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgTwitter = styled(BaseComponent)`
  outline: none;
`
export default SvgTwitter
