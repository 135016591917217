import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M11 22C4.9 22 0 17.1 0 11S4.9 0 11 0s11 4.9 11 11-4.9 11-11 11zm5.9-11.9l-5.4-5.8A.5.5 0 0011 4l-.5.2L5.1 10c-.3.3-.3.8 0 1 .3.3.8.3 1.1 0l4.1-4.4v10.5c0 .4.3.7.7.7.4 0 .7-.3.7-.7V6.6l4.1 4.4c.3.3.7.3 1 0 .4-.2.4-.6.1-.9z"
      />
    </Svg>
  )
}

const SvgArrowUpCircle = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowUpCircle
