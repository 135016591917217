import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={34}
      height={10}
      viewBox="0 0 34 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.675 5.991H.804V4.388h32.87V5.99z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.455 10L0 4.861 4.532.379l1.08.87-3.717 3.676 3.71 4.28-1.15.795z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgArrowLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowLeft
