import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.123 12H3.5a.5.5 0 010-1h1.6c1.128 0 1.945 0 2.586-.053.637-.052 1.057-.152 1.403-.329a3.5 3.5 0 001.53-1.529c.176-.346.276-.766.328-1.403C11 7.045 11 6.228 11 5.1V3.5a.5.5 0 011 0v1.623c0 1.1 0 1.958-.056 2.645-.057.698-.175 1.265-.435 1.775a4.5 4.5 0 01-1.966 1.966c-.51.26-1.077.378-1.775.435C7.08 12 6.224 12 5.123 12z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCornerBottomRight = styled(BaseComponent)`
  outline: none;
`
export default SvgCornerBottomRight
