import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.597 2.936A.25.25 0 008 2.74V2c1.981 0 3.185.364 3.91 1.09C12.637 3.814 13 5.018 13 7a.5.5 0 001 0c0-2.056-.367-3.603-1.382-4.618C11.603 1.368 10.056 1 8 1V.261a.25.25 0 00-.403-.197L6.004 1.303a.25.25 0 000 .394l1.593 1.24zM9.5 5h-7a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5zm-7-1A1.5 1.5 0 001 5.5v7A1.5 1.5 0 002.5 14h7a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 009.5 4h-7z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgRotateCounterClockwise = styled(BaseComponent)`
  outline: none;
`
export default SvgRotateCounterClockwise
