import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7.5a.5.5 0 01-.21.407l-7 5A.5.5 0 016 12.5V10H3.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5H6V2.5a.5.5 0 01.79-.407l7 5A.5.5 0 0114 7.5zM7 3.472V5.5a.5.5 0 01-.5.5H4v3h2.5a.5.5 0 01.5.5v2.028L12.64 7.5 7 3.472z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgThickArrowRight = styled(BaseComponent)`
  outline: none;
`
export default SvgThickArrowRight
