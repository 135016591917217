import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9.5a.4.4 0 00-.8 0v.6h-.6a.4.4 0 000 .8h.6v.6a.4.4 0 00.8 0v-.6h.6a.4.4 0 000-.8h-.6V.5zm-2.046 2.646a.5.5 0 010 .708l-1 1a.5.5 0 11-.707-.708l1-1a.5.5 0 01.707 0zm-2 2a.5.5 0 010 .708l-7 7a.5.5 0 11-.708-.708l7-7a.5.5 0 01.708 0zM13.5 5.1c.22 0 .4.18.4.4v.6h.6a.4.4 0 010 .8h-.6v.6a.4.4 0 01-.8 0v-.6h-.6a.4.4 0 010-.8h.6v-.6c0-.22.18-.4.4-.4zM8.9.5a.4.4 0 00-.8 0v.6h-.6a.4.4 0 100 .8h.6v.6a.4.4 0 10.8 0v-.6h.6a.4.4 0 000-.8h-.6V.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgMagicWand = styled(BaseComponent)`
  outline: none;
`
export default SvgMagicWand
