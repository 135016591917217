import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14A7 7 0 107 0a7 7 0 000 14zM6.3 3.5h1.05v3.675l3.15 1.869-.524.861L6.3 7.7V3.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgClockInverted = styled(BaseComponent)`
  outline: none;
`
export default SvgClockInverted
