// based on https://github.com/streamich/react-use/blob/master/src/useWindowSize.ts

import { useEffect, useState } from 'react'

import { isBrowser } from '../utils/misc'
import { TimerId, Size } from '../utils/types'

type ResizeHandlerListener = (width: number, height: number) => void
type UseResize = (
  listener: ResizeHandlerListener,
  debounceInterval?: number
) => Size

const RESIZE_EVENT = 'resize'

export const useResize: UseResize = (handleResize, debounceInterval = 100) => {
  const [state, setState] = useState<{ width: number; height: number }>({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0,
  })

  useEffect(() => {
    let timerId: TimerId | undefined
    const handler = () => {
      if (timerId) {
        clearTimeout(timerId)
      }
      timerId = setTimeout(() => {
        if (handleResize) {
          handleResize(window.innerWidth, window.innerHeight)
        }
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }, debounceInterval)
    }
    if (isBrowser) {
      window.addEventListener(RESIZE_EVENT, handler)
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener(RESIZE_EVENT, handler)
      }
    }
  }, [debounceInterval, handleResize])

  return state
}
