import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9.5a.4.4 0 00-.8 0v.6h-.6a.4.4 0 100 .8h.6v.6a.4.4 0 10.8 0v-.6h.6a.4.4 0 000-.8h-.6V.5zm3 3a.4.4 0 10-.8 0v.6h-.6a.4.4 0 100 .8h.6v.6a.4.4 0 10.8 0v-.6h.6a.4.4 0 000-.8h-.6v-.6zm-4 3a.4.4 0 10-.8 0v.6H.5a.4.4 0 100 .8h.6v.6a.4.4 0 00.8 0v-.6h.6a.4.4 0 000-.8h-.6v-.6zM8.544.982l-.298-.04c-.213-.024-.34.224-.217.4A6.57 6.57 0 019.203 5.1a6.602 6.602 0 01-6.243 6.59c-.214.012-.333.264-.183.417.069.07.138.139.21.206l.072.066.26.226.188.148.121.09.187.131.176.115c.12.076.244.149.37.217l.264.135.26.12.303.122.244.086a6.568 6.568 0 001.103.26l.317.04.267.02a6.6 6.6 0 006.943-7.328l-.037-.277a6.557 6.557 0 00-.384-1.415l-.113-.268-.077-.166-.074-.148a6.602 6.602 0 00-.546-.883l-.153-.2-.199-.24-.163-.18-.12-.124-.16-.158-.223-.2-.32-.26-.245-.177-.292-.19-.321-.186-.328-.165-.113-.052-.24-.101-.276-.104-.252-.082-.325-.09-.265-.06-.292-.053zm1.86 4.318a7.578 7.578 0 00-.572-2.894 5.601 5.601 0 11-4.748 10.146 7.61 7.61 0 003.66-2.51.749.749 0 001.355-.442.75.75 0 00-.584-.732c.062-.116.122-.235.178-.355A1.25 1.25 0 1010.35 6.2c.034-.295.052-.595.052-.9z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgMoon = styled(BaseComponent)`
  outline: none;
`
export default SvgMoon
