import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.105 12c-.397 0-.681-.088-.853-.264-.168-.18-.252-.465-.252-.853V4.117c0-.397.086-.681.258-.853.176-.176.458-.264.847-.264H9.03c1.108 0 2.025.982 2.025 2.185 0 .9-.45 1.634-1.35 2.051 1.162.213 1.814 1.392 1.814 2.245 0 1.031-.528 2.519-2.24 2.519H5.104zm3.274-3.997H5.8v2.628h2.579c.521 0 1.25-.51 1.25-1.332 0-.823-.729-1.296-1.25-1.296zM5.8 4.37v2.327h2.38c.36 0 1.097-.337 1.097-1.196 0-.86-.797-1.131-1.097-1.131H5.8z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFontBold = styled(BaseComponent)`
  outline: none;
`
export default SvgFontBold
