import { Stage, Step } from './types'
import { getStep } from './utils'

export enum ActionType {
  NEXT_STAGE_OR_STEP = 'NEXT_STAGE_OR_STEP',
  GO_TO_STAGE = 'GO_TO_STAGE',
  PREV_STAGE_OR_STEP = 'PREV_STAGE_OR_STEP',
  RESET_STAGE = 'RESET_STAGE',
  UPDATE_STAGES = 'UPDATE_STAGES',
}

type FormState = Record<
  string,
  string | number | boolean | Record<string, string | number | boolean>
>

export type Action =
  | {
      type: ActionType.GO_TO_STAGE
      payload: { formState: FormState; stageIndex: number }
    }
  | { type: ActionType.NEXT_STAGE_OR_STEP; formState: FormState }
  | { type: ActionType.PREV_STAGE_OR_STEP; formState: FormState }
  | { type: ActionType.RESET_STAGE; payload: State }
  | { type: ActionType.UPDATE_STAGES; payload: { stages: Stage[] } }

export type State = {
  stages: Stage[]
  stage: Stage
  step: Step
  stepIndex: number
  stageIndex: number
}

const goToNextStepOrStage = (
  { stepIndex, stageIndex, stage, stages, step }: State,
  formState: FormState
) => {
  const curStage = stage ?? stages[stageIndex]
  const hasNextStep = stepIndex < curStage.steps.length - 1
  const hasNextStage = stageIndex < stages.length - 1

  if (hasNextStep) {
    return getStep('NEXT')(formState, stages, stageIndex, stepIndex)
  }

  if (hasNextStage) {
    return getStep('NEXT')(formState, stages, stageIndex + 1, -1)
  }

  return {
    step,
    stepIndex,
    stage,
    stageIndex,
  }
}

const goToStage = (
  { stages }: State,
  formState: FormState,
  newStageIndex: number
) => getStep('STAGE')(formState, stages, newStageIndex, 0)

const goToPreviousStepOrStage = (
  { stepIndex, stageIndex, stages }: State,
  formState: FormState
) => {
  const hasPrevStep = stepIndex > 0

  if (hasPrevStep) {
    return getStep('PREVIOUS')(formState, stages, stageIndex, stepIndex)
  }

  const prevStageIndex = stageIndex - 1
  const prevStage = stages[prevStageIndex]

  return getStep('PREVIOUS')(
    formState,
    stages,
    prevStageIndex,
    prevStage.steps.length
  )
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.NEXT_STAGE_OR_STEP: {
      const data = goToNextStepOrStage(state, action.formState)
      return {
        ...state,
        ...data,
      }
    }
    case ActionType.GO_TO_STAGE: {
      const data = goToStage(
        state,
        action.payload?.formState,
        action.payload?.stageIndex
      )
      return {
        ...state,
        ...data,
      }
    }
    case ActionType.PREV_STAGE_OR_STEP: {
      const data = goToPreviousStepOrStage(state, action.formState)
      return {
        ...state,
        ...data,
      }
    }
    case ActionType.RESET_STAGE: {
      return action.payload
    }
    case ActionType.UPDATE_STAGES: {
      return {
        ...state,
        stages: action.payload.stages,
      }
    }
    default:
      return state
  }
}
