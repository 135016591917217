import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 1v13h1.5V1h-1.5z"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 5 7)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 5 13)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 3 7)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 3 13)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 7 7)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 1 7)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 7 13)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 1 13)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 7 5)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 1 5)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 7 3)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 1 3)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 7 9)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 1 9)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 7 11)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 1 11)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 9 7)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 9 13)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 11 7)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 11 13)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 5 1)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 3 1)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 7 1)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 1 1)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 9 1)"
        fill="currentColor"
      />
      <rect
        width={1}
        height={1}
        rx={0.5}
        transform="matrix(0 1 1 0 11 1)"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBorderRight = styled(BaseComponent)`
  outline: none;
`
export default SvgBorderRight
