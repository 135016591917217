import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.05 1.5a.45.45 0 01.45-.45h12a.45.45 0 010 .9h-12a.45.45 0 01-.45-.45zm0 12a.45.45 0 01.45-.45h12a.45.45 0 010 .9h-12a.45.45 0 01-.45-.45zM6 11V4h3v7H6zM5 3.75A.75.75 0 015.75 3h3.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75h-3.5a.75.75 0 01-.75-.75v-7.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgAlignStretch = styled(BaseComponent)`
  outline: none;
`
export default SvgAlignStretch
