import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 4a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm-2.829 7A4.491 4.491 0 016 7.5c0-1.414.652-2.675 1.671-3.5H4.5a3.5 3.5 0 100 7h3.171zM0 7.5A4.5 4.5 0 014.5 3h6a4.5 4.5 0 110 9h-6A4.5 4.5 0 010 7.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSwitch = styled(BaseComponent)`
  outline: none;
`
export default SvgSwitch
