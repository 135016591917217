import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.038 9.98c-.103-.322.078-.603.362-.731.284-.129.695-.115.87.205.584 1.06 1.376 1.274 2.217 1.274 1.04 0 1.81-.466 1.81-1.274 0-.65-.385-.99-1.426-1.311l-.712-.221c-1.514-.473-2.27-1.23-2.27-2.466 0-1.659 1.387-2.769 3.354-2.769 1.674 0 2.731.648 3.195 1.624.133.278.138.602-.21.88s-.704.157-.995-.153c-.76-.811-1.238-.988-1.977-.988-1.116 0-1.709.586-1.709 1.23 0 .586.416.952 1.4 1.254l.732.227c1.55.473 2.295 1.199 2.295 2.41 0 1.601-1.28 2.92-3.513 2.92-1.595 0-3.061-.978-3.423-2.11zm10.811-1.2c-1.188-.385-1.684-.919-1.684-1.792 0-1.12.999-1.942 2.448-1.942 1.242 0 2.05.587 2.365 1.589.066.211-.019.345-.23.414-.209.068-.43.05-.51-.153-.302-.773-.886-1.133-1.638-1.133-.953 0-1.586.489-1.586 1.153 0 .535.332.834 1.233 1.128l.588.189c1.227.397 1.717.905 1.717 1.785 0 1.18-1.071 2.026-2.56 2.026-1.348 0-2.336-.763-2.572-1.708-.055-.217-.008-.307.28-.374.289-.067.371-.063.472.175.284.674.981 1.19 1.86 1.19.96 0 1.651-.547 1.651-1.264 0-.527-.287-.775-1.246-1.088l-.588-.195z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFontStyle = styled(BaseComponent)`
  outline: none;
`
export default SvgFontStyle
