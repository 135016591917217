import React, { useCallback, useState, forwardRef, useEffect } from 'react'

import { css } from '@styled-system/css'
import styled from 'styled-components'

import { Box } from '../../atoms/Box'
import { Props as InputProps, StyledInput, StyledLabel } from './Checkbox'

export type Props = InputProps

const StyledRadio = styled(Box)<{
  hasError?: boolean
  checked?: boolean
  disabled?: boolean
}>(({ hasError, disabled }) =>
  css({
    width: 32,
    height: 32,
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'thin',
    mr: 4,
    borderRadius: '50%',
    transition: 'all 150ms',
    borderColor: hasError ? 'red' : disabled ? 'black.1' : 'secondary',
    color: 'white',
    'input:checked ~ &': {
      backgroundColor: disabled ? 'green.1' : 'success',
    },
  })
)

const StyledRadioIcon = styled(Box)<{ internalValue: boolean }>(
  ({ internalValue }) =>
    css({
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'white',
      visibility: internalValue ? 'visible' : 'hidden',
    })
)

const Radio = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      id,
      error,
      labelProps,
      checked,
      defaultChecked,
      children,
      disabled,
      onChange,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(
      checked ?? !!defaultChecked
    )

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event?.target.checked)
        onChange?.(event)
      },
      [onChange]
    )

    useEffect(() => {
      setInternalValue(checked ?? internalValue)
    }, [checked, internalValue])

    return (
      <StyledLabel htmlFor={id} {...labelProps} disabled={disabled}>
        <StyledInput
          as="input"
          type="radio"
          id={id}
          name={name}
          checked={internalValue}
          onChange={handleOnChange}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        <StyledRadio hasError={!!error} disabled={disabled}>
          <StyledRadioIcon internalValue={internalValue} />
        </StyledRadio>
        {children}
      </StyledLabel>
    )
  }
)

export default Radio
