import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M78 39.5L52.6 50.2a1 1 0 01-1-.3L35.8 33.8a1 1 0 01.3-1.6l13.4-6.7c.2-.1.5-.2.8 0L78 37.6a1 1 0 010 1.9z"
        fill="#F3AD3D"
      />
      <path
        d="M86.5 29.5l-57 25.2a1 1 0 01-1-.1l-7-6.2a1 1 0 00-1-.1L10 53a1 1 0 00-.5 1.4C24.1 81 26.7 80.8 30 79.5l80.4-30h.2c4-2.8 8.6-7.2 10-9a1 1 0 00.1-1l-2.6-5.3a3 3 0 00-2-1.5L106 30c-15-3.5-10-4.5-19.5-.5z"
        fill="#4A4A48"
      />
      <rect
        x={96.2}
        y={37.2}
        width={8.2}
        height={6.2}
        rx={1}
        transform="rotate(-19.5 96.2 37.2)"
        fill="#329C7F"
      />
      <rect
        x={75.1}
        y={44.5}
        width={8.2}
        height={6.2}
        rx={1}
        transform="rotate(-19.5 75 44.5)"
        fill="#329C7F"
      />
      <rect
        x={85.7}
        y={40.8}
        width={8.2}
        height={6.2}
        rx={1}
        transform="rotate(-19.5 85.7 40.8)"
        fill="#329C7F"
      />
      <rect x={17} y={105} width={94} height={8} rx={0.9} fill="#329C7F" />
      <path
        d="M82.8 53.8L54.6 64.5a1 1 0 00-.6 1v24.1a1 1 0 001.3 1L71 85.8c.2-.1.5-.3.6-.6l12.5-30a1 1 0 00-1.3-1.4z"
        fill="#F3AD3D"
      />
    </Svg>
  )
}

const SvgAirplane = styled(BaseComponent)`
  outline: none;
`
export default SvgAirplane
