import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.782 2.217a.4.4 0 00-.565 0l-2 2a.4.4 0 00.565.566L3.1 3.466v8.068l-1.317-1.317a.4.4 0 00-.565.566l2 2a.4.4 0 00.565 0l2-2a.4.4 0 00-.565-.566l-1.318 1.317V3.466l1.318 1.317a.4.4 0 00.565-.566l-2-2zM8.5 4a.5.5 0 100 1h6a.5.5 0 000-1h-6zM8 7.5a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5zm.5 2.5a.5.5 0 100 1h6a.5.5 0 000-1h-6z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLineHeight = styled(BaseComponent)`
  outline: none;
`
export default SvgLineHeight
