import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM1.827 7.5a5.673 5.673 0 1111.346 0 5.673 5.673 0 01-11.346 0zm3.21 1.714a.5.5 0 10-.82.572A3.996 3.996 0 007.5 11.5c1.36 0 2.56-.679 3.283-1.714a.5.5 0 00-.82-.572A2.996 2.996 0 017.5 10.5a2.996 2.996 0 01-2.463-1.286zm.338-2.364a.875.875 0 100-1.75.875.875 0 000 1.75zm5.125-.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFace = styled(BaseComponent)`
  outline: none;
`
export default SvgFace
