import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2h5.5a.5.5 0 01.5.5v10a.5.5 0 01-.5.5H8V2zM7 2H1.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5H7V2zm-7 .5A1.5 1.5 0 011.5 1h12A1.5 1.5 0 0115 2.5v10a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 010 12.5v-10z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgViewVertical = styled(BaseComponent)`
  outline: none;
`
export default SvgViewVertical
