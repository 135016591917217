import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 12.85H1v1.3h13v-1.3zm0-4H1v1.3h13v-1.3zm-13-4h13v1.3H1v-1.3zm13-4H1v1.3h13V.85z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgRows = styled(BaseComponent)`
  outline: none;
`
export default SvgRows
