import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.854 3.854a.5.5 0 10-.708-.708l-4 4a.5.5 0 000 .708l4 4a.5.5 0 00.708-.708L3.207 7.5l3.647-3.646zm6 0a.5.5 0 00-.708-.708l-4 4a.5.5 0 000 .708l4 4a.5.5 0 00.708-.708L9.207 7.5l3.647-3.646z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDoubleArrowLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgDoubleArrowLeft
