import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 6.625a.875.875 0 100 1.75.875.875 0 000-1.75zm4 0a.875.875 0 100 1.75.875.875 0 000-1.75zm4 0a.875.875 0 100 1.75.875.875 0 000-1.75zm3.125.875a.875.875 0 111.75 0 .875.875 0 01-1.75 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBorderDotted = styled(BaseComponent)`
  outline: none;
`
export default SvgBorderDotted
