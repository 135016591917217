import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 68 68"
      {...props}
    >
      <path d="M9.58 18.85v41.49h31.91V24.6h-7.98v-5.75z" fill="#414141" />
      <path
        d="M9.58 45.02c15.64 0 21.7-8.93 23.94-26.17H9.58v26.17z"
        fill="gray"
      />
      <path
        fill="none"
        stroke="#edf2f1"
        strokeWidth={1.91}
        d="M15.96 52.36h19.15m-19.15-6.38h19.15"
      />
      <path
        d="M25.53 25.87l6.38 5.32v7.44h-4.46V32.9h-3.83v5.74h-4.47V31.2z"
        fill="#edf2f1"
        fillRule="evenodd"
      />
      <circle cx={11.49} cy={56.51} r={9.57} fill="#f8f7f5" />
      <circle cx={11.49} cy={56.51} r={9.57} fill="#329c7f" />
      <path
        d="M11.49 46.94a9.57 9.57 0 00-6.77 16.34l6.77-6.77z"
        fill="#5bb099"
      />
      <path
        d="M11.49 51.4l1.2 3.9h3.9l-3.15 2.42 1.2 3.9-3.15-2.41-3.16 2.4 1.2-3.9-3.15-2.4h3.9z"
        fill="#fff"
      />
      <path
        d="M47.23 32.89A12.13 12.13 0 0135.1 20.76h-7.66a19.79 19.79 0 0019.8 19.79zm12.13-12.13A12.13 12.13 0 0147.23 32.9v7.66a19.79 19.79 0 0019.79-19.79zM47.23 8.63a12.13 12.13 0 0112.13 12.13h7.66A19.79 19.79 0 0047.23.97zm0-7.66a19.79 19.79 0 00-19.79 19.8h7.66A12.13 12.13 0 0147.23 8.62z"
        fill="#f8f7f5"
      />
      <path
        d="M31.27 20.76A15.96 15.96 0 0147.23 4.8 15.96 15.96 0 0163.2 20.76a15.96 15.96 0 01-15.96 15.96 15.96 15.96 0 01-15.96-15.96z"
        fill="#5bb099"
      />
      <path
        d="M44.93 24.63a.14.14 0 00.15.15h4.32a.14.14 0 00.16-.15V23.5a.14.14 0 00-.16-.15h-2.77v-.02l2.28-3.98a4.32 4.32 0 00.65-2.5 3.98 3.98 0 00-.14-1.2 2.07 2.07 0 00-2.18-1.43 2.1 2.1 0 00-2.19 1.45 4.3 4.3 0 00-.12 1.29.16.16 0 00.14.18l1.2.11c.1.02.18-.03.18-.15a3.27 3.27 0 01.06-.95.67.67 0 01.7-.5.69.69 0 01.72.5 2.27 2.27 0 01.06.7 2.94 2.94 0 01-.4 1.72l-2.58 4.65a.64.64 0 00-.08.31z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgHomeTwo = styled(BaseComponent)`
  outline: none;
`
export default SvgHomeTwo
