import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={34}
      height={56}
      viewBox="0 0 34 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.313.656c-.95 0-1.72.77-1.72 1.719V16.71L11.69 28.005.594 39.395v14.23c0 .95.77 1.719 1.719 1.719h29.375c.949 0 1.718-.77 1.718-1.719v-14.23l-11.095-11.39L33.406 16.71V2.374c0-.95-.77-1.719-1.718-1.719H2.313zm1.406 14.776V3.782H30.28v11.65L17.94 27.995l12.342 12.67V52.22H3.72V40.665l12.342-12.67L3.719 15.432zm1.562-2.276h23.437L16.999 24.875 5.281 13.156zm10.938 17.188h1.563v1.562h-1.563v-1.562zm1.563 3.125h-1.563v1.562h1.563V33.47zm-1.563 3.125h1.563v1.562h-1.563v-1.562zm12.499 6.25H5.281v7.812h23.437v-7.812z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgHourGlass = styled(BaseComponent)`
  outline: none;
`
export default SvgHourGlass
