import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1a1 1 0 00-1 1v11a1 1 0 001 1h13a1 1 0 001-1V2a1 1 0 00-1-1H1zm6.5 9.625a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgMaskOn = styled(BaseComponent)`
  outline: none;
`
export default SvgMaskOn
