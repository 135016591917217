import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1 12.5a.4.4 0 00.8 0v-10a.4.4 0 00-.8 0v10zm2 0a.4.4 0 00.8 0v-10a.4.4 0 00-.8 0v10zm2.4.4a.4.4 0 01-.4-.4v-10a.4.4 0 11.8 0v10a.4.4 0 01-.4.4zm1.6-.4a.4.4 0 00.8 0v-10a.4.4 0 00-.8 0v10z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDragHandleVertical = styled(BaseComponent)`
  outline: none;
`
export default SvgDragHandleVertical
