import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05 2.75a.55.55 0 00-1.1 0v9.5a.55.55 0 001.1 0v-9.5zm4 0a.55.55 0 00-1.1 0v9.5a.55.55 0 001.1 0v-9.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPause = styled(BaseComponent)`
  outline: none;
`
export default SvgPause
