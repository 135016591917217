import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1.5a.5.5 0 00-1 0V4H5V1.5a.5.5 0 00-1 0V4H1.5a.5.5 0 000 1H4v5H1.5a.5.5 0 000 1H4v2.5a.5.5 0 001 0V11h5v2.5a.5.5 0 001 0V11h2.5a.5.5 0 000-1H11V5h2.5a.5.5 0 000-1H11V1.5zM10 10V5H5v5h5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFrame = styled(BaseComponent)`
  outline: none;
`
export default SvgFrame
