import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 0a.5.5 0 01.5.5V3h7.5a.5.5 0 01.5.5V11h2.5a.5.5 0 110 1H12v2.5a.5.5 0 01-1 0V12H3.5a.5.5 0 01-.5-.5V4H.5a.5.5 0 110-1H3V.5a.5.5 0 01.5-.5zM4 4v7h7V4H4z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCrop = styled(BaseComponent)`
  outline: none;
`
export default SvgCrop
