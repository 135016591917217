import React, { useMemo, useEffect } from 'react'

import {
  InfoCircled,
  CheckCircled,
  CrossCircled,
  ExclamationTriangle,
} from '@linus-capital/icons'

import { Box } from '../../atoms/Box'
import { useTimeout } from '../../hooks'
import { Banner } from '../../molecules/Banner'
import { ToastProps, ToastPosition } from './Toast.type'

const StatusIconMap = {
  success: <CheckCircled size={20} />,
  error: <CrossCircled size={20} />,
  warning: <ExclamationTriangle size={20} />,
  info: <InfoCircled size={20} />,
}

const getToastStyle = (position: ToastPosition): React.CSSProperties => {
  const isRighty = position.includes('right')
  const isLefty = position.includes('left')

  let alignItems = 'center'
  if (isRighty) {
    alignItems = 'flex-end'
  }
  if (isLefty) {
    alignItems = 'flex-start'
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems,
    margin: '0.5rem',
    pointerEvents: 'auto',
    maxWidth: 560,
    minWidth: 300,
  }
}

const Toast = ({
  message,
  duration = 5000,
  position = 'top-right',
  requestClose,
  isClosable,
  status = 'info',
  onClose,
  onRequestRemove,
}: ToastProps) => {
  useEffect(() => {
    if (requestClose) {
      onRequestRemove()
    }
  }, [requestClose, onRequestRemove])

  useTimeout(onRequestRemove, duration)

  const style = useMemo(() => getToastStyle(position), [position])

  return (
    <Box style={style}>
      <Banner
        status={status}
        icon={StatusIconMap[status]}
        hasClose={isClosable}
        onClose={onClose}
      >
        {message}
      </Banner>
    </Box>
  )
}

export default Toast
