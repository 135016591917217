import styled from 'styled-components';
import {
  color,
  space,
  layout,
  SpaceProps,
  LayoutProps,
  ColorProps,
} from 'styled-system';

type Props = React.SVGProps<SVGSVGElement> &
  ColorProps &
  SpaceProps &
  LayoutProps;

const Svg = styled('svg')<Props>(
  {
    flex: 'none',
    lineHeight: 1,
  },
  space,
  color,
  layout
);

export default Svg;
