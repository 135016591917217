import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 1v13H.25V1h1.5z"
        fill="currentColor"
      />
      <rect
        x={10}
        y={7}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 10 7)"
        fill="currentColor"
      />
      <rect
        x={10}
        y={13}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 10 13)"
        fill="currentColor"
      />
      <rect
        x={12}
        y={7}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 12 7)"
        fill="currentColor"
      />
      <rect
        x={12}
        y={13}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 12 13)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={7}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 8 7)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={7}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 14 7)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={13}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 8 13)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={13}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 14 13)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={5}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 8 5)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={5}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 14 5)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={3}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 8 3)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={3}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 14 3)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={9}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 8 9)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={9}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 14 9)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={11}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 8 11)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={11}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 14 11)"
        fill="currentColor"
      />
      <rect
        x={6}
        y={7}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 6 7)"
        fill="currentColor"
      />
      <rect
        x={6}
        y={13}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 6 13)"
        fill="currentColor"
      />
      <rect
        x={4}
        y={7}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 4 7)"
        fill="currentColor"
      />
      <rect
        x={4}
        y={13}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 4 13)"
        fill="currentColor"
      />
      <rect
        x={10}
        y={1}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 10 1)"
        fill="currentColor"
      />
      <rect
        x={12}
        y={1}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 12 1)"
        fill="currentColor"
      />
      <rect
        x={8}
        y={1}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 8 1)"
        fill="currentColor"
      />
      <rect
        x={14}
        y={1}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 14 1)"
        fill="currentColor"
      />
      <rect
        x={6}
        y={1}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 6 1)"
        fill="currentColor"
      />
      <rect
        x={4}
        y={1}
        width={1}
        height={1}
        rx={0.5}
        transform="rotate(90 4 1)"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBorderLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgBorderLeft
