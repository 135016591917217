import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={38.1}
        y={25.4}
        width={19.05}
        height={6.35}
        rx={1.032}
        transform="rotate(90 38.1 25.4)"
        fill="#fff"
      />
      <rect
        x={101.6}
        y={25.4}
        width={19.05}
        height={6.35}
        rx={1.032}
        transform="rotate(90 101.6 25.4)"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.688 38.1h53.974v6.35c0 .877.711 1.587 1.588 1.587h6.35c.877 0 1.588-.71 1.588-1.587V38.1h13.255c.57 0 1.032.462 1.032 1.032v74.136c0 .57-.462 1.032-1.032 1.032H15.319c-.57 0-1.032-.462-1.032-1.032V39.132c0-.57.462-1.032 1.032-1.032h14.844v6.35c0 .877.71 1.587 1.587 1.587h6.35c.877 0 1.587-.71 1.587-1.587V38.1zM64.24 77.212c-.229 0-.381-.152-.381-.38V55.8h-.077l-3.238 2.286c-.19.153-.42.076-.42-.228v-3.239c0-.267.077-.419.382-.648l3.315-2.438c.228-.152.419-.229.685-.229h2.858c.228 0 .38.153.38.381v25.146c0 .229-.152.381-.38.381H64.24zM51.54 97.99c0 .134-.09.222-.223.222h-1.933c-.134 0-.223-.088-.223-.222v-5.978l-2.889-8.69c-.044-.134 0-.223.178-.223h1.956c.155 0 .222.067.266.223l1.69 5.845h.066l1.645-5.845c.044-.156.111-.223.267-.223h1.91c.179 0 .223.09.179.223l-2.89 8.69v5.978zm5.15.222c-.133 0-.222-.088-.222-.222V83.322c0-.134.089-.223.222-.223h6.2c.134 0 .223.09.223.223v1.711c0 .133-.089.222-.222.222H58.98c-.089 0-.133.045-.133.134v3.978c0 .089.044.133.133.133h3.378c.134 0 .223.09.223.222v1.712c0 .133-.09.222-.223.222H58.98c-.089 0-.133.044-.133.133v4.134c0 .09.044.134.133.134h3.912c.133 0 .222.088.222.222v1.711c0 .134-.089.222-.222.222H56.69zm8.176 0c-.134 0-.2-.088-.178-.222l3.267-14.668c.044-.134.111-.223.244-.223h1.867c.134 0 .223.09.245.223l3.2 14.668c.022.134-.044.222-.178.222h-1.889c-.155 0-.222-.088-.244-.222l-.6-3.045h-3L67 97.99c-.023.134-.09.222-.245.222h-1.89zm4.222-10.89l-1.11 5.623h2.244l-1.067-5.623h-.067zM83.533 97.99c.045.111 0 .222-.11.222h-2.068c-.133 0-.2-.044-.244-.155l-1.845-6.023h-1.133c-.09 0-.134.044-.134.133v5.823c0 .134-.089.222-.222.222h-1.933c-.134 0-.223-.088-.223-.222V83.322c0-.134.09-.223.223-.223h3.044c2.69 0 4.312 1.178 4.312 4.49 0 1.978-.667 3.222-1.8 3.867l2.133 6.534zm-5.4-8.067h.778c1.333 0 1.91-.534 1.91-2.334 0-1.778-.577-2.334-1.91-2.334h-.778c-.09 0-.134.045-.134.134v4.4c0 .089.045.133.134.133z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.662 37.1H39.685v-3.175H94v3.179h-1.338V37.1zM30 40.402v4.447a1.587 1.587 0 01-.837-1.399v-3.048H30zm0-3.305v-3.172H9.557c-.57 0-1.032.462-1.032 1.032v74.136c0 .57.462 1.032 1.032 1.032h3.73V38.132c0-.57.462-1.032 1.032-1.032H27.75v-.003H30zm81.713-2.14V37.1H103v-3.175h7.681c.57 0 1.032.462 1.032 1.032z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgCalendarWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgCalendarWhite
