import { useEffect, useState } from 'react'

export type Status = 'idle' | 'loading' | 'ready' | 'error'
export type LinkElt = HTMLLinkElement | null

export const useCssLink = (src: string): Status => {
  const [status, setStatus] = useState<Status>(src ? 'loading' : 'idle')

  useEffect(() => {
    if (!src) {
      setStatus('idle')
      return
    }

    let link: LinkElt = document.querySelector(`link[src="${src}"]`)

    if (!link) {
      link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = src
      link.setAttribute('data-status', 'loading')
      document.head.appendChild(link)

      // Store status in attribute on link
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: Event) => {
        link?.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error'
        )
      }

      link.addEventListener('load', setAttributeFromEvent)
      link.addEventListener('error', setAttributeFromEvent)
    } else {
      // Grab existing link status from attribute and set to state.
      setStatus(link.getAttribute('data-status') as Status)
    }

    // link event handler to update status in state
    // Note: Even if the link already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error')
    }

    link.addEventListener('load', setStateFromEvent)
    link.addEventListener('error', setStateFromEvent)

    return () => {
      if (link) {
        document.head.removeChild(link)
        link.removeEventListener('load', setStateFromEvent)
        link.removeEventListener('error', setStateFromEvent)
      }
    }
  }, [src])

  return status
}
