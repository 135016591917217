import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react'

import { ChevronLeft, ChevronRight } from '@linus-capital/icons'
import css from '@styled-system/css'
import styled from 'styled-components'
import useEventCallback from 'use-event-callback'

import { Box } from '../../atoms/Box'
import { IconButton } from '../../atoms/Button'
import { Flex } from '../../atoms/Flex'
import {
  useSwiping,
  useBreakpoints,
  useResize,
  BreakpointChangeEventListener,
} from '../../hooks'
import {
  mapBreakpointIdentifierToIndex,
  breakpointsMobileFirst,
  breakIdentifiers,
  breakpointIdentifiersCount,
} from '../../utils/createMediaQueries'
import { TimerId, WithChildren } from '../../utils/types'

type ResponsiveProp<T> = { [Breakpoint in breakIdentifiers]?: T }

export type PageNavigationProps = {
  active: boolean
  pageIndex: number
  onClick: (pageIndex: number) => void
}

export type Props = WithChildren<{
  viewportItemsCountMap: ResponsiveProp<number>
  PageNavigationTemplate?: React.FC<PageNavigationProps>
  PageNavigationArrowTemplate?: React.FC<PageNavigationArrowProps>
  options: {
    isSinglePageSwipeMode: boolean
    isElasticModeEnabled: boolean
    autoplayIntervalMs: number
    isNavigationSensorEnabled: boolean
    isArrowNavigationEnabled: ResponsiveProp<boolean>
    hidePageNavigationArrows: ResponsiveProp<boolean>
    hasNavigationTransparentBackground: ResponsiveProp<boolean>
  }
}>

type PageNavigationArrowProps = WithChildren<{
  visible: boolean
  onClick: (event: React.SyntheticEvent) => void
  icon: React.ReactNode
  direction: 'left' | 'right'
}>

type ResponsivePropMap<T> = { [breakpointIndex: number]: T }

const THRESHOLD = 5 * 5
const ELASTICITY = 0.55
const PAGE_NAVIGATION_BUTTON_WIDTH = 40
const PAGE_NAVIGATION_BUTTON_PADDING = 9
// TODO: use hexToRgb once https://github.com/linus-capital/linus-ui/pull/12 is merged to master
// TODO: add functionality that converts named colors to hex
const WHITE = '255, 255, 255'
const DEFAULT_ITEMS_IN_VIEWPORT_COUNT = 1

const minWidth = (breakpoint: breakIdentifiers) => (props: {
  visibleItemsList: number[]
}) => {
  const { visibleItemsList } = props
  const breakpointIndex = mapBreakpointIdentifierToIndex(breakpoint)
  const minWidth = `${
    100 /
    visibleItemsList[Math.min(breakpointIndex, visibleItemsList.length - 1)]
  }%`
  return {
    [breakpointsMobileFirst()[breakpoint]]: {
      minWidth,
    },
  }
}

const PageNavigationArrowStyled = styled(IconButton)<{
  visible: boolean
  tabIndex?: number
}>(({ visible }) =>
  css({
    visibility: visible ? 'visible' : 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `rgba(${WHITE}, 0.5)`,
    minWidth: 0,
    mx: [1, 1, 2],
    width: [40, 53],
    height: [40, 53],
    ':focus': {
      outline: ['-webkit-focus-ring-color auto thin', 'revert'],
    },
  })
)

const CarouselContainerStyled = styled(Box)`
  overflow: hidden;
  flex: 1 0;
  position: relative;
`

const CarouselNavigationSensor = styled(Box)<{ side: 'left' | 'right' }>(
  ({ side }) =>
    css({
      position: 'absolute',
      [side]: 0,
      width: '40%',
      top: 0,
      bottom: 0,
    })
)

const CarouselScrollerStyled = styled(Flex)<{ isMoving: boolean }>(
  ({ isMoving }) => ({
    transition: `all ${
      isMoving ? '0' : '350ms'
    } cubic-bezier(0.25, 0.46, 0.45, 0.94)`,
  })
)

const CarouselItemContainerStyled = styled(Box)<{
  visibleItemsList: number[]
}>`
  display: grid;
  ${minWidth('sm')}
  ${minWidth('md')}
  ${minWidth('lg')}
  ${minWidth('xl')}
`

const PageStyled = styled(Flex)(
  css({
    position: 'relative',
    width: PAGE_NAVIGATION_BUTTON_WIDTH + 2 * PAGE_NAVIGATION_BUTTON_PADDING,
    justifyContent: 'center',
  })
)

const PageButtonStyled = styled(Box)<{ active: boolean }>(({ active }) =>
  css({
    backgroundColor: active ? 'primary' : 'earth.1',
    width: `${
      (100 * PAGE_NAVIGATION_BUTTON_WIDTH) /
      (PAGE_NAVIGATION_BUTTON_WIDTH + 2 * PAGE_NAVIGATION_BUTTON_PADDING)
    }%`,
    height: 4,
    margin: `4px ${
      PAGE_NAVIGATION_BUTTON_PADDING /
      (PAGE_NAVIGATION_BUTTON_WIDTH + 2 * PAGE_NAVIGATION_BUTTON_PADDING)
    }%`,
  })
)

const PageHotZoneStyled = styled(Box)<{ active: boolean }>(({ active }) =>
  css({
    cursor: active ? 'default' : 'pointer',
    position: 'absolute',
    top: '-20px',
    bottom: '-20px',
    left: '1px',
    right: '1px',
  })
)

const PageNavigation = ({
  active,
  pageIndex,
  onClick,
}: PageNavigationProps) => {
  const handleClick = useCallback(() => {
    onClick(pageIndex)
  }, [onClick, pageIndex])
  return (
    <PageStyled onClick={handleClick}>
      <PageHotZoneStyled active={active} />
      <PageButtonStyled active={active} />
    </PageStyled>
  )
}

// TODO: fix tab index - can't assign tabIndex to the button component
const PageNavigationArrow = ({
  visible,
  onClick,
  icon,
}: PageNavigationArrowProps) => (
  <PageNavigationArrowStyled
    onClick={onClick}
    visible={visible}
    icon={icon}
    // tabIndex={0}
  />
)

const calcElasticity = (
  translateX: number,
  diffX: number,
  scrollableWidth: number
): number => {
  translateX += diffX
  if (translateX > 0) {
    // translateX - left side tension
    translateX = translateX ** ELASTICITY
  } else if (scrollableWidth + translateX < 0) {
    // scrollableWidth + translateX - right side tension
    translateX =
      -scrollableWidth - (-(scrollableWidth + translateX)) ** ELASTICITY
  }
  return translateX
}

// +-------------------------------------+
// |  page 0        page 1      page 2   |
// |*-----------*-----------*-----------*|
// |+---+---+---+---+---+---+---+---+    |
// || A | B | C | A | B | C | A | B |    |
// |+---+---+---+---+---+---+---+---+    |
// |<-----------> viewportWidth          |
// |<--------- scrollWidth --------->    |
// +-------------------------------------+
// <------- effectiveScrollWidth -------->

const calcEffectiveScrollWidth = (
  viewportWidth: number,
  scrollWidth: number
): number => Math.ceil(scrollWidth / viewportWidth) * viewportWidth

// +----------------------+      scrollerWidth
// |+---------------------:--------------+
// ||                     :              |
// ||                     :              |
// |+---------------------:--------------+
// +----------------------+
//     viewportWidth

// https://github.com/Microsoft/TypeScript/issues/4922#issuecomment-355130811
const convertResponsivePropMapToList = <T extends any>(
  responsiveProp: ResponsiveProp<T>,
  smDefault: T
): T[] => {
  // the function transforms input map like { sm: 3, md: 5 } to indices based map { 0: 3, 2: 5 }
  // The gaps are filled by repeating of the values from the smaller resolution: { 0: 3, 1: 3, 2: 5, 3: 5}
  // and finally the list of values is extracted as the result of function [3, 3, 5, 5]
  const mapBreakpointsAsIndices: ResponsivePropMap<T> = Object.entries(
    responsiveProp
  ).reduce((acc, [breakpointKey, value]) => {
    const index = mapBreakpointIdentifierToIndex(
      breakpointKey as breakIdentifiers
    )
    if (value !== undefined) {
      acc[index] = value
    }
    return acc
  }, {} as ResponsivePropMap<T>)
  mapBreakpointsAsIndices[0] = mapBreakpointsAsIndices[0] ?? smDefault
  for (let i = 1; i < breakpointIdentifiersCount; i += 1) {
    mapBreakpointsAsIndices[i] =
      mapBreakpointsAsIndices[i] ?? mapBreakpointsAsIndices[i - 1]
  }
  return Object.values(mapBreakpointsAsIndices)
}

const getResponsiveValue = <T extends any>(
  values: T[],
  breakpoints: { breakpointIndex: number }
): T => values[Math.min(breakpoints.breakpointIndex, values.length - 1)]

const Carousel = ({
  children,
  viewportItemsCountMap,
  PageNavigationTemplate = PageNavigation,
  PageNavigationArrowTemplate = PageNavigationArrow,
  options,
}: Props) => {
  const {
    isSinglePageSwipeMode,
    isElasticModeEnabled,
    autoplayIntervalMs,
    isNavigationSensorEnabled,
  } = options

  const viewportItemsCountList: number[] = convertResponsivePropMapToList(
    viewportItemsCountMap,
    DEFAULT_ITEMS_IN_VIEWPORT_COUNT
  )
  const isArrowNavigationEnabledList: boolean[] = convertResponsivePropMapToList(
    options.isArrowNavigationEnabled,
    false
  )

  const hidePageNavigationArrowsList: boolean[] = convertResponsivePropMapToList(
    options.hidePageNavigationArrows,
    false
  )

  const hasNavigationTransparentBackgroundList: boolean[] = convertResponsivePropMapToList(
    options.hasNavigationTransparentBackground,
    false
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollerRef = useRef<HTMLDivElement>(null)
  const pagerRef = useRef<HTMLDivElement>(null)
  const autoplayTimerRef = useRef<TimerId | undefined>(undefined)

  // the scroll offset of items scroller for the given page
  // contains only values N * viewport_width, N ∈ { 0, -1, -2, ... }
  const [scrollerOffsetX, setScrollerOffsetX] = useState(0)

  // this is used if single page swipe mode is enabled
  // the value holds the scroll offset value that the items scroller had when swipe was started
  const [initialScrollerOffsetX, setInitialScrollerOffsetX] = useState(0)

  // the visible items width
  const getViewportWidth = useCallback(
    () => containerRef.current?.clientWidth ?? 0,
    []
  )

  const breakpoints = useBreakpoints()
  const viewportItemsCount = getResponsiveValue(
    viewportItemsCountList,
    breakpoints
  )

  const isArrowNavigationEnabled = getResponsiveValue(
    isArrowNavigationEnabledList,
    breakpoints
  )

  const hidePageNavigationArrows = getResponsiveValue(
    hidePageNavigationArrowsList,
    breakpoints
  )

  const hasNavigationTransparentBackground = getResponsiveValue(
    hasNavigationTransparentBackgroundList,
    breakpoints
  )

  // calculates the index of visible page
  const activePage = getViewportWidth()
    ? Math.round(-initialScrollerOffsetX / getViewportWidth())
    : 0
  const itemsCount = useMemo(() => React.Children.count(children), [children])

  // calculate the number of visible items, the total items count and the count of pages
  const pagesCount = useMemo(
    () => Math.max(1, Math.ceil(itemsCount / viewportItemsCount)),
    [itemsCount, viewportItemsCount]
  )

  // sets visible page
  const showPage = useCallback(
    (pageIndex) => {
      const clippedPageIndex = Math.max(0, Math.min(pagesCount - 1, pageIndex))
      setScrollerOffsetX(-clippedPageIndex * getViewportWidth())
      setInitialScrollerOffsetX(-clippedPageIndex * getViewportWidth())
    },
    [pagesCount, getViewportWidth]
  )

  const retriggerAutoplayTimer = useCallback(
    (isOnlyClearTimeout = false) => {
      if (autoplayTimerRef.current) {
        clearTimeout(autoplayTimerRef.current)
        autoplayTimerRef.current = undefined
      }
      if (autoplayIntervalMs > 0 && !isOnlyClearTimeout) {
        autoplayTimerRef.current = setTimeout(() => {
          showPage((activePage + 1) % pagesCount)
        }, autoplayIntervalMs)
      }
    },
    [activePage, autoplayIntervalMs, pagesCount, showPage]
  )

  // handle breakpoint changed event
  const handleBreakpointChanged: BreakpointChangeEventListener = ({
    previous,
    current,
  }) => {
    if (previous !== undefined) {
      const previousIndex = mapBreakpointIdentifierToIndex(previous)
      const nextIndex = mapBreakpointIdentifierToIndex(current)
      const viewportItemsCountPrevious =
        viewportItemsCountList[
          Math.min(previousIndex, viewportItemsCountList.length - 1)
        ]
      const viewportItemsCountNew =
        viewportItemsCountList[
          Math.min(nextIndex, viewportItemsCountList.length - 1)
        ]
      const viewportCentralItemIndex =
        (activePage + 1 / 2) * viewportItemsCountPrevious
      const newActivePage = Math.round(
        viewportCentralItemIndex / viewportItemsCountNew - 1 / 2
      )
      showPage(newActivePage)
      retriggerAutoplayTimer()
    }
  }

  useBreakpoints(handleBreakpointChanged)

  // all pages width
  const scrollerWidth = calcEffectiveScrollWidth(
    getViewportWidth(),
    scrollerRef.current?.scrollWidth ?? 0
  )

  // handles start of swipe
  const handleSwipeStart = () => {
    setInitialScrollerOffsetX(scrollerOffsetX)
    retriggerAutoplayTimer(true)
  }

  // handles end of swipe
  const handleSwipeEnd = () => {
    // mouse button is released / swiping is done => scroll to the previous/next page
    const referentScrollX = isSinglePageSwipeMode
      ? initialScrollerOffsetX
      : scrollerOffsetX
    let currentPage = Math.round(-referentScrollX / getViewportWidth())

    if (diffPosition.x > 0) {
      currentPage -= 1
    } else if (diffPosition.x < 0) {
      currentPage += 1
    }

    showPage(currentPage)
    retriggerAutoplayTimer()
  }

  // handles swiping
  const { isMoving, diffPosition } = useSwiping(containerRef, {
    threshold: THRESHOLD,
    onSwipeStart: handleSwipeStart,
    onSwipeEnd: handleSwipeEnd,
  })

  const resizeHandlerListener = useEventCallback(() => {
    setTimeout(() => {
      const offset = activePage * (containerRef.current?.clientWidth ?? 0)
      setScrollerOffsetX(-offset)
      setInitialScrollerOffsetX(-offset)
    })
  })

  useResize(resizeHandlerListener, 100)

  useEffect(() => {
    retriggerAutoplayTimer()
    return () => {
      retriggerAutoplayTimer(true)
    }
  }, [retriggerAutoplayTimer])

  // the left arrow click handler - shows the previous page
  const handlePreviousPageNavigation = useEventCallback(() => {
    showPage(activePage - 1)
    retriggerAutoplayTimer()
  })

  // the right arrow click handler - shows the next page
  const handleNextPageNavigation = useEventCallback(() => {
    showPage(activePage + 1)
    retriggerAutoplayTimer()
  })

  // handles the page navigation click
  const handlePageNavigationClick = useEventCallback((pageIndex) => {
    showPage(pageIndex)
    retriggerAutoplayTimer()
  })

  // handles the keyboard page navigation
  const handlePagerKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useEventCallback(
    (event) => {
      if (event.key === 'ArrowLeft') {
        showPage(activePage - 1)
        retriggerAutoplayTimer()
      } else if (event.key === 'ArrowRight') {
        showPage(activePage + 1)
        retriggerAutoplayTimer()
      }
    }
  )

  // translateX defines the position of scroller element
  // if elastic mode is disabled, it's the same as scrollerOffsetX
  let translateX = scrollerOffsetX

  if (isMoving && isElasticModeEnabled) {
    translateX = calcElasticity(
      translateX,
      diffPosition.x,
      scrollerWidth - getViewportWidth()
    )
  }

  // we are using percentage as the offset of scroller. Check the picture in order to
  // understand what will happen if the pixel (absolute) units are used to determine
  // the scroll offset and the screen is resized
  //
  // BEFORE RESIZE
  // <-translateX->|<----------->| viewport size
  //               +-------------+ CarouselContainerStyled
  // +------+------+------+------+------+------+ CarouselScrollerStyled
  // |      |      |      |      |      |      |
  // +------+------+------+------+------+------+
  //               +-------------+      |<---->| 60px
  // AFTER RESIZE
  // <-translateX->|<----------------->| viewport size
  //               +-------------------+
  // +---------+---+-----+---------+---+-----+---------+---------+
  // |         |   |     |         |   |     |         |         |
  // +---------+---+-----+---------+---+-----+---------+---------+
  //               +-------------------+               |<--90px->|
  //
  // after the screen resize, items won't be properly aligned in the view.
  // Using of the percentage resolves the described issue

  const translateXPercentage =
    getViewportWidth() > 0 ? (translateX / getViewportWidth()) * 100 : 0

  // a dummy array used to provide the iterator for navigation pages
  const pages = Array.from({ length: pagesCount }, (_, i) => i)

  return (
    <Box>
      <Flex alignItems="center">
        {isArrowNavigationEnabled && (
          <PageNavigationArrowTemplate
            onClick={handlePreviousPageNavigation}
            visible={activePage > 0}
            icon={<ChevronLeft />}
            direction={'left'}
          />
        )}

        <CarouselContainerStyled ref={containerRef}>
          <CarouselScrollerStyled
            ref={scrollerRef}
            style={{ transform: `translate3d(${translateXPercentage}%, 0, 0)` }}
            isMoving={isMoving}
          >
            {React.Children.map(children, (child, index) => (
              <CarouselItemContainerStyled
                visibleItemsList={viewportItemsCountList}
                key={index}
              >
                {child}
              </CarouselItemContainerStyled>
            ))}
          </CarouselScrollerStyled>
          {
            // HACK: in order to prevent CarouselNavigationSensor to receive a click event during the swipe
            // the element will be hidden => hence !isMoving condition
            !isArrowNavigationEnabled && isNavigationSensorEnabled && !isMoving && (
              <>
                <CarouselNavigationSensor
                  side="left"
                  onClick={handlePreviousPageNavigation}
                />
                <CarouselNavigationSensor
                  side="right"
                  onClick={handleNextPageNavigation}
                />
              </>
            )
          }
        </CarouselContainerStyled>

        {isArrowNavigationEnabled && (
          <PageNavigationArrowTemplate
            onClick={handleNextPageNavigation}
            visible={activePage < pagesCount - 1}
            icon={<ChevronRight />}
            direction={'right'}
          />
        )}
      </Flex>

      <Box position="relative">
        <Flex justifyContent="center">
          <Flex
            backgroundColor={
              hasNavigationTransparentBackground
                ? 'transparent'
                : `rgba(${WHITE}, 0.5)`
            }
            ref={pagerRef}
            my={[5, 6, 7]}
            alignItems="center"
            tabIndex={0}
            onKeyDown={handlePagerKeyDown}
          >
            {!hidePageNavigationArrows && (
              <ChevronLeft onClick={handlePreviousPageNavigation} />
            )}
            {pages.map((_, index) => (
              <PageNavigationTemplate
                key={index}
                active={index === activePage}
                pageIndex={index}
                onClick={handlePageNavigationClick}
              />
            ))}
            {!hidePageNavigationArrows && (
              <ChevronRight onClick={handleNextPageNavigation} />
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

export default Carousel
