import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#329C7F" d="M10 10h20v20H10z" />
      <rect x={98} y={98} width={20} height={20} rx={10} fill="#F3AD3D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 20.5h28v90h26.4l-4.5 4.4 2.2 2.2 7-7 1-1.1-1-1-7-7-2.2 2 4.5 4.5H66v-90H35v3z"
        fill="#4A4A48"
      />
    </Svg>
  )
}

const SvgExecution = styled(BaseComponent)`
  outline: none;
`
export default SvgExecution
