import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M98.163 101.768a1 1 0 011-1h6.541a1 1 0 011 1v10.811a1 1 0 01-1 1h-6.54a1 1 0 01-1-1v-10.811zM21.02 101.768a1 1 0 011-1h6.54a1 1 0 011 1v10.811a1 1 0 01-1 1h-6.54a1 1 0 01-1-1v-10.811z"
        fill="#4A4A48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.795 23.625a.551.551 0 00-.55.551v85.408c0 .304.246.551.55.551h58.408a.551.551 0 00.551-.551V24.176a.551.551 0 00-.551-.551H34.795zm25.347 12.673a.551.551 0 00-.55.55V48.42c0 .304.246.551.55.551h7.715a.551.551 0 00.55-.55V36.848a.551.551 0 00-.55-.551h-7.715zm-15.428.55c0-.303.247-.55.55-.55h7.715c.304 0 .551.246.551.55V48.42a.551.551 0 01-.551.551h-7.714a.551.551 0 01-.551-.55V36.848zm.55 17.633a.551.551 0 00-.55.551v11.572c0 .304.247.55.55.55h7.715a.551.551 0 00.551-.55V55.031a.551.551 0 00-.551-.55h-7.714zm-.55 18.735c0-.304.247-.55.55-.55h7.715c.304 0 .551.246.551.55v11.572a.551.551 0 01-.551.55h-7.714a.551.551 0 01-.551-.55V73.216zm15.428-18.735a.551.551 0 00-.55.551v11.572c0 .304.246.55.55.55h7.715a.551.551 0 00.55-.55V55.031a.551.551 0 00-.55-.55h-7.715zm-.55 18.735c0-.304.246-.55.55-.55h7.715c.304 0 .55.246.55.55v11.572a.551.551 0 01-.55.55h-7.715a.551.551 0 01-.55-.55V73.216zm15.427-.55a.551.551 0 00-.55.55v11.572c0 .304.246.55.55.55h7.714a.551.551 0 00.551-.55V73.216a.551.551 0 00-.55-.55h-7.715zm-.55-17.634c0-.304.246-.55.55-.55h7.714c.305 0 .551.246.551.55v11.572a.551.551 0 01-.55.55h-7.715a.551.551 0 01-.55-.55V55.031zm.55-18.734a.551.551 0 00-.55.55V48.42c0 .304.246.551.55.551h7.714a.551.551 0 00.551-.55V36.848a.551.551 0 00-.55-.551h-7.715z"
        fill="#4A4A48"
      />
      <path
        d="M59.73 98.461a1 1 0 011-1h6.816a1 1 0 011 1v10.812a1 1 0 01-1 1h-6.817a1 1 0 01-1-1V98.461z"
        fill="#F3AD3D"
      />
      <path
        d="M12.756 110.583a1 1 0 011-1h100.489a1 1 0 011 1v6.541a1 1 0 01-1 1H13.755a1 1 0 01-1-1v-6.541z"
        fill="#F3AD3D"
      />
      <path
        d="M101.641 69.515c.333-.844 1.527-.844 1.86 0l11.893 30.162a1 1 0 01-.931 1.366H90.679a1 1 0 01-.93-1.367l11.892-30.16zM24.498 69.515c.333-.844 1.528-.844 1.86 0l11.893 30.162a1 1 0 01-.93 1.366H13.536a1 1 0 01-.93-1.367l11.892-30.16z"
        fill="#329C7F"
      />
    </Svg>
  )
}

const SvgOffice = styled(BaseComponent)`
  outline: none;
`
export default SvgOffice
