import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM3.858 3.151a5.673 5.673 0 017.992 7.992L3.857 3.15zm-.707.707a5.673 5.673 0 007.992 7.992L3.15 3.857z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCircleBackslash = styled(BaseComponent)`
  outline: none;
`
export default SvgCircleBackslash
