import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.697.04a.5.5 0 01.296.542L8.09 6h4.41a.5.5 0 01.4.8l-6 8a.5.5 0 01-.893-.382L6.91 9H2.5a.5.5 0 01-.4-.8l6-8a.5.5 0 01.597-.16zM3.5 8h4a.5.5 0 01.493.582L7.33 12.56 11.5 7h-4a.5.5 0 01-.493-.582L7.67 2.44 3.5 8z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLightningBolt = styled(BaseComponent)`
  outline: none;
`
export default SvgLightningBolt
