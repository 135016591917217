import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.662 34.925H39.687v11.113c0 .876-.71 1.587-1.587 1.587h-6.35c-.877 0-1.587-.71-1.587-1.587V34.925H10.556c-.57 0-1.032.462-1.032 1.032v74.136c0 .57.462 1.032 1.032 1.032h101.124c.57 0 1.032-.462 1.032-1.032V35.957c0-.57-.462-1.032-1.032-1.032h-8.493v11.113c0 .876-.711 1.587-1.588 1.587h-6.35c-.877 0-1.588-.71-1.588-1.587V34.925z"
        fill="#329C7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.662 38.1H39.687v6.35c0 .877-.71 1.587-1.587 1.587h-6.35c-.877 0-1.587-.71-1.587-1.587V38.1H15.319c-.57 0-1.032.462-1.032 1.032v74.136c0 .57.462 1.032 1.032 1.032h101.124c.57 0 1.032-.462 1.032-1.032V39.132c0-.57-.462-1.032-1.032-1.032h-13.255v6.35c0 .877-.711 1.587-1.588 1.587h-6.35c-.877 0-1.588-.71-1.588-1.587V38.1z"
        fill="#4A4A48"
      />
      <rect
        x={38.1}
        y={25.4}
        width={19.05}
        height={6.35}
        rx={1.032}
        transform="rotate(90 38.1 25.4)"
        fill="#329C7F"
      />
      <rect
        x={101.6}
        y={25.4}
        width={19.05}
        height={6.35}
        rx={1.032}
        transform="rotate(90 101.6 25.4)"
        fill="#329C7F"
      />
      <path
        d="M63.86 76.831c0 .229.152.381.38.381h3.125c.228 0 .38-.152.38-.38V51.684c0-.228-.152-.38-.38-.38h-2.858c-.266 0-.457.076-.686.228l-3.314 2.438c-.305.229-.381.381-.381.648v3.239c0 .304.228.38.419.228l3.238-2.286h.077v21.031zM51.318 98.212c.134 0 .223-.088.223-.222v-5.978l2.889-8.69c.044-.134 0-.223-.178-.223H52.34c-.156 0-.223.067-.267.223l-1.645 5.845h-.067l-1.689-5.845c-.044-.156-.11-.223-.266-.223H46.45c-.178 0-.222.09-.178.223l2.89 8.69v5.978c0 .134.088.222.222.222h1.933zm5.15-.222c0 .134.09.222.223.222h6.2c.134 0 .223-.088.223-.222V96.28c0-.133-.089-.222-.222-.222H58.98c-.089 0-.133-.045-.133-.134V91.79c0-.088.044-.133.133-.133h3.378c.134 0 .223-.089.223-.222v-1.712c0-.133-.09-.222-.223-.222H58.98c-.089 0-.133-.044-.133-.133v-3.978c0-.09.044-.134.133-.134h3.912c.133 0 .222-.089.222-.222v-1.711c0-.134-.089-.223-.222-.223H56.69c-.133 0-.222.09-.222.223V97.99zm8.22 0c-.021.134.045.222.179.222h1.889c.155 0 .222-.088.244-.222l.6-3.045h3l.6 3.045c.023.134.09.222.245.222h1.89c.133 0 .2-.088.177-.222l-3.2-14.668c-.022-.134-.111-.223-.245-.223H68.2c-.133 0-.2.09-.244.223L64.689 97.99zm3.29-5.045l1.111-5.623h.067l1.067 5.623h-2.245zm15.444 5.267c.111 0 .156-.11.111-.222L81.4 91.456c1.133-.645 1.8-1.89 1.8-3.867 0-3.312-1.623-4.49-4.312-4.49h-3.044c-.134 0-.223.09-.223.223V97.99c0 .134.09.222.223.222h1.933c.133 0 .222-.088.222-.222v-5.823c0-.089.045-.133.134-.133h1.133l1.845 6.023c.044.111.11.155.244.155h2.067zm-4.511-8.29h-.778c-.09 0-.134-.044-.134-.133v-4.4c0-.09.045-.134.134-.134h.778c1.333 0 1.91.556 1.91 2.334 0 1.8-.577 2.334-1.91 2.334z"
        fill="#F3AD3D"
      />
    </Svg>
  )
}

const SvgCalendar = styled(BaseComponent)`
  outline: none;
`
export default SvgCalendar
