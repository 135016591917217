import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M11 0c6.1 0 11 4.9 11 11s-4.9 11-11 11S0 17.1 0 11A11 11 0 0111 0zM5.1 11.9l5.4 5.8.5.2.5-.2 5.4-5.8c.3-.3.3-.8 0-1a.8.8 0 00-1.1 0l-4.1 4.4V4.8c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v10.5l-4.1-4.4c-.3-.3-.7-.3-1 0-.4.3-.4.7-.1 1z"
      />
    </Svg>
  )
}

const SvgArrowDownCircle = styled(BaseComponent)`
  outline: none;
`
export default SvgArrowDownCircle
