import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.95 2.75a.55.55 0 111.1 0v4.533a.5.5 0 01.217-.225l9-4.75A.5.5 0 0113 2.75v9.5a.5.5 0 01-.733.442l-9-4.75a.5.5 0 01-.217-.225v4.533a.55.55 0 01-1.1 0v-9.5zM4.57 7.5L12 11.42V3.58L4.571 7.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgTrackPrevious = styled(BaseComponent)`
  outline: none;
`
export default SvgTrackPrevious
