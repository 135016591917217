import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v11a.5.5 0 01-.765.424L7.5 11.59l-3.735 2.334A.5.5 0 013 13.5v-11zM4 3v9.598l2.97-1.856a1 1 0 011.06 0L11 12.598V3H4z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgBookmark = styled(BaseComponent)`
  outline: none;
`
export default SvgBookmark
