import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.877 7.502a6.625 6.625 0 1113.25 0 6.625 6.625 0 01-13.25 0zM1.85 7A5.676 5.676 0 017 1.849V4.5a.5.5 0 101 0V1.849A5.677 5.677 0 0113.155 7H10.5a.5.5 0 000 1h2.655A5.676 5.676 0 018 13.155V10.5a.5.5 0 00-1 0v2.655A5.677 5.677 0 011.849 8H4.5a.5.5 0 000-1H1.849z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCrosshair1 = styled(BaseComponent)`
  outline: none;
`
export default SvgCrosshair1
