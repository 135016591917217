import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM1.827 7.5a5.673 5.673 0 1111.346 0 5.673 5.673 0 01-11.346 0zm6.546 0a.873.873 0 11-1.745 0 .873.873 0 011.745 0zm.95 0a1.823 1.823 0 11-3.645 0 1.823 1.823 0 013.645 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDisc = styled(BaseComponent)`
  outline: none;
`
export default SvgDisc
