import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85 7.5c0-.287.021-.569.063-.844l7.65-4.417c.3.117.587.26.859.424L3.77 6.503a.447.447 0 00-.234.374.447.447 0 00.196.394l3.592 2.567-3.453 1.994A5.638 5.638 0 011.85 7.5zm6.518 2.775a.42.42 0 00.025-.014l1.448-.836a.448.448 0 00.018-.01l1.451-.838a.45.45 0 00.052-.756L7.828 5.2l3.388-1.957a5.637 5.637 0 011.849 5.24L5.569 12.81a5.623 5.623 0 01-.882-.41l3.681-2.126zm-.187-.931L4.817 6.939l.692-.4 3.297 2.444-.625.36zM6.36 6.048l.62-.357 3.296 2.444-.62.358L6.36 6.048zm1.973-4.137l-6.09 3.515a5.652 5.652 0 016.09-3.515zM6.847 13.113a5.652 5.652 0 005.842-3.373l-5.842 3.373zM7.5.85a6.65 6.65 0 100 13.3 6.65 6.65 0 000-13.3z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgStitchesLogo = styled(BaseComponent)`
  outline: none;
`
export default SvgStitchesLogo
