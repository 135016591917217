import React, { forwardRef } from 'react'

import FormItem, { Props as FormItemProps } from '../../Forms/FormItem'
import { BasicSelect, Props as SelectProps } from './BasicSelect'

export type Props = SelectProps & Omit<FormItemProps, 'children'>

const Select = forwardRef<HTMLInputElement, Props>(
  (
    { id, label, error, labelProps, errorProps, helperText, name, ...props },
    ref
  ) => (
    <FormItem
      id={id}
      error={error}
      label={label}
      labelProps={labelProps}
      errorProps={errorProps}
      helperText={helperText}
      name={name}
    >
      <BasicSelect ref={ref} id={id} name={name} {...props} />
    </FormItem>
  )
)

export default Select
