import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 13.25h13v1.5H1v-1.5z"
        fill="currentColor"
      />
      <rect x={7} y={5} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={13} y={5} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={7} y={3} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={13} y={3} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={7} y={7} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={7} y={1} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={13} y={7} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={13} y={1} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={5} y={7} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={5} y={1} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={3} y={7} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={3} y={1} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={9} y={7} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={9} y={1} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={11} y={7} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={11} y={1} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={7} y={9} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={13} y={9} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={7} y={11} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={13} y={11} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={1} y={5} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={1} y={3} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={1} y={7} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={1} y={1} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={1} y={9} width={1} height={1} rx={0.5} fill="currentColor" />
      <rect x={1} y={11} width={1} height={1} rx={0.5} fill="currentColor" />
    </Svg>
  )
}

const SvgBorderBottom = styled(BaseComponent)`
  outline: none;
`
export default SvgBorderBottom
