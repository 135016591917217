import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5.5A.5.5 0 016 0h3a.5.5 0 010 1H8v1.12a6.363 6.363 0 012.992 1.016.638.638 0 01.066-.078l1-1a.625.625 0 01.884.884l-.975.975A6.4 6.4 0 117 2.119V1H6a.5.5 0 01-.5-.5zm-3.4 8a5.4 5.4 0 1110.8 0 5.4 5.4 0 01-10.8 0zm5.4 0V4.1a4.4 4.4 0 103.111 7.511L7.5 8.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLapTimer = styled(BaseComponent)`
  outline: none;
`
export default SvgLapTimer
