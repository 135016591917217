import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.675 3.5a.45.45 0 01.45-.45h4.5a.45.45 0 110 .9h-1.62l-1.774 7.1h1.644a.45.45 0 010 .9h-4.5a.45.45 0 110-.9h1.619l1.775-7.1H6.125a.45.45 0 01-.45-.45z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFontItalic = styled(BaseComponent)`
  outline: none;
`
export default SvgFontItalic
