import React, { useState, forwardRef, useEffect } from 'react'

import { css } from '@styled-system/css'
import styled from 'styled-components'

import { Box } from '../../atoms/Box'
import { Flex } from '../../atoms/Flex'
import { Text } from '../../atoms/Typography'
import { Props as InputProps, StyledInput, StyledLabel } from './Checkbox'

export type Props = {
  options?: React.ReactNode[]
} & InputProps

const SwitchWrapper = styled(Box)<Props>(() =>
  css({
    userSelect: 'none',
  })
)

const SwitchInner = styled(Flex)<{
  hasError?: boolean
}>(({ hasError }) =>
  css({
    bg: hasError ? 'red' : 'white',
    boxShadow: 0,
  })
)

const SwitchBlock = styled(Text).attrs({ variant: 'ButtonExtraLarge' })<{
  checked: boolean
}>(({ checked }) =>
  css({
    flex: '1 0 auto',
    padding: 2,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 500ms',
    textTransform: 'uppercase',
    visibility: checked ? 'visible' : 'hidden',
    opacity: +checked,
  })
)

const Switch = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      name,
      checked,
      error,
      defaultChecked,
      onChange,
      disabled,
      labelProps,
      children,
      options = ['on', 'off'],
      ...props
    },
    ref
  ) => {
    const [internalChecked, setInternalChecked] = useState(
      checked ?? defaultChecked
    )

    useEffect(() => {
      setInternalChecked(checked ?? internalChecked)
    }, [checked, internalChecked])

    return (
      <SwitchWrapper aria-checked={internalChecked}>
        <StyledInput
          as="input"
          type="checkbox"
          id={id}
          name={name}
          checked={internalChecked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInternalChecked(event?.target.checked)
            onChange?.(event)
          }}
          disabled={disabled}
          ref={ref}
          css={{
            visibility: 'hidden',
          }}
          {...props}
        />
        <StyledLabel
          htmlFor={name}
          {...labelProps}
          disabled={disabled}
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e: React.KeyboardEvent<HTMLLabelElement>) => {
            e.preventDefault()
            if (e.key === 'Enter' || e.key === ' ') {
              setInternalChecked(!internalChecked)
              onChange?.({
                target: {
                  checked: !internalChecked,
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          }}
        >
          <SwitchInner
            tabIndex={-1}
            justifyContent="space-between"
            width={1}
            flex={1}
            hasError={!!error}
          >
            <SwitchBlock
              bg="success"
              color={disabled ? 'green.2' : 'white'}
              checked={!internalChecked}
            >
              {options[0]}
            </SwitchBlock>

            <SwitchBlock
              bg="earth.0"
              color={disabled ? 'earth.2' : 'primary'}
              checked={!!internalChecked}
            >
              {options[1]}
            </SwitchBlock>
          </SwitchInner>
          {children && <Box mx={4}>{children}</Box>}
        </StyledLabel>
      </SwitchWrapper>
    )
  }
)

export default Switch
