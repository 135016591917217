import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1a.5.5 0 000 1H12v11H4.5a.5.5 0 000 1H12a1 1 0 001-1V2a1 1 0 00-1-1H4.5zm2.104 3.896a.5.5 0 10-.708.708L7.293 7H.5a.5.5 0 000 1h6.793L5.896 9.396a.5.5 0 00.708.708l2.25-2.25a.5.5 0 000-.708l-2.25-2.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgEnter = styled(BaseComponent)`
  outline: none;
`
export default SvgEnter
