import * as React from 'react'

import css from '@styled-system/css'
import styled from 'styled-components'

import { Box, BoxProps } from '../../atoms/Box'
import { WithChildren, Position } from '../../utils/types'

const DEG = '45deg'
const ROTATE = `rotate(${DEG})`
const NEGATIVE_ROTATE = `rotate(-${DEG})`

const transformMap = {
  'top-left': NEGATIVE_ROTATE,
  'top-right': ROTATE,
  'bottom-left': ROTATE,
  'bottom-right': NEGATIVE_ROTATE,
}

export type Props = WithChildren<
  {
    position: Position
    text: React.ReactNode
    hidden?: boolean
  } & Omit<BoxProps, 'position'>
>

type RibbonPlacement = {
  placement: 'top' | 'bottom'
  side: 'right' | 'left'
}

const getPlacement = (position: string): RibbonPlacement => {
  const result = position.split('-')

  return {
    placement: result[0] as RibbonPlacement['placement'],
    side: result[1] as RibbonPlacement['side'],
  }
}

const RibbonWrapper = styled(Box)<RibbonPlacement>(({ side, placement }) =>
  css({
    [side]: 0,
    position: 'absolute',
    [placement]: 0,
    height: 100,
    width: 100,
    overflow: 'hidden',
  })
)

const RibbonContainer = styled(Box)<RibbonPlacement>(({ side, placement }) =>
  css({
    [side]: -64,
    position: 'absolute',
    [placement]: 32,
    width: 206,
    transform: transformMap[`${placement}-${side}` as Props['position']],
    backgroundColor: 'success',
    textAlign: 'center',
    color: 'white',
  })
)

const Ribbon = ({
  children,
  text,
  position = 'top-left',
  hidden = false,
  ...boxProps
}: Props) => {
  if (hidden === true) {
    return <Box {...boxProps}>{children}</Box>
  }
  return (
    <Box position="relative" {...boxProps}>
      {children}
      <RibbonWrapper {...getPlacement(position)}>
        <RibbonContainer {...getPlacement(position)}>{text}</RibbonContainer>
      </RibbonWrapper>
    </Box>
  )
}

export default Ribbon
