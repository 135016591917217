import React from 'react'

import { Box } from '../../atoms/Box'
import { Flex, Props as FlexProps } from '../../atoms/Flex'
import { TextClamp } from '../../atoms/TextClamp'
import Avatar from '../../molecules/Avatar'

export type Props = {
  imagePath?: string
  altText?: string
  fullName: string
  positionTitle?: string
  phone?: string
} & FlexProps

const Person = ({
  imagePath,
  altText,
  fullName,
  positionTitle,
  phone,
  ...flexBoxProps
}: Props) => (
  <Flex alignItems="center" {...flexBoxProps}>
    <Avatar
      size={50}
      src={imagePath}
      alt={altText || fullName}
      mr={[3, 4]}
      name={fullName}
      flexShrink={0}
    />
    <Box>
      <TextClamp
        linesShown={2}
        fontSize="18px"
        fontWeight="normal"
        lineHeight={1}
        fontFamily="heading"
        textTransform="uppercase"
        flex="1 0 auto"
      >
        {fullName}
      </TextClamp>
      {positionTitle && (
        <TextClamp linesShown={1} fontSize="14px" lineHeight="1">
          {positionTitle}
        </TextClamp>
      )}
      {phone && (
        <TextClamp linesShown={1} fontSize="14px" lineHeight="1">
          {phone}
        </TextClamp>
      )}
    </Box>
  </Flex>
)

export default Person
