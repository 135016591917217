import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-10zM1 9.5a1.5 1.5 0 001 1.415v.585A1.5 1.5 0 003.5 13h8a1.5 1.5 0 001.5-1.5v-.585A1.5 1.5 0 0014 9.5v-6A1.5 1.5 0 0012.5 2h-10A1.5 1.5 0 001 3.5v6zm11 2V11H3v.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5zM5.5 6a.5.5 0 000 1h4a.5.5 0 000-1h-4z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCardStackMinus = styled(BaseComponent)`
  outline: none;
`
export default SvgCardStackMinus
