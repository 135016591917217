import React from 'react'

import { ChevronDown, ChevronUp } from '@linus-capital/icons'

import { Box } from '../../atoms/Box'
import { IconButton } from '../../atoms/Button'

const IconCollapsed = <ChevronDown width={20} height={20} />

const IconExpanded = <ChevronUp width={20} height={20} />

export const CollapseStatusIcon = ({ isExpanded }: { isExpanded: boolean }) => (
  <Box display="flex" flexShrink={0}>
    <IconButton icon={isExpanded ? IconExpanded : IconCollapsed} />
  </Box>
)
