import * as React from 'react'

import { Property } from 'csstype'
import { ResponsiveValue } from 'styled-system'

import { ButtonSize } from './Button'
import { Props, TransparentButton } from './TransparentButton'

const DISABLED_BUTTON_TRANSPARENCY = 0.7

const sizePaddingMap: Record<
  ButtonSize,
  ResponsiveValue<Property.Padding<number>>
> = {
  small: 0,
  large: 2,
}

const IconButton = ({
  icon,
  size = 'large',
  disabled,
  ...props
}: Props & {}) => (
  <TransparentButton
    as="button"
    variant="transparent"
    disabled={disabled}
    px={sizePaddingMap[size as ButtonSize]}
    py={sizePaddingMap[size as ButtonSize]}
    css={{ opacity: disabled ? DISABLED_BUTTON_TRANSPARENCY : 1 }}
    {...props}
  >
    {icon}
  </TransparentButton>
)

export default IconButton
