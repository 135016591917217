import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      viewBox="0 0 50 50"
      {...props}
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from={0}
        to={360}
        dur="11s"
        repeatCount="indefinite"
      />
      <g>
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          calcMode="spline"
          values="0 25 25; 360 25 25"
          keyTimes="0 ; 1"
          keySplines="0.34 0.79 0.54 0.86"
          repeatCount="indefinite"
        />
        <path
          d="M25 0a25 25 0 1025 25A25 25 0 0025 0zm0 46.5A21.5 21.5 0 1146.5 25 21.51 21.51 0 0125 46.5z"
          fill="#fff"
        />
        <path
          d="M25 0v3.5A21.51 21.51 0 0146.5 25H50A25 25 0 0025 0z"
          fill="#4a4a48"
        />
      </g>
    </Svg>
  )
}

const SvgSpinner = styled(BaseComponent)`
  outline: none;
`
export default SvgSpinner
