import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.5a.5.5 0 00-1 0V7c0 .017 0 .033.002.05a2.5 2.5 0 000 4.9A.506.506 0 004 12v1.5a.5.5 0 001 0V12c0-.017 0-.033-.002-.05a2.5 2.5 0 000-4.9A.507.507 0 005 7V1.5zm6 0a.5.5 0 00-1 0V3c0 .017 0 .033.002.05a2.5 2.5 0 000 4.9A.507.507 0 0010 8v5.5a.5.5 0 001 0V8c0-.017 0-.033-.002-.05a2.5 2.5 0 000-4.9A.507.507 0 0011 3V1.5zM4.5 8a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM9 5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgMixerVertical = styled(BaseComponent)`
  outline: none;
`
export default SvgMixerVertical
