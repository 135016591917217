import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 4a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5H.5zm.5 6V5h1.075v2.5a.425.425 0 00.85 0V5h1.15v1.5a.425.425 0 00.85 0V5h1.15v1.5a.425.425 0 00.85 0V5h1.15v2.5a.425.425 0 00.85 0V5h1.15v1.5a.425.425 0 00.85 0V5h1.15v1.5a.425.425 0 00.85 0V5H14v5H1z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgRulerHorizontal = styled(BaseComponent)`
  outline: none;
`
export default SvgRulerHorizontal
