import React from 'react'

import { TransitionGroup } from 'react-transition-group'

import { Box } from '../../atoms/Box'
import { FadeIn } from '../../molecules/Transition'
import Toast from './Toast'
import { ToastPosition, ToastState } from './Toast.type'

type Props = {
  toastState: ToastState
}

const getStyle = (position: ToastPosition): React.CSSProperties => {
  const isTopOrBottom = position.includes('top') || position.includes('bottom')
  const margin = isTopOrBottom ? '0 auto' : undefined

  const top = position.includes('top')
    ? 'env(safe-area-inset-top, 0px)'
    : undefined
  const bottom = position.includes('bottom')
    ? 'env(safe-area-inset-bottom, 0px)'
    : undefined
  const right = !position.includes('left')
    ? 'env(safe-area-inset-right, 0px)'
    : undefined
  const left = !position.includes('right')
    ? 'env(safe-area-inset-left, 0px)'
    : undefined

  return {
    position: 'fixed',
    zIndex: 5500,
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    margin,
    top,
    bottom,
    right,
    left,
  }
}

const ToastManager = ({ toastState }: Props) => (
  <TransitionGroup component={Box}>
    {Object.keys(toastState).map((position) => {
      const toasts = toastState[position as ToastPosition]

      return (
        <FadeIn open={Boolean(toasts.length)} key={position}>
          <Box style={getStyle(position as ToastPosition)}>
            {toasts.map((toast) => (
              <Toast key={toast.id} {...toast} />
            ))}
          </Box>
        </FadeIn>
      )
    })}
  </TransitionGroup>
)

export default ToastManager
