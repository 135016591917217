import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.292.049a.5.5 0 01.535.077L12.86 7.95a.5.5 0 01-.306.878l-3.334.147 1.931 4.244a.5.5 0 01-.247.662l-2.153.98a.5.5 0 01-.662-.247L6.153 10.37l-2.29 2.416A.5.5 0 013 12.44V.504a.5.5 0 01.292-.455zM4 1.599v9.589l1.938-2.044a.5.5 0 01.818.137l2.035 4.463 1.242-.566-2.031-4.463a.5.5 0 01.433-.707l2.82-.124L4 1.599z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCursorArrow = styled(BaseComponent)`
  outline: none;
`
export default SvgCursorArrow
