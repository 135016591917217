import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1.05a.45.45 0 100 .9h12a.45.45 0 100-.9h-12zm2.432 6.382a.45.45 0 10.636.636L7.05 5.586V13.5a.45.45 0 00.9 0V5.586l2.482 2.482a.45.45 0 10.636-.636l-3.25-3.25a.45.45 0 00-.636 0l-3.25 3.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPinTop = styled(BaseComponent)`
  outline: none;
`
export default SvgPinTop
