import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08.222a.6.6 0 01.84 0l6.75 6.64a.6.6 0 01-.84.856L13 6.902V12.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5V6.902l-.83.816a.6.6 0 11-.84-.856L7.08.222zm.42 1.27L12 5.918V12h-2V8.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V12H3V5.918l4.5-4.426zM7 12h2V9H7v3z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgHome = styled(BaseComponent)`
  outline: none;
`
export default SvgHome
