import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M54.8 65.6s-6.9-1.3-9.7 0c-2 .9-9.3 3.5-13.4 5a1 1 0 00-.7 1v24.8c0 .5.5 1 1 1 2.5-.2 6.2-.4 7.8-.3 11.3.5 17 5.2 28.3 6 6.3.5 11.8 1.7 16.2 0 4.3-1.5 29.2-13.6 32.3-16.8 1.4-.8-3.1-12.6-6.6-11.3-9.1 3.4-23.9 10.3-29.1 10-5.3-.3-19.3-2.9-19.3-2.9l16 .2s2.3.2 5.4-2c3.1-2.3 6-8.7 1.3-8.7S74.6 70 74.6 70l-19.8-4.4zM47 41.4L62.8 15l5.9 10.3v1L58 42.4a1 1 0 01-.8.5h-9.5a1 1 0 01-.9-1.5z"
        fill="#4A4A48"
      />
      <path
        d="M58.3 59.4a1 1 0 01-.8-1V42.7L72.3 20l14.2 22.2V41h23.3v17.4c0 .5-.3.9-.8 1l-22.3 6a1 1 0 01-.5 0l-28-6z"
        fill="#F3AD3D"
      />
      <path
        d="M76.5 41.4L62.3 15h42.1c.4 0 .7.2.9.5l13.9 25a1 1 0 01-.9 1.4h-41a1 1 0 01-.8-.5z"
        fill="#329C7F"
      />
      <rect x={10} y={67} width={16} height={35} rx={1} fill="#329C7F" />
    </Svg>
  )
}

const SvgInvest = styled(BaseComponent)`
  outline: none;
`
export default SvgInvest
