import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={24.379}
        y={58.836}
        width={15.945}
        height={52.088}
        rx={1.063}
        fill="#329C7F"
      />
      <rect
        x={88.159}
        y={43.954}
        width={15.945}
        height={66.971}
        rx={1.063}
        fill="#329C7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.262 18.44c0-.587.476-1.063 1.063-1.063H88.16c.587 0 1.063.476 1.063 1.064v91.419c0 .588-.476 1.063-1.063 1.063H40.325a1.062 1.062 0 01-1.063-1.063V18.44zm9.567 53.151c0-.587.476-1.063 1.063-1.063h7.441c.587 0 1.063.476 1.063 1.063v9.568c0 .587-.476 1.063-1.063 1.063h-7.44a1.063 1.063 0 01-1.064-1.063V71.59zm9.567-18.071c0-.588-.476-1.064-1.063-1.064h-7.44c-.588 0-1.064.476-1.064 1.063v9.568c0 .587.476 1.063 1.063 1.063h7.441c.587 0 1.063-.476 1.063-1.063v-9.568zM48.83 35.448c0-.587.476-1.063 1.063-1.063h7.441c.587 0 1.063.476 1.063 1.064v9.567c0 .587-.476 1.063-1.063 1.063h-7.44a1.063 1.063 0 01-1.064-1.063v-9.567zm29.765 36.143c0-.587-.476-1.063-1.063-1.063H70.09c-.587 0-1.063.476-1.063 1.063v9.568c0 .587.476 1.063 1.063 1.063h7.44c.588 0 1.064-.476 1.064-1.063V71.59zM69.027 53.52c0-.588.476-1.064 1.063-1.064h7.44c.588 0 1.064.476 1.064 1.063v9.568c0 .587-.476 1.063-1.063 1.063H70.09a1.063 1.063 0 01-1.063-1.063v-9.568zm9.567-18.072c0-.587-.476-1.063-1.063-1.063H70.09c-.587 0-1.063.476-1.063 1.064v9.567c0 .587.476 1.063 1.063 1.063h7.44c.588 0 1.064-.476 1.064-1.063v-9.567z"
        fill="#4A4A48"
      />
      <rect
        x={45.638}
        y={11}
        width={37.206}
        height={9.567}
        rx={1.063}
        fill="#4A4A48"
      />
      <rect
        x={18}
        y={109.861}
        width={91.42}
        height={7.441}
        rx={1.063}
        fill="#F3AD3D"
      />
      <path
        d="M58.395 96.041c0-.587.476-1.063 1.063-1.063h10.63c.588 0 1.064.476 1.064 1.064v13.819c0 .587-.476 1.063-1.063 1.063h-10.63a1.063 1.063 0 01-1.064-1.063v-13.82z"
        fill="#F3AD3D"
      />
    </Svg>
  )
}

const SvgBuilding1 = styled(BaseComponent)`
  outline: none;
`
export default SvgBuilding1
