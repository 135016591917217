export const breakpointIdentifiersList = ['sm', 'md', 'lg', 'xl'] as const

export type breakIdentifiers = typeof breakpointIdentifiersList[number]

type BreakpointRange = {
  minWidth: string
  maxWidth: string
}

export const breakpointIdentifiersCount = breakpointIdentifiersList.length

export const themeBreakPoints = [768, 1024, 1440]

export const createMediaQueries = (breakpoints: string[]) =>
  breakpoints.map((n) => `@media screen and (min-width:${n})`)

const getMaxWidth = (px: number) => px - 1

export const mapIndexToBreakpointIdentifier = (
  index: number
): breakIdentifiers => {
  switch (index) {
    case 0:
      return 'sm'
    case 1:
      return 'md'
    case 2:
      return 'lg'
    case 3:
      return 'xl'
  }
  return 'sm'
}

export const mapBreakpointIdentifierToIndex = (
  bp: breakIdentifiers
): number => {
  switch (bp) {
    case 'sm':
      return 0
    case 'md':
      return 1
    case 'lg':
      return 2
    case 'xl':
      return 3
  }
  return 0
}

export const breakpointsWidthMap: {
  readonly [bp in breakIdentifiers]: BreakpointRange
} = {
  sm: {
    minWidth: '0',
    maxWidth: `${getMaxWidth(themeBreakPoints[0])}px`,
  },
  md: {
    minWidth: `${themeBreakPoints[0]}px`,
    maxWidth: `${getMaxWidth(themeBreakPoints[1])}px`,
  },
  lg: {
    minWidth: `${themeBreakPoints[1]}px`,
    maxWidth: `${getMaxWidth(themeBreakPoints[2])}px`,
  },
  xl: {
    minWidth: `${themeBreakPoints[2]}px`,
    maxWidth: '32767px', // 2 ^ 15 - 1 - max 16bits signed integer
  },
}

// |<--- sm ---o                                  |
// |           <--- md ---o                       |
// |                      <--- lg ---o            |
// |                                  <--- xl ---o|

export const breakpoints = () => ({
  sm: `@media screen and (max-width: ${getMaxWidth(themeBreakPoints[0])}px)`,
  md: `@media screen and (min-width: ${
    themeBreakPoints[0]
  }px) and (max-width: ${getMaxWidth(themeBreakPoints[1])}px)`,
  lg: `@media screen and (min-width: ${
    themeBreakPoints[1]
  }px) and (max-width: ${getMaxWidth(themeBreakPoints[2])}px)`,
  xl: `@media screen and (min-width: ${getMaxWidth(themeBreakPoints[2])}px)`,
})

// |<--- sm -----------------------------------------------|
// |           <--- md ------------------------------------|
// |                      <--- lg -------------------------|
// |                                 <--- xl --------------|

export const breakpointsMobileFirst = () => ({
  sm: `@media screen and (min-width: 0)`, // can't leave it blank - hence min-width: 0
  md: `@media screen and (min-width: ${themeBreakPoints[0]}px)`,
  lg: `@media screen and (min-width: ${themeBreakPoints[1]}px)`,
  xl: `@media screen and (min-width: ${themeBreakPoints[2]}px)`,
})
