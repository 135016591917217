import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1 3c.428 0 .72 0 .944.019.22.018.332.05.41.09a1 1 0 01.437.437c.04.078.072.19.09.41.019.225.019.516.019.944v.6a.5.5 0 001 0v-.62c0-.403 0-.735-.022-1.006-.023-.281-.072-.54-.196-.782a2 2 0 00-.874-.874c-.243-.124-.501-.173-.782-.196C10.856 2 10.523 2 10.12 2H9.5a.5.5 0 000 1h.6zM5.5 2h-.62c-.403 0-.735 0-1.006.022-.281.023-.54.072-.782.196a2 2 0 00-.874.874c-.124.243-.173.501-.196.782C2 4.144 2 4.477 2 4.88V5.5a.5.5 0 001 0v-.6c0-.428 0-.72.019-.944.018-.22.05-.332.09-.41a1 1 0 01.437-.437c.078-.04.19-.072.41-.09C4.18 3 4.472 3 4.9 3h.6a.5.5 0 000-1zM13 9.5a.5.5 0 00-1 0v.6c0 .428 0 .72-.019.944-.018.22-.05.332-.09.41a1 1 0 01-.437.437c-.078.04-.19.072-.41.09-.225.019-.516.019-.944.019h-.6a.5.5 0 000 1h.62c.403 0 .735 0 1.006-.022.281-.023.54-.072.782-.196a2 2 0 00.874-.874c.124-.243.173-.501.196-.782.022-.27.022-.603.022-1.005V9.5zM2.5 9a.5.5 0 01.5.5v.6c0 .428 0 .72.019.944.018.22.05.332.09.41a1 1 0 00.437.437c.078.04.19.072.41.09.225.019.516.019.944.019h.6a.5.5 0 010 1h-.62c-.403 0-.735 0-1.006-.022-.281-.023-.54-.072-.782-.196a2 2 0 01-.874-.874c-.124-.243-.173-.501-.196-.782C2 10.856 2 10.523 2 10.12V9.5a.5.5 0 01.5-.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCorners = styled(BaseComponent)`
  outline: none;
`
export default SvgCorners
