export const INPUT_HEIGHT = 48

export const PAGE_CONTAINER_MAX_WIDTH = 1440

export const grid: Record<number, number[]> = {
  0: [0, 0, 0, 0],
  1: [20, 65, 65, 73],
  2: [40, 130, 130, 146],
  3: [60, 195, 195, 219],
  4: [80, 260, 260, 292],
  5: [100, 325, 325, 365],
  6: [120, 390, 390, 438],
  7: [140, 455, 455, 511],
}

export const gridMix = (sm: number, md: number, lg: number, xl: number) => [
  grid[sm][0],
  grid[md][1],
  grid[lg][2],
  grid[xl][3],
]
