import * as React from 'react'

import { Box, BoxProps } from '../atoms/Box'
import { PAGE_CONTAINER_MAX_WIDTH } from '../utils/constants'
import { WithChildren } from '../utils/types'

export const PageContainer = ({
  children,
  ...boxProps
}: WithChildren &
  Omit<BoxProps, 'maxWidth' | 'margin' | 'marginLeft' | 'marginRight'>) => (
  <Box
    maxWidth={PAGE_CONTAINER_MAX_WIDTH}
    width="auto"
    margin="0 auto"
    {...boxProps}
  >
    {children}
  </Box>
)
