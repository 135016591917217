import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.782 2.217a.4.4 0 00-.565 0l-2 2a.4.4 0 00.565.566L2.1 3.466v8.068L.782 10.217a.4.4 0 00-.565.566l2 2a.4.4 0 00.565 0l2-2a.4.4 0 00-.565-.566l-1.318 1.317V3.466l1.318 1.317a.4.4 0 00.565-.566l-2-2zm7.718.533a.5.5 0 01.47.33l3 8.32a.5.5 0 01-.94.34l-.982-2.724H8.952l-.982 2.723a.5.5 0 01-.94-.34l3-8.319a.5.5 0 01.47-.33zm0 1.974l1.241 3.442H9.26L10.5 4.724z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgFontSize = styled(BaseComponent)`
  outline: none;
`
export default SvgFontSize
