import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.5C3 7.983 4.992 9 7 9v3.5a.5.5 0 001 0V3.1h1v9.4a.5.5 0 001 0V3.1h1.5a.55.55 0 100-1.1H7C4.992 2 3 3.017 3 5.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgPilcrow = styled(BaseComponent)`
  outline: none;
`
export default SvgPilcrow
