import { breakpointsWidthMap } from './createMediaQueries'

type Direction = 'UP' | 'DOWN'

const directMap = {
  UP: 'min-width',
  DOWN: 'max-width',
}

export const media = (direction: Direction) =>
  (Object.keys(breakpointsWidthMap) as Array<
    keyof typeof breakpointsWidthMap
  >).reduce((acc, key) => {
    acc[key] = (style: String) =>
      `@media (${directMap[direction]}: ${breakpointsWidthMap[key].maxWidth}) { ${style} }`
    return acc
  }, {} as { [index: string]: Function })
