import * as React from 'react'

import { Cross1 } from '@linus-capital/icons'
import styled from 'styled-components'
import { variant } from 'styled-system'

import { Box } from '../../atoms/Box'
import { IconButton } from '../../atoms/Button'
import { Flex } from '../../atoms/Flex'
import { hexToRgb } from '../../utils/misc'
import { WithChildren, Status, TextAlign } from '../../utils/types'

export type Props = WithChildren<{
  icon?: React.ReactNode
  status?: Status
  onClose?: () => void
  hasClose?: boolean
  textAlign?: TextAlign
  padding?: number | number[]
  textMargin?: number | number[]
  alpha?: number
}>

const justifyContentToTextAlignmentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const BannerWrapper = styled(Flex)<Pick<Props, 'status' | 'alpha'>>(
  ({ theme, alpha }) =>
    variant({
      prop: 'status',
      variants: {
        info: {
          bg: hexToRgb(theme.colors.primary, alpha),
          color: 'white',
        },
        success: {
          bg: hexToRgb(theme.colors.success, alpha),
          color: 'white',
        },
        error: {
          bg: hexToRgb(theme.colors.red, alpha),
          color: 'white',
        },
        warning: {
          bg: hexToRgb(theme.colors.orange, alpha),
          color: 'white',
        },
      },
    })
)

const Banner = ({
  children,
  textAlign = 'left',
  status = 'info',
  icon,
  hasClose,
  onClose,
  padding = [1, 2, 3],
  textMargin = [2, 3, 4],
  alpha = 1,
}: Props) => (
  <BannerWrapper
    status={status}
    alpha={alpha}
    p={padding}
    justifyContent="space-between"
    alignItems="center"
  >
    <Flex
      flex="1 1"
      mx={textMargin}
      justifyContent={justifyContentToTextAlignmentMap[textAlign]}
      alignItems="center"
    >
      {icon && (
        <Box mr={3} lineHeight={1} flexShrink={0}>
          {icon}
        </Box>
      )}
      {children}
    </Flex>
    {hasClose && (
      <IconButton
        flex="0 0"
        flexBasis={[30, 40]}
        minWidth="0"
        icon={<Cross1 size={16} />}
        onClick={onClose}
      />
    )}
  </BannerWrapper>
)

export default Banner
