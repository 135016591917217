import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.877 7.5a6.623 6.623 0 1113.246 0 6.623 6.623 0 01-13.246 0zM7.5 1.827a5.673 5.673 0 100 11.346 5.673 5.673 0 000-11.346z"
        fill="currentColor"
      />
      <path
        opacity={0.05}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.162 3.852c-3.804 1.037-7.273 4.506-8.31 8.31l-.482-.132c1.084-3.974 4.686-7.577 8.66-8.66l.132.482z"
        fill="currentColor"
      />
      <path
        opacity={0.1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.88 3.427C8.035 4.505 4.507 8.034 3.428 11.88l-.481-.134c1.125-4.015 4.785-7.675 8.8-8.8l.135.481z"
        fill="currentColor"
      />
      <path
        opacity={0.15}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.52 3.026c-3.83 1.136-7.352 4.657-8.492 8.486l-.48-.143c1.189-3.99 4.839-7.638 8.83-8.823l.142.48z"
        fill="currentColor"
      />
      <path
        opacity={0.2}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.047 2.662C7.31 3.877 3.879 7.308 2.663 11.043l-.475-.154C3.453 7 7.004 3.45 10.892 2.186l.155.476z"
        fill="currentColor"
      />
      <path
        opacity={0.25}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.52 2.324c-3.6 1.29-6.896 4.585-8.192 8.183l-.47-.169c1.346-3.739 4.754-7.144 8.493-8.485l.17.47z"
        fill="currentColor"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.902 2.031C6.5 3.395 3.4 6.494 2.034 9.896l-.464-.187c1.417-3.528 4.617-6.728 8.146-8.142l.186.464z"
        fill="currentColor"
      />
      <path
        opacity={0.35}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.207 1.789C6.061 3.203 3.211 6.052 1.793 9.197l-.456-.205c1.468-3.257 4.408-6.194 7.665-7.66l.205.457z"
        fill="currentColor"
      />
      <path
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.407 1.62c-2.814 1.431-5.35 3.965-6.782 6.778l-.445-.226c1.48-2.908 4.092-5.519 7-6.997l.227.446z"
        fill="currentColor"
      />
      <path
        opacity={0.45}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.462 1.567c-2.375 1.38-4.508 3.512-5.89 5.887l-.432-.252c1.425-2.45 3.62-4.644 6.071-6.067l.251.432z"
        fill="currentColor"
      />
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.304 1.705a17.386 17.386 0 00-4.6 4.6l-.414-.28a17.886 17.886 0 014.734-4.734l.28.414z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgInnerShadow = styled(BaseComponent)`
  outline: none;
`
export default SvgInnerShadow
