import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.383 117a1 1 0 01-1 1H11a1 1 0 01-1-1V99a1 1 0 011-1h26.383a1 1 0 011 1v18zM78.197 116.999a1 1 0 01-1 1H50.813a1 1 0 01-1-1V99a1 1 0 011-1h26.384a1 1 0 011 1v17.999zM118.001 116.999a1 1 0 01-1 1H90.617a1 1 0 01-1-1V99a1 1 0 011-1h26.384a1 1 0 011 1v17.999z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgDotsWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgDotsWhite
