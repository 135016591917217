import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.818 4.182a.45.45 0 010 .636L6.136 7.5l2.682 2.682a.45.45 0 11-.636.636l-3-3a.45.45 0 010-.636l3-3a.45.45 0 01.636 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgCaretLeft = styled(BaseComponent)`
  outline: none;
`
export default SvgCaretLeft
