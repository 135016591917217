import React, { ReactNode, ReactElement, cloneElement, useEffect } from 'react'

import { css } from '@styled-system/css'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { LayoutProps } from 'styled-system'

import { Box } from '../../atoms/Box'
import { Flex } from '../../atoms/Flex'
import {
  usePortal,
  useToggle,
  useBreakpoints,
  BreakpointChangeEventListener,
} from '../../hooks'
import { WithChildren } from '../../utils/types'

type PositionRect = {
  left?: number | string
  right?: number | string
  top?: number | string
  bottom?: number | string
}

export type Props = WithChildren<
  {
    id: string
    buttonRenderFn: (
      isOpen: boolean,
      open: () => void,
      close?: () => void
    ) => ReactNode
    position?: (rect?: DOMRect) => PositionRect
  } & LayoutProps
>

const MOUSE_DOWN_EVENT = 'mousedown'
const TOUCH_START_EVENT = 'touchstart'
const SCROLL_EVENT = 'scroll'

const StyledPopover = styled(Flex)(() =>
  css({
    bg: 'white',
    position: 'absolute',
    boxShadow: 0,
  })
)

const Magnet = ({ children }: WithChildren) => (
  <Box zIndex={9999} position="fixed" top={0} left={0} width={0} height={0}>
    {children}
  </Box>
)

export const Popup = ({
  id,
  buttonRenderFn,
  children,
  position,
  ...props
}: Props) => {
  const [portalRef, rect, buttonRef] = usePortal(id)
  const { isOpen, handleToggle, handleClose: closePopup } = useToggle()

  const handleBreakpointChanged: BreakpointChangeEventListener = ({
    previous,
    current,
  }) => {
    if (
      (previous !== 'lg' && current === 'lg') ||
      (previous === 'lg' && current !== 'lg')
    ) {
      closePopup()
    }
  }

  useBreakpoints(handleBreakpointChanged)

  useEffect(() => {
    let isEventListenerAttached = false

    const handleScroll = () => {
      closePopup()
    }

    const handleMouseDown = (event: MouseEvent) => {
      const { target } = event
      if (
        target !== portalRef.current &&
        !portalRef.current?.contains(target as Element) &&
        target !== buttonRef.current &&
        !buttonRef.current?.contains(target as Element)
      ) {
        closePopup()
      }
    }

    const addEventListeners = () => {
      window.document.addEventListener(
        MOUSE_DOWN_EVENT,
        handleMouseDown as EventListener
      )
      window.document.addEventListener(
        TOUCH_START_EVENT,
        handleMouseDown as EventListener
      )
      window.document.addEventListener(SCROLL_EVENT, handleScroll)
    }

    const removeEventListeners = () => {
      window.document.removeEventListener(
        MOUSE_DOWN_EVENT,
        handleMouseDown as EventListener
      )
      window.document.removeEventListener(
        TOUCH_START_EVENT,
        handleMouseDown as EventListener
      )
      window.document.removeEventListener(SCROLL_EVENT, handleScroll)
    }

    if (isOpen) {
      addEventListeners()
      isEventListenerAttached = true
    } else {
      removeEventListeners()
      isEventListenerAttached = false
    }

    return () => {
      if (isEventListenerAttached) {
        removeEventListeners()
      }
    }
  }, [buttonRef, closePopup, isOpen, portalRef])

  const clientPosition = () =>
    position
      ? position(rect)
      : {
          top: rect?.bottom ?? 0,
          left: rect?.left ?? 0,
        }

  return (
    <>
      <Box id={id} {...props}>
        {buttonRenderFn(isOpen, handleToggle, closePopup)}
      </Box>
      {isOpen &&
        portalRef.current &&
        createPortal(
          <Magnet>
            <StyledPopover {...clientPosition()}>
              {children &&
                cloneElement(children as ReactElement<any>, {
                  ...(children as ReactElement<any>).props,
                  closePopup,
                  buttonRectangle: rect,
                })}
            </StyledPopover>
          </Magnet>,
          portalRef.current
        )}
    </>
  )
}
