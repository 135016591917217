import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M118 116.326a1 1 0 01-1 1H69.262a1 1 0 01-1-1v-13.632a1 1 0 011-1H117a1 1 0 011 1v13.632z"
        fill="#4A4A48"
      />
      <path
        d="M118 73.573a1 1 0 01-1 1H69.262a1 1 0 01-1-1V59.94a1 1 0 011-1H117a1 1 0 011 1v13.632z"
        fill="#329C7F"
      />
      <path
        d="M118 94.514a1 1 0 01-1 1H69.262a1 1 0 01-1-1V80.882a1 1 0 011-1H117a1 1 0 011 1v13.632z"
        fill="#F3AD3D"
      />
      <path
        d="M118 52.632a1 1 0 01-1 1H69.262a1 1 0 01-1-1V39a1 1 0 011-1H117a1 1 0 011 1v13.632zM59.738 116.326a1 1 0 01-1 1H11a1 1 0 01-1-1v-13.632a1 1 0 011-1h47.738a1 1 0 011 1v13.632z"
        fill="#4A4A48"
      />
      <path
        d="M59.738 73.573a1 1 0 01-1 1H11a1 1 0 01-1-1V59.94a1 1 0 011-1h47.738a1 1 0 011 1v13.632z"
        fill="#329C7F"
      />
      <path
        d="M59.738 94.514a1 1 0 01-1 1H11a1 1 0 01-1-1V80.882a1 1 0 011-1h47.738a1 1 0 011 1v13.632z"
        fill="#F3AD3D"
      />
      <path
        d="M59.816 52.632a1 1 0 01-1 1H11.078a1 1 0 01-1-1V39a1 1 0 011-1h47.738a1 1 0 011 1v13.632z"
        fill="#4A4A48"
      />
    </Svg>
  )
}

const SvgCoins1 = styled(BaseComponent)`
  outline: none;
`
export default SvgCoins1
