import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={34.784}
        y={78.092}
        width={29.879}
        height={23.297}
        rx={0.96}
        transform="rotate(-43.34 34.784 78.092)"
        fill="#4A4A48"
      />
      <rect
        x={45.837}
        y={31.137}
        width={40.796}
        height={76.769}
        rx={0.865}
        transform="rotate(-15 45.837 31.137)"
        fill="#F3AD3D"
      />
      <rect
        x={65.901}
        y={19}
        width={46.099}
        height={89.317}
        rx={0.887}
        fill="#329C7F"
      />
      <rect
        x={15}
        y={66.84}
        width={28.704}
        height={41.462}
        rx={0.96}
        fill="#329C7F"
      />
      <path
        d="M43.575 69.987h34.073v38.47h-26.39a7.683 7.683 0 01-7.683-7.683V69.987z"
        fill="#4A4A48"
      />
      <rect
        x={43.575}
        y={90.87}
        width={60.452}
        height={8.793}
        rx={0.96}
        fill="#4A4A48"
      />
      <rect
        x={54.57}
        y={100.761}
        width={41.767}
        height={7.694}
        rx={0.96}
        fill="#4A4A48"
      />
      <rect
        x={43.575}
        y={79.878}
        width={63.749}
        height={9.892}
        rx={0.96}
        fill="#4A4A48"
      />
      <rect
        x={43.575}
        y={69.987}
        width={58.254}
        height={8.793}
        rx={0.96}
        fill="#4A4A48"
      />
    </Svg>
  )
}

const SvgMoney = styled(BaseComponent)`
  outline: none;
`
export default SvgMoney
