import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118 27a1 1 0 00-1-1H11a1 1 0 00-1 1v74.605a1 1 0 001 1h106a1 1 0 001-1V27zm-5.063 5.46a1 1 0 00-1-1H16.055a1 1 0 00-1 1v63.685a1 1 0 001 1h95.882a1 1 0 001-1V32.46z"
        fill="#4A4A48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.002 72.302l.002-.001c.136-4.627 3.9-8.645 9.427-10.775a.473.473 0 01.548.166 11.18 11.18 0 009.01 4.55c3.563 0 6.736-1.664 8.787-4.257a.473.473 0 01.555-.147c5.105 2.196 8.531 6.052 8.661 10.46 0 .003.002.004.004.004s.004.002.004.004v8.236a.466.466 0 01-.466.466H23.466a.466.466 0 01-.466-.466v-8.238s0-.002.002-.002z"
        fill="#4A4A48"
      />
      <circle cx={42.131} cy={51.043} r={9.973} fill="#4A4A48" />
      <rect
        x={69.015}
        y={49.86}
        width={22.605}
        height={6.279}
        rx={1}
        fill="#F3AD3D"
      />
      <rect
        x={69.015}
        y={61.163}
        width={32.651}
        height={6.279}
        rx={1}
        fill="#329C7F"
      />
      <rect
        x={69.015}
        y={72.465}
        width={32.651}
        height={6.279}
        rx={1}
        fill="#329C7F"
      />
    </Svg>
  )
}

const SvgIdCard = styled(BaseComponent)`
  outline: none;
`
export default SvgIdCard
