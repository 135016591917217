import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" {...props}>
      <path
        d="M416 0H31.9C14.2.1-.1 14.6 0 32.3v383.4A32.1 32.1 0 0031.9 448H416c17.7-.1 32-14.6 32-32.3V32.3A32.3 32.3 0 00416 0zM135.4 384H69V170.2h66.5V384h-.1zm-33.2-243a38.5 38.5 0 110-77 38.5 38.5 0 010 77zm282.1 243h-66.4V280c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V384h-66.4V170.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V384z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLinkedin = styled(BaseComponent)`
  outline: none;
`
export default SvgLinkedin
