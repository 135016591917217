import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke="#000" d="M64.5 24v20" />
      <path
        d="M29.365 49.885h-10.96a1 1 0 00-1 1V96.01a1 1 0 001 1h10.96a1 1 0 001-1V50.885a1 1 0 00-1-1zM56.52 49.885H45.56a1 1 0 00-1 1V96.01a1 1 0 001 1h10.96a1 1 0 001-1V50.885a1 1 0 00-1-1zM83.675 49.885h-10.96a1 1 0 00-1 1V96.01a1 1 0 001 1h10.96a1 1 0 001-1V50.885a1 1 0 00-1-1zM110.212 49.885H98.635a1 1 0 00-1 1V96.01a1 1 0 001 1h11.577a1 1 0 001-1V50.885a1 1 0 00-1-1z"
        fill="#4A4A48"
      />
      <path
        d="M115.818 101.906H12.182c-1.205 0-2.182 1.055-2.182 2.354v10.593c0 1.299.977 2.354 2.182 2.354h103.636c1.205 0 2.182-1.055 2.182-2.354V104.26c0-1.299-.977-2.354-2.182-2.354z"
        fill="#F3AD3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 33.736a1 1 0 01.702-.955L64.2 16.094a1 1 0 01.601.001l52.503 16.683a1 1 0 01.697.953v10.215a1 1 0 01-1 1H11a1 1 0 01-1-1v-10.21z"
        fill="#329C7F"
      />
    </Svg>
  )
}

const SvgBuilding = styled(BaseComponent)`
  outline: none;
`
export default SvgBuilding
