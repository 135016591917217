import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.25}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h3v3H0V0zm6 3H3v3H0v3h3v3H0v3h3v-3h3v3h3v-3h3v3h3v-3h-3V9h3V6h-3V3h3V0h-3v3H9V0H6v3zm0 3V3h3v3H6zm0 3H3V6h3v3zm3 0V6h3v3H9zm0 0H6v3h3V9z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgTransparencyGrid = styled(BaseComponent)`
  outline: none;
`
export default SvgTransparencyGrid
