import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5.003c-.695 0-1.25.278-1.25.747 0 .247.174.37.327.477.118.084.223.158.223.273 0 .207-.738.243-1.602.284-1.222.06-2.698.13-2.698.716A.5.5 0 003 3h9a.5.5 0 00.5-.5c0-.586-1.476-.657-2.698-.716C8.938 1.743 8.2 1.707 8.2 1.5c0-.115.105-.19.223-.273.153-.107.327-.23.327-.477 0-.469-.555-.747-1.25-.747zm-4.605 6.12a1.958 1.958 0 00-.87 1.933l.81 4.421A1.867 1.867 0 004.662 14h5.676c.898 0 1.651-.644 1.825-1.523l.812-4.42c.115-.733-.146-1.524-.84-1.963-.406-.264-.493-.762 0-.966C12.827 4.864 12.693 4 12 4H3c-.695 0-.801.893-.105 1.157.491.175.404.673 0 .966zM8.2 6.25c0-.432-.114-.874-.294-1.25H4.111a1.4 1.4 0 01.118.655c-.034.57-.383 1.014-.749 1.278l-.025.018-.026.017a.96.96 0 00-.418.92l.807 4.4a.867.867 0 00.843.712h5.676c.4 0 .756-.285.843-.711l.807-4.4c.061-.407-.092-.763-.388-.95l-.011-.007c-.393-.256-.755-.704-.79-1.284A1.398 1.398 0 0110.912 5H9.055A2.127 2.127 0 009 5.5c0 .474.217.871.444 1.287.249.456.51.934.51 1.563 0 .92-.455 1.492-1.332 1.492-.49 0-1.044-.381-1.044-1.084 0-.405.138-.742.283-1.098.164-.402.34-.83.34-1.41z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgIconjarLogo = styled(BaseComponent)`
  outline: none;
`
export default SvgIconjarLogo
