import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M98.64 94.444c0-.563.457-1.019 1.02-1.019h7.132c.562 0 1.018.457 1.018 1.02v13.244c0 .563-.456 1.019-1.018 1.019h-7.133a1.019 1.019 0 01-1.018-1.019V94.444zM20.208 96.244c0-.563.456-1.019 1.019-1.019h6.664c.563 0 1.019.456 1.019 1.019v11.015c0 .563-.456 1.019-1.019 1.019h-6.664a1.019 1.019 0 01-1.019-1.019V96.244z"
        fill="#fff"
      />
      <path
        d="M11.79 105.226c0-.563.456-1.019 1.019-1.019h102.385c.563 0 1.019.456 1.019 1.019v6.664c0 .563-.456 1.019-1.019 1.019H12.809a1.019 1.019 0 01-1.019-1.019v-6.664zM102.791 66.215c.343-.85 1.546-.85 1.889 0l10.843 26.828c.271.67-.222 1.4-.945 1.4H92.893c-.722 0-1.215-.73-.944-1.4l10.842-26.828zM23.32 66.215c.343-.85 1.546-.85 1.889 0L36.05 93.043c.27.67-.222 1.4-.945 1.4H13.422c-.723 0-1.216-.73-.945-1.4L23.32 66.215z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.684 17.189c0-.31.25-.562.561-.562h59.51c.31 0 .561.252.561.562v66.576l-4.08 10.35c-.264.668.228 1.392.947 1.392h3.133v8.701c0 .31-.251.561-.561.561h-59.51a.561.561 0 01-.561-.561v-8.7h3.134c.718 0 1.211-.725.948-1.393l-4.082-10.353V17.189zM60.07 29.54a.561.561 0 00-.562.562v11.79c0 .31.252.56.562.56h7.86c.31 0 .561-.25.561-.56v-11.79a.561.561 0 00-.561-.562h-7.86zm-15.72.562c0-.31.251-.562.561-.562h7.86c.31 0 .561.252.561.562v11.79c0 .31-.251.56-.561.56h-7.86a.561.561 0 01-.561-.56v-11.79zm.561 17.964a.561.561 0 00-.561.562v11.79c0 .31.251.56.561.56h7.86c.31 0 .561-.25.561-.56v-11.79a.561.561 0 00-.561-.562h-7.86zm-.561 19.087c0-.31.251-.561.561-.561h7.86c.31 0 .561.251.561.561v11.79c0 .31-.251.561-.561.561h-7.86a.561.561 0 01-.561-.561v-11.79zm15.72-19.087a.561.561 0 00-.562.562v11.79c0 .31.252.56.562.56h7.86c.31 0 .561-.25.561-.56v-11.79a.561.561 0 00-.561-.562h-7.86zm-.562 19.087c0-.31.252-.561.562-.561h7.86c.31 0 .561.251.561.561v11.79c0 .31-.251.561-.561.561h-7.86a.561.561 0 01-.562-.561v-11.79zm15.717-.561a.561.561 0 00-.56.561v11.79c0 .31.25.561.56.561h7.86c.31 0 .562-.251.562-.561v-11.79a.561.561 0 00-.562-.561h-7.86zm-.56-17.964c0-.31.25-.562.56-.562h7.86c.31 0 .562.252.562.562v11.79c0 .31-.252.56-.562.56h-7.86a.561.561 0 01-.56-.56v-11.79zm.56-19.088a.561.561 0 00-.56.562v11.79c0 .31.25.56.56.56h7.86c.31 0 .562-.25.562-.56v-11.79a.561.561 0 00-.562-.562h-7.86zM59.923 90.368c-.562 0-1.019.456-1.019 1.019v12.226c0 .563.457 1.019 1.02 1.019h8.15c.563 0 1.02-.456 1.02-1.019V91.387c0-.563-.457-1.019-1.02-1.019h-8.15z"
        fill="#fff"
      />
    </Svg>
  )
}

const SvgOfficeWhite = styled(BaseComponent)`
  outline: none;
`
export default SvgOfficeWhite
