import React, { useCallback } from 'react'

import { Box } from '../../atoms/Box'
import { Button } from '../../atoms/Button'
import { Flex } from '../../atoms/Flex'
import { Text } from '../../atoms/Typography'
import { useCalendly } from '../../hooks'
import { Hide } from '../../molecules/Hide'
import Header, { Props as HeaderProps } from './Header'

export type Props = {
  phoneNumber: string
  phoneNumberLabel: string
  buttonLabel: string
  label: string
  calendlyUrl: string
} & HeaderProps

export const PublicHeader = ({
  phoneNumber,
  phoneNumberLabel,
  buttonLabel,
  label,
  calendlyUrl,
  ...props
}: Props) => {
  const calendly = useCalendly()

  const handleOpenCalendly = useCallback(() => {
    calendly?.initPopupWidget({
      url: calendlyUrl,
    })
  }, [calendly, calendlyUrl])

  return (
    <Header {...props}>
      <Flex justifyContent="flex-end" alignItems="center">
        <Hide sm md>
          <Flex alignItems="center">
            <Text variant="BodyExtraExtraSmallCaps">{label}</Text>
            <Text
              as="a"
              ml={[0, 0, 70, 9]}
              variant="BodySmallMedium"
              href={`tel:${phoneNumber}`}
            >
              {phoneNumberLabel}
            </Text>
          </Flex>
        </Hide>
        <Box ml={[0, 6]}>
          <Button variant="secondary" onClick={handleOpenCalendly}>
            {buttonLabel}
          </Button>
        </Box>
      </Flex>
    </Header>
  )
}
