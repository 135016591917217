import React, {
  useCallback,
  useReducer,
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react'

import useDeepCompareEffect from 'use-deep-compare-effect'

import { assert } from '../../utils/asserts'
import { reducer, ActionType } from './reducer'
import { Stage, Step } from './types'
import { getInitialValues, StepOperation } from './utils'

export type ContextValueType = {
  stageTitles?: string[]
  stages: Stage[]
  stage?: Stage
  stageIndex: number
  stepIndex: number
  step?: Step
  navigate: (type: StepOperation, data: any) => void
  reset: () => void
  isLastStep: boolean
  hasPrevStep: boolean
  setStages: (stages: Stage[]) => void
}

export const WizardContext = createContext<ContextValueType>({
  stageTitles: [],
  stages: [],
  stage: undefined,
  stageIndex: 0,
  stepIndex: 0,
  step: undefined,
  navigate: () => {},
  reset: () => {},
  isLastStep: false,
  hasPrevStep: false,
  setStages: () => {},
})

export type Props = PropsWithChildren<{
  stages: Stage[]
  stageTitles?: string[]
  initialStageIndex?: number
  initialStepIndex?: number
  currentStageIndex?: number
}>

export const WizardProvider = ({
  children,
  stages,
  stageTitles = [],
  currentStageIndex = 0,
  initialStageIndex = 0,
  initialStepIndex = 0,
}: Props) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialValues(stages, initialStageIndex, initialStepIndex)
  )

  const { stepIndex, stageIndex, stage } = state

  useDeepCompareEffect(() => {
    if (!stage) {
      dispatch({
        type: ActionType.RESET_STAGE,
        payload: getInitialValues(stages, initialStageIndex, initialStepIndex),
      })
    }
    setStages(stages)
  }, [currentStageIndex, stages])

  const reset = useCallback(() => {
    dispatch({
      type: ActionType.RESET_STAGE,
      payload: getInitialValues(stages, initialStageIndex, initialStepIndex),
    })
  }, [stages, initialStageIndex, initialStepIndex])

  const isMultiStep = useMemo(() => stage?.steps?.length > 1, [stage])
  const isLastStep = useMemo(() => {
    if (isMultiStep) {
      return (
        stepIndex === stage!.steps.length - 1 &&
        stageIndex === stages!.length - 1
      )
    }
    return stageIndex === stages!.length - 1
  }, [isMultiStep, stage, stageIndex, stages, stepIndex])

  const hasPrevStep = useMemo(() => stageIndex > 0 || stepIndex > 0, [
    stageIndex,
    stepIndex,
  ])

  const navigate = useCallback((type: StepOperation, data: any) => {
    if (type === 'NEXT') {
      dispatch({ type: ActionType.NEXT_STAGE_OR_STEP, formState: data })
    } else if (type === 'STAGE') {
      dispatch({ type: ActionType.GO_TO_STAGE, payload: data })
    } else {
      dispatch({ type: ActionType.PREV_STAGE_OR_STEP, formState: data })
    }
  }, [])

  const setStages = useCallback((data: Stage[]) => {
    dispatch({
      type: ActionType.UPDATE_STAGES,
      payload: {
        stages: data,
      },
    })
  }, [])

  const contextValue = {
    ...state,
    navigate,
    reset,
    isLastStep,
    hasPrevStep,
    stageTitles,
    setStages,
  }

  return (
    <WizardContext.Provider value={contextValue}>
      {children}
    </WizardContext.Provider>
  )
}

export const useWizard = () => {
  const context = useContext(WizardContext)
  assert(!context, 'useWizard can only be used with WizardProvider')
  return context
}
