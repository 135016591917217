import { useState, useEffect, useCallback, useMemo } from 'react'

import { isBrowser } from '../utils/misc'

export const useMedia = <T>(
  queries: string[],
  values: T[],
  defaultValue: T
) => {
  const mediaQueryLists = useMemo(
    () => (isBrowser ? queries.map((q) => window.matchMedia(q)) : []),
    [queries]
  )

  const getValue = useCallback(() => {
    if (!isBrowser || mediaQueryLists.length === 0) {
      return defaultValue
    }
    const index = mediaQueryLists.findIndex((mql) => mql.matches)
    return values?.[index] || defaultValue
  }, [mediaQueryLists, values, defaultValue])

  const [value, setValue] = useState<T>(getValue)

  useEffect(() => {
    const handler = () => setValue(getValue)
    mediaQueryLists.forEach((mql) => mql.addListener(handler))
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler))
  }, [getValue, mediaQueryLists])

  return value
}
