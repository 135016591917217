import { useEffect } from 'react'

import { useBreakpoints } from './useBreakpoints'

export const useScrollLock = () => {
  const breakpoint = useBreakpoints()
  useEffect(() => {
    if (
      breakpoint.breakpointIdentifier === 'sm' ||
      breakpoint.breakpointIdentifier === 'md'
    ) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
    }
  }, [breakpoint])
}
