import { useCssLink } from './useCssLink'
import { useScript } from './useScript'

declare global {
  interface Window {
    Calendly: {
      initPopupWidget: (options: { url: string }) => void
    }
  }
}

// https://github.com/tcampb/react-calendly/blob/363943a805d3295c05c57eaa177c55205bc8ae83/src/calendly.tsx

type Optional<T extends object> = {
  [P in keyof T]?: T[P]
}

type PageSettings = Optional<{
  hideLandingPageDetails: boolean
  hideEventTypeDetails: boolean
  backgroundColor: string
  textColor: string
  primaryColor: string
  hideGdprBanner: boolean
}>

type Prefill = Optional<{
  name: string
  email: string
  firstName: string
  lastName: string
  location: string
  guests: string[]
  customAnswers: Optional<{
    a1: string
    a2: string
    a3: string
    a4: string
    a5: string
    a6: string
    a7: string
    a8: string
    a9: string
    a10: string
  }>
  date: Date
}>

type Utm = Optional<{
  utmCampaign: string
  utmSource: string
  utmMedium: string
  utmContent: string
  utmTerm: string
}>

interface InlineWidgetOptions {
  url: string
  parentElement: HTMLElement
  prefill?: Prefill
  utm?: Utm
}

interface BadgeWidgetOptions {
  url: string
  text: string
  color?: string
  textColor?: string
  branding?: boolean
  prefill?: Prefill
  utm?: Utm
  pageSettings?: PageSettings
}

interface PopupWidgetOptions {
  url: string
  prefill?: Prefill
  utm?: Utm
}

export interface ICalendly {
  initInlineWidget(options: InlineWidgetOptions): void
  showPopupWidget(url: string): void
  closePopupWidget(): void
  destroyBadgeWidget(): void
  initBadgeWidget(options: BadgeWidgetOptions): void
  initPopupWidget(options: PopupWidgetOptions): void
}

export const useCalendly = () => {
  useCssLink('https://assets.calendly.com/assets/external/widget.css')
  const status = useScript(
    'https://assets.calendly.com/assets/external/widget.js'
  )

  const calendly = status === 'ready' ? (window as any).Calendly : undefined

  return calendly as ICalendly | undefined
}
