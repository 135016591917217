import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.09 10a1 1 0 01.702.287l30.909 30.42a1 1 0 01.299.712V117a1 1 0 01-1 1H18a1 1 0 01-1-1V11a1 1 0 011-1h60.09zM101 41L78 18v22a1 1 0 001 1h22z"
        fill="#4A4A48"
      />
      <rect x={36} y={73} width={56} height={6} rx={1} fill="#329C7F" />
      <rect x={36} y={60} width={56} height={6} rx={1} fill="#F3AD3D" />
      <rect x={36} y={47} width={28} height={6} rx={1} fill="#F3AD3D" />
      <rect x={36} y={86} width={56} height={7} rx={1} fill="#329C7F" />
    </Svg>
  )
}

const SvgDocumentColor = styled(BaseComponent)`
  outline: none;
`
export default SvgDocumentColor
