import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.5C4.5 4.25 3 6.5 3 9a4.5 4.5 0 109 0c0-2.5-1.5-4.75-4.5-7.5zM11 9c0-1.888-1.027-3.728-3.5-6.126C5.027 5.272 4 7.112 4 9a3.5 3.5 0 107 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgOpacity = styled(BaseComponent)`
  outline: none;
`
export default SvgOpacity
