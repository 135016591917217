import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2h4.5a.5.5 0 01.5.5V5H8V2zM7 5V2H2.5a.5.5 0 00-.5.5V5h5zM2 6v3h5V6H2zm6 0h5v3H8V6zm0 4h5v2.5a.5.5 0 01-.5.5H8v-3zm-6 2.5V10h5v3H2.5a.5.5 0 01-.5-.5zm-1-10A1.5 1.5 0 012.5 1h10A1.5 1.5 0 0114 2.5v10a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 011 12.5v-10z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgTable = styled(BaseComponent)`
  outline: none;
`
export default SvgTable
