import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.29 2.75a.5.5 0 01.467.32l3.21 8.32a.5.5 0 01-.934.36l-1.05-2.725H9.61L8.567 11.75a.5.5 0 01-.934-.358l3.19-8.32a.5.5 0 01.466-.321zm.002 1.893l1.362 3.532H9.937l1.354-3.532zm-8.393.564c-1.64 0-2.89 1.479-2.89 3.403 0 2.024 1.35 3.402 2.89 3.402a3.06 3.06 0 002.255-.99v.508a.45.45 0 00.9 0V5.72a.45.45 0 10-.9 0v.503A3.01 3.01 0 002.9 5.207zm2.255 4.591V7.302c-.39-.721-1.213-1.244-2.067-1.244-.978 0-2.052.908-2.052 2.552 0 1.543.974 2.552 2.052 2.552.883 0 1.685-.667 2.067-1.364z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLetterCaseToggle = styled(BaseComponent)`
  outline: none;
`
export default SvgLetterCaseToggle
