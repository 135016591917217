import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3 7.5a1.8 1.8 0 11-3.6 0 1.8 1.8 0 013.6 0zm.905.5a2.751 2.751 0 01-5.41 0H.5a.5.5 0 010-1h5.295a2.751 2.751 0 015.41 0H14.5a.5.5 0 010 1h-3.295z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgSlider = styled(BaseComponent)`
  outline: none;
`
export default SvgSlider
