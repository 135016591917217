import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5 7.98c-3.35.63-5.7 3.05-7.06 7.27a19.89 19.89 0 00-1.02 6.18v.66c.05.14.1.5.14 1.09h7.93v15.85H0V24.41c0-7.17 1.43-12.7 4.3-16.58 2.85-3.88 6.58-6.1 11.2-6.69v6.84zm24.5 0c-2.67.43-4.7 1.94-6.1 4.5a18.71 18.71 0 00-2.05 8.88v.87c.05.3.13.6.22.95H40v15.85H24.44V24.41c0-5.77 1.2-10.9 3.63-15.41C30.5 4.44 34.47 1.82 40 1.14v6.84z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgQuote2 = styled(BaseComponent)`
  outline: none;
`
export default SvgQuote2
