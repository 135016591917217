import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={128}
      height={22}
      viewBox="0 0 128 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20.91L2.9.48h5.58c1.6-.1 3.19.44 4.52 1.54 1.1.95 1.73 2.5 1.7 4.15a13.3 13.3 0 01-.16 1.92 7 7 0 01-2.24 4.57 8 8 0 01-5.18 1.44H4.7l-.98 6.81H0zM8.05 4.19H6.09l-.87 6.2H7.2a4.4 4.4 0 002.7-.69c.6-.5.97-1.3 1.03-2.2a3.86 3.86 0 00.05-.74c0-1.71-.97-2.57-2.93-2.57zm13.87 17.19a6.19 6.19 0 01-4.87-1.98 7.78 7.78 0 01-1.78-5.4c.02-1.22.12-2.44.3-3.64a15.62 15.62 0 012.85-7.68A6.74 6.74 0 0123.92 0c1.78-.1 3.5.6 4.88 2a7.78 7.78 0 011.78 5.4 26.74 26.74 0 01-.3 3.65 15.62 15.62 0 01-2.85 7.67 6.74 6.74 0 01-5.5 2.67zm.1-3.94a3.45 3.45 0 003-1.64 12.41 12.41 0 001.59-5.14 20.86 20.86 0 00.25-3.06c.05-1-.23-2-.8-2.73-.61-.66-1.42-.99-2.24-.92a3.45 3.45 0 00-3 1.64 12.39 12.39 0 00-1.59 5.14 20.33 20.33 0 00-.25 3.06c-.06 1 .23 2 .8 2.73.61.66 1.42 1 2.24.92zm15.12 3.93a8.52 8.52 0 01-3.49-.74 9.71 9.71 0 01-3.06-2.17l1.93-3.19a6.88 6.88 0 002.16 1.58c.79.37 1.62.56 2.46.56a3.3 3.3 0 002.07-.57c.47-.34.76-.98.74-1.66.01-.47-.14-.93-.4-1.27a4.13 4.13 0 00-1.51-.99l-1.23-.56a7.88 7.88 0 01-3.17-2.32 5.61 5.61 0 01-1-3.44 7.14 7.14 0 01.81-3.43A5.85 5.85 0 0135.68.85 6.43 6.43 0 0139 0c2.17-.04 4.28.9 5.92 2.66l-1.93 3.1a6.04 6.04 0 00-4.11-1.8 2.73 2.73 0 00-1.82.56c-.43.34-.68.94-.66 1.58-.03.45.13.9.4 1.2.47.39.99.7 1.53.9l1.17.53a8.02 8.02 0 013.26 2.37 6 6 0 01.96 3.58 7.18 7.18 0 01-.83 3.51 5.92 5.92 0 01-2.3 2.34 6.83 6.83 0 01-3.44.83zm11.38-.46l2.36-16.5h-4.8l.56-3.93h13.29l-.55 3.93h-4.8l-2.35 16.5h-3.71z"
        fill="#2C2C2C"
      />
      <path
        d="M59.1 20.91L62.01.48h3.71l-2.9 20.43H59.1zm6.52 0L68.53.48h5.84a6.78 6.78 0 013.59.9 5.74 5.74 0 012.24 2.5c.52 1.13.79 2.42.77 3.72a21.3 21.3 0 01-.28 3.44c-.95 6.58-3.82 9.87-8.6 9.87h-6.47zM74 4.41h-2.31l-1.8 12.57h2.28c1.21.1 2.4-.45 3.28-1.5a10.2 10.2 0 001.58-4.82 32.85 32.85 0 00.23-2.84 3.7 3.7 0 00-.8-2.48A3.05 3.05 0 0074 4.41zm7.62 16.5L84.52.48h10.91l-.55 3.9h-7.2l-.62 4.3h6.47L93 12.46h-6.47l-.65 4.55h7.45l-.55 3.9H81.62zm13.46 0L98 .48h4.72l3.33 14.46h.4L108.5.48h3.48l-2.9 20.43h-4.72l-3.33-14.46h-.4l-2.06 14.46h-3.49zm20.62 0l2.35-16.5h-4.79l.55-3.93h13.3l-.56 3.93h-4.79l-2.35 16.5h-3.72z"
        fill="#BD1111"
      />
    </Svg>
  )
}

const SvgPostident = styled(BaseComponent)`
  outline: none;
`
export default SvgPostident
