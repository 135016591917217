import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.354 1.854a.5.5 0 10-.708-.708l-2 2a.5.5 0 000 .708l2 2a.5.5 0 10.708-.708L2.207 4H9.5A3.5 3.5 0 0113 7.5a.5.5 0 001 0A4.5 4.5 0 009.5 3H2.207l1.147-1.146zM2 7.5a.5.5 0 00-1 0A4.5 4.5 0 005.5 12h7.293l-1.147 1.146a.5.5 0 00.708.708l2-2a.5.5 0 000-.708l-2-2a.5.5 0 00-.708.708L12.793 11H5.5A3.5 3.5 0 012 7.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgLoop = styled(BaseComponent)`
  outline: none;
`
export default SvgLoop
