import * as React from 'react'
import styled from 'styled-components'
import Svg from './Svg'

function BaseComponent(props: any) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 9.125a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zm0 1a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z"
        fill="currentColor"
      />
    </Svg>
  )
}

const SvgDot = styled(BaseComponent)`
  outline: none;
`
export default SvgDot
